import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";

export const LabelTextCard = ({
    label,
    value,
    addButton = "",
    onUpdate = false,
    boxSx = {},
    noWrap = false,
    valueStyle={}

}) => {




    return (
        <>
            <Box sx={{ borderRadius: 1, mb: 1.2, ...boxSx }}>
                <Box
                    my={1}
                    sx={{
                        background: (theme) =>
                            theme?.palette?.mode === "light"
                                ? theme?.palette.grey[0]
                                : theme?.palette.grey[900],
                        pl: 0,
                        pr: 1,
                        py: 0.3,
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                    }}
                >
                    <Box >

                        <Typography
                            sx={{
                                fontSize: 15,
                                py: 0.15,
                                textTransform: "capitalize",
                            }}
                        >
                            {label}
                        </Typography>


                        <Typography
                            noWrap={!noWrap}
                            sx={{
                                fontWeight: "600",
                                textOverflow: "ellipsis",
                                fontSize: 18,
                                py: 0.1,
                                maxWidth: window.innerWidth * 0.85,
                               ...valueStyle
                            }}
                        >
                            {value}
                        </Typography>

                    </Box>
                    {onUpdate ? (<>
                        {onUpdate}
                    </>
                    ) : (
                        ""
                    )}
                </Box>
                {addButton}
            </Box>


        </>
    );
};
