import React from "react";
import { LabelTextCard, NavBar } from "../../../components";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ReadableDateTime } from "../../../utils/ReadableDateTime";

function WalkThroughDetail() {
  return (
    <>
      <NavBar title="Walkthrough Detail" noFilter noSearch />

      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pt:2,
          width: "100%",
        }}
      >
        <List
          sx={{ background: "#ccccc850", borderRadius: 2, width: "100%" }}
          dense
        >
          <ListItem
            sx={{ justifyContent: "space-between", alignItems: "flex-start" }}
          >
            <ListItemText
              primaryTypographyProps={{
                fontSize: 18,
                fontWeight: "600",
                textTransform: "capitalize",
              }}
              primary={"Client Name"}
              secondary={"789 Yonge Street, Amigo Beach, Ontario, M4W 2G8"}
            />

            <Chip
              clickable
              label="Move to quote"
              sx={{ color: "#fff", background: "#0169EE", fontWeight: "500" }}
            />
          </ListItem>

          <ListItem sx={{ justifyContent: "space-between" }}>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "600",
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
              primary={ReadableDateTime("2023-12-15")}
            />
          </ListItem>

          <ListItem sx={{ justifyContent: "space-between" }}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Chip
                label="Filled out"
                sx={{
                  color: "#587D00",
                  background: "#E7FFB2",
                  fontWeight: "500",
                }}
              />
              <Typography>• filled by you</Typography>
            </Stack>
          </ListItem>
        </List>

        <Stack
          padding={2}
          direction={{ lg: "row" }}
          flexWrap={"wrap"}
          sx={{
            "&>div": {
              width: { lg: "50%" },
            },
          }}
        >
          <LabelTextCard label={"Heating type:"} value={"Gas furnace"} />
          <LabelTextCard
            label={"T-stats:"}
            value={"Heating contractor providing"}
          />

          <LabelTextCard
            label={"Water heaters:"}
            value={"On demand gas wall mount"}
          />
          <LabelTextCard label={"Well/septic:"} value={"City water"} />

          <LabelTextCard
            label={"Air flow:"}
            value={"Bath fan De-humidistate"}
          />
          <LabelTextCard label={"Cabinets:"} value={"Ranges"} />
          <LabelTextCard label={"Any islands or peninsulas in kitchen?:"} value={"Ranges"} />

          <LabelTextCard label={"Wide custom door trim on call doors:"} value={"Ranges"} />
          <LabelTextCard label={"Generate door trim on call doors:"} value={"Ranges"} />
          <LabelTextCard label={"Trim Devices:"} value={"Ranges"} />
          <LabelTextCard label={"Drimmers: Type 00 | #"} value={"Dimmed lighting"} />
        

        </Stack>
      </Box>
    </>
  );
}

export { WalkThroughDetail };
