import React from "react";
import { JobDetailContainerListCard } from "../../../components";
import { Box, Divider, Typography } from "@mui/material";
import CalculateTotalServiceMaterial from "../../../utils/CalculateTotalServiceMaterial";

function Scheduled({ quoteDetail }) {
    const subTotal = CalculateTotalServiceMaterial(
        quoteDetail?.materials,
        quoteDetail?.services
    );

    const addTax = () => {
        let rate = quoteDetail?.quote_tax_rate || 0;
        let tax = (Number(subTotal) * Number(rate)) / 100;
        return {
            total: Number(subTotal) + (Number(subTotal) * Number(rate)) / 100,
            tax,
        };
    };

    return (
        <>
            <Box>
                <JobDetailContainerListCard
                    hideCardButton
                    hideListButton
                    listData={quoteDetail?.services}
                    cardButton="service"
                    cardTitle="Services"
                />
                {quoteDetail?.materials?.length ? (
                    <JobDetailContainerListCard
                        hideCardButton
                        hideListButton
                        listData={quoteDetail?.materials}
                        cardButton="material"
                        cardTitle="Material"
                    />
                ) : (
                    ""
                )}

                <Box
                    sx={{
                        overflow: "hidden",
                        width: "100%",
                        pr: 0,
                        mb: 4,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            mt: 0.5,
                            border: 0.8,
                            borderColor: "#B7D4FF",
                            background: "#F8F9FC",
                            borderRadius: 1.6,
                            borderBottom: 3,
                            borderBottomColor: "#B7D4FF",
                            px: 1,
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography> Subtotal: </Typography>
                                <Typography>${subTotal?.toFixed(2)}</Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography> Tax: ({quoteDetail?.quote_tax_rate || 0}%)</Typography>
                                <Typography>${addTax()?.tax?.toFixed(2)}</Typography>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography> Grand Total: </Typography>
                                <Typography>${addTax()?.total?.toFixed(2)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Scheduled;
