import { DeleteOutlineRounded, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import EditIcon from "@mui/icons-material/Edit";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const TextArrowCard = ({
  title,
  url,
  onUpdate,
  onDelete,
  sx,
  noDivider,
  onClick = false,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();


  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box
        onClick={() => { onClick ? onClick() : navigate(`${pathname}/${url}`) }}
        sx={{
          my: 0.6,
          borderRadius: 1,
          ":hover": {
            boxShadow: "0 2px 5px -3px",
            cursor: "pointer",
          },
          ...sx,
        }}
      >
        <ListItem
          components={"div"}
          secondaryAction={
            <>
              {(!onUpdate || !onDelete) ? <KeyboardArrowRightIcon className="!fill-[#B1BAC4]" /> : <MoreVertIcon
                sx={{
                  fontSize: 24,
                  mr: -1,
                  // background: '#000',
                }}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              />}
            </>
          }
        >
          <ListItemText primary={title} />

          {/* {(onUpdate || onDelete) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <MoreVertIcon
                sx={{
                  fontSize: 24,
                  mr: -1,
                  // background: '#000',
                }}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              />
            </Box>
          )} */}
        </ListItem>

        {noDivider ? (
          ""
        ) : (
          <Divider
            sx={{
              borderRadius: 8,
              mx: 1,
            }}
          />
        )}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List dense>
          {onUpdate && (
            <ListItem disableGutters disablePadding components={"div"}>
              {React.cloneElement(onUpdate, { onClose: handleClose })}
            </ListItem>
          )}
          {onDelete && (
            <ListItem disableGutters disablePadding components={"div"}>
              {React.cloneElement(onDelete, { onClose: handleClose })}
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
