import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Quote, QuoteAdd, QuoteDetial, QuoteUpdate } from '../../pages/Quote'

export const QuoteRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Quote />} />
                <Route path='add' element={<QuoteAdd />} />
                <Route path='detail/:quote_id' element={<QuoteDetial />} />
                <Route path='update/:quote_id' element={<QuoteUpdate />} />
            </Routes>
        </>
    )
}
