import { ArrowOutward } from "@mui/icons-material";
import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";

export const MailSentScreen = () => {
    return (
        <>
            <Box
                display={"flex"}
                flexDirection={"column"}
                height={"100vh"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        marginY: 2,
                        color: "#000",
                        fontWeight: 500,
                    }}
                >
                    Check your mail
                </Typography>
                <Typography p={2} sx={{ padding: 2, textAlign: "center", color: "#000" }}>
                    We have sent a password recover instructions to your email.
                </Typography>

                <Button
                    type="submit"
                    variant="contained"
                    className="inline-flex"
                    sx={{
                        width: "70%",
                        marginY: 2,
                        fontSize: 16,
                        padding: 0,
                        borderRadius: "4px",
                        maxWidth: 300,
                    }}
                >
                    {" "}
                    <Link
                        sx={{
                            alignSelf: "center",
                            width: "100%",
                            fontSize: 16,
                            borderRadius: "4px",
                        }}
                        href="mailto:"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            className="inline-flex"
                            sx={{
                                width: "100%",
                                fontSize: 16,
                                borderRadius: "4px",
                                maxWidth: 300,
                            }}
                        >
                            <span>Open email </span>
                            <ArrowOutward className="self-start" />
                        </Button>
                    </Link>
                </Button>
            </Box>
        </>
    );
};
