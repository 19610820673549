import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  JobDetailContainerListCard,
  LabelTextCard,
  NoResultFound,
} from "../../../components";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { ReadableDate, ReadableDateTime } from "../../../utils/ReadableDateTime";
import { GroupAdd } from "@mui/icons-material";
import ReadableAddress from "../../../utils/ReadableAddress";
import { calculateTotal } from "../../../utils/CalculateTaxTotal";

function JobInfo({ jobDetail, isEdit }) {
  const { jobId } = useParams();



  const [services, setServices] = useState([]);
  const [material, setMaterial] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (jobDetail && !services?.length && !material?.length) {
      setServices(jobDetail.services);
      setMaterial(jobDetail.materials);
    }
  }, [jobDetail]);

  if (!jobDetail) {
    return <NoResultFound />;
  }

  return (
    <>
      <Box
        flexGrow
        component={"div"}
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          mt: 1,
          padding: { lg: 2 },
          pb: 10,

        }}
      >

        <Stack
          direction={{ lg: "row" }}
          flexWrap={"wrap"}
          sx={{
            "&>div": {
              width: { lg: "50%" },
            },
          }}
        >

          <LabelTextCard
            label={"Job Created at"}
            value={ReadableDateTime(jobDetail?.created_at)}
          />
          <LabelTextCard
            label={"Job Scheduled for"}
            value={ReadableDateTime(jobDetail?.schedule_date)}
          />
          <LabelTextCard
            label={"Job Completed at"}
            value={jobDetail?.end_time ? ReadableDateTime(jobDetail?.end_time) : 'yet to complete'}
          />
          <LabelTextCard
            label={"Job Name"}
            value={
              jobDetail?.name
            }
          />
          <LabelTextCard
            label={"Client Name"}
            value={
              jobDetail?.client_details?.first_name +
              " " +
              jobDetail?.client_details?.last_name
            }
          />
          <LabelTextCard
            noWrap
            label={"Address"}
            value={`${ReadableAddress(jobDetail?.client_details)}`}
          />

          <LabelTextCard
            label={"Team"}
            value={jobDetail?.team_details?.length &&
              jobDetail?.team_details?.map((item, index) => {
                if (jobDetail?.team_details?.length - 1 == index) {
                  return `${item?.first_name + " " + item?.last_name} `;
                }
                return `${item?.first_name + " " + item?.last_name}, `;
              }) || (
                <Typography
                  component={"span"}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/jobs/update/" + jobId + "/full");
                  }}
                >
                  <Button
                  // variant="outlined"
                  >
                    Assign
                    <KeyboardArrowRightIcon p={0} />
                  </Button>
                </Typography>
              )
            }
          />

        </Stack>

        <JobDetailContainerListCard
          hideCardButton
          hideListButton
          listData={services}
          cardButton="service"
          cardTitle="Services"
        />
        {material?.length ? <JobDetailContainerListCard
          hideCardButton
          hideListButton
          listData={material}
          jobId={jobId}
          cardButton="material"
          cardTitle="Material"
        /> : ''}

        <LabelTextCard
          noWrap
          label={"Job Description"}
          value={jobDetail?.description}
        />

        <Box
          sx={{
            overflow: "hidden",
            width: "100%",
            pr: 0.5,
            mb: 6,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mt: 1,
              border: 0.8,
              borderColor: "#B7D4FF",
              background: "#F8F9FC",
              borderRadius: 1.6,
              borderBottom: 3,
              borderBottomColor: "#B7D4FF",
              px: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography> Subtotal: </Typography>
                <Typography>${calculateTotal(jobDetail)?.subTotal?.toFixed(2)}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography> Tax: {jobDetail?.tax_percent_rate}% </Typography>
                <Typography>${calculateTotal(jobDetail)?.tax?.toFixed(2)}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography> Grand Total: </Typography>
                <Typography>${calculateTotal(jobDetail)?.total.toFixed(2)}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  );
}

export { JobInfo };
