import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
    JobDetailContainerListCard,
    LabelTextCard,
    NoResultFound,
} from "../../components";
import {
    Box,
    Button,
    Container,
    Divider,
    Typography,
    ImageListItem,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReadableDate, ReadableDateTime } from "../../utils/ReadableDateTime";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import ApiClient from "../../constant/API_URLS";
import ReadableAddress from "../../utils/ReadableAddress";

function NoteDetail() {
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get("id");

    const [noteDetail, setNoteDetail] = useState(false);
    const fetchNotes = () => {
        ApiClient.publicNoteDetail
            .get("?id=" + jobId)
            .then((res) => {
                if (res) {
                    console.log(res?.data);
                    setNoteDetail(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchNotes();
    }, [jobId]);


    return (
        <>
            {noteDetail ? (
                <Container>
                    <Box
                        sx={{
                            alignContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <img src={"/assets/static/img/logo.png"} width={420} height={160} />
                        <Typography variant="h6">
                            Note Detail
                        </Typography>
                        <Divider />
                    </Box>
                    <Box
                        flexGrow
                        component={"div"}
                        sx={{
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            mt: 1,
                            pb: 4,
                        }}
                    >
                        {noteDetail ? (
                            <>
                                {noteDetail?.map((item) => (
                                    <Box my={1}>
                                        <Box
                                            sx={{ display: "flex", justifyContent: "space-between" }}
                                        >
                                            <Typography
                                                variant="h6"
                                                sx={{ textTransform: "capitalize" }}
                                            >
                                                {item?.sender_info?.first_name +
                                                    " " +
                                                    item?.sender_info?.last_name}
                                            </Typography>
                                        </Box>

                                        <Divider />
                                        <Typography component={"span"} variant="body2">
                                            Created : {ReadableDateTime(item?.created_at)}
                                        </Typography>
                                        <Typography variant="h6">{item?.note}</Typography>

                                        <Box
                                            display={"flex"}
                                            spacing={2}
                                            sx={{ overflow: "auto", px: 1, gap: 2 }}
                                        >
                                            {item?.images &&
                                                item?.images?.map((item, index) => {
                                                    return (
                                                        <ImageListItem
                                                            sx={{
                                                                position: "relative",
                                                                borderRadius: 4,
                                                                ".MuiImageListItem-img": {
                                                                    height: "160px",
                                                                    width: "160px",
                                                                    // objectFit: "fill",
                                                                },
                                                            }}
                                                            key={item?.id}
                                                        >
                                                            <img
                                                                style={{
                                                                    borderRadius: 1,
                                                                    // borderTopRightRadius: 6,
                                                                }}
                                                                // height={"100px"}
                                                                // width={"100px"}
                                                                src={item?.fileurl}
                                                                alt={item?.filename}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    );
                                                })}
                                        </Box>
                                    </Box>
                                ))}
                            </>
                        ) : (
                            <>{noteDetail ? <NoResultFound /> : <LoadingEffect />}</>
                        )}
                    </Box>
                </Container>
            ) : (
                <LoadingEffect />
            )}
        </>
    );
}

export default NoteDetail;
