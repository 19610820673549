import React from "react";
import { BottomDrawer, ConfirmationDialog, NavBar } from "../../../components";
import { Box, Button, Container, ListItemButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WalkThroughCard } from "../../../components/Cards/DisplayCards";

export const WalkThroughForm = () => {

  const navigate = useNavigate()
  const actions = [
    <>
      <ListItemButton
        sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
      >
        <Typography component={"span"}>Fill Out</Typography>
      </ListItemButton>
    </>,
    <>
      <ListItemButton
      onClick={()=>navigate('assign-team')}
        sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
      >
        <Typography component={"span"} sx={{fontWeight:'600'}}>Assign Team to Fill</Typography>
      </ListItemButton>
    </>,
  ];

  return (
    <>
      <NavBar title="Walk Through Form" noFilter onRight={<BottomDrawer title="" data={actions} />} />

      <WalkThroughCard />
    </>
  );
};
