import React, { useEffect } from 'react'
import { setPageTitle } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { NavBar } from '../../components'

export const Payments = () => {
  const dispatch = useDispatch()


  return (
    <div>
       <NavBar title='Payments' />
       
       Payments</div>
  )
}
