import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../constant/API_URLS';

const initialState = {
    unitList: [],
    taxList: [],
};

export const fetchUnitList = createAsyncThunk('constant/fetchUnitList', async () => {
    try {
        const response = await ApiClient.unit.get().then(res=>{
            if (res) {
              return res;  
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const fetchTaxDetail = createAsyncThunk('constant/fetchTaxDetail', async () => {
    try {
        const response = await ApiClient.tax.get().then(res=>{
            if (res) {
              return res;  
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});

const constant = createSlice({
    name: 'constant',
    initialState,
    reducers: {
        setUnitList: (state, action) => {
            state.unitList = action.payload
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchUnitList.fulfilled, (state, action) => {
            state.unitList = action.payload;
        }).addCase(fetchTaxDetail.fulfilled, (state, action) => {
            state.taxList = action.payload;
        });
    },
});

export const { setUnitList } = constant.actions;

export default constant.reducer;
