import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AssignTeam, WalkThroughDetail, WalkThroughForm } from '../../pages/walkThrough'

export const WalkThroughRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<WalkThroughForm />} />
                <Route path='assign-team' element={<AssignTeam />} />
                <Route path='detail/:id' element={<WalkThroughDetail />} />
            </Routes>
        </>
    )
}
