import { BASE_URL } from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import { GET_TOKEN } from "../../utils/LocalStorageGetSet";
import replaceAndRemoveTrailingSlash from "../../utils/ReplaceAndRemoveTrailingSlash";

export function createApiFunction(apiUrl) {
  return {
    get: (id) => {
      const url = id ? `${apiUrl}/${id}` : apiUrl;
      return fetchData(url, "GET");
    },
    post: (data, id = "") => {
      const url = `${apiUrl}/${id}`;
      return fetchData(url, "POST", data);
    },
    patch: (id, data) => {
      const url = `${apiUrl}/${id}`;
      return fetchData(url, "PATCH", data);
    },
    put: (id, data) => {
      const url = `${apiUrl}/${id}`;
      return fetchData(url, "PUT", data);
    },
    delete: (id) => {
      const url = `${apiUrl}/${id}`;
      return fetchData(url, "DELETE");
    },
  };
}

async function fetchData(url, method, data) {
  const headers = {
    Authorization: "Bearer " + (GET_TOKEN || ""),
    "Access-Control-Allow-Origin": "*",
  };

  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  try {
    const options = {
      method: method,
      headers,
      body: data ? createRequestBody(data) : undefined,
    };
    
    const response = await fetch(BASE_URL + replaceAndRemoveTrailingSlash(url), options);
    let res = await response.json();
    if (!response.ok) {
      CustomToaster.error(res?.message);
      return false;
    }
    return res;
  } catch (error) {
    CustomToaster.error(
      "An error occurred while fetching data. Please try again later."
    );
    throw error;
  }
}

function createRequestBody(data) {
  return data instanceof FormData ? data : JSON.stringify(data);
}
