import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLeftIcon, setPageTitle } from "../../../redux/navbarSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { LabelTextCard, NavBar } from "../../../components";
import { useFormik } from "formik";
import {
  emailValidationSchema,
  passwordValidationSchema,
  phoneValidationSchema,
  postalCodeValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomAutoComplete from "../../../components/InputFields/SelectFields/CustomAutoComplete";
import {
  CANADA_CITIES,
  CANADA_STATES,
} from "../../../constant/CanadaStateCities";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { MapModal, usePlacesAutocomplete } from "../../../utils/GoogleMap";
import GoogleAutoComplete from "../../../components/InputFields/SelectFields/GoogleAutoComplete";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate } from "react-router-dom";
import CustomCheckBox from "../../../components/InputFields/CustomCheckBox/CustomCheckBox";
import { GET_USER, SET_USER } from "../../../utils/LocalStorageGetSet";

export default function ClientAdd() {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: GET_USER?.first_name,
      last_name: GET_USER?.last_name,
      phone: GET_USER?.phone,
      email: GET_USER?.email,
      company_name: GET_USER?.company_name,
      // street: "",
      // state: "",
      // city: "",
      // state: "",
      // pincode: "",
      // lat: null,
      // lang: null,
      // place_id: null,
      // password: "Demo@123",
      // role: "3",
    },
    validationSchema: Yup.object().shape({
      email: emailValidationSchema,
      first_name: stringValidationSchema,
      last_name: stringValidationSchema,
      phone: phoneValidationSchema,
      company_name: stringValidationSchema,
    //   state: stringValidationSchema,
    //   city: stringValidationSchema,
    //   street: stringValidationSchema,
    //   pincode: postalCodeValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      editAdminProfile(values, resetForm);
    },
  });

  const editAdminProfile = (data, resetForm) => {
    ApiClient.adminProfile
      .put('',data)
      .then((res) => {
        if (res) {
          const data = res?.data
          data['token'] = GET_USER.token
          SET_USER(data)
          
          CustomToaster.success(res?.message);
          resetForm();
          setTimeout(()=>{
            window?.location?.reload(false)
          },500)
          // navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isEditable ? (
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            mt: 1,
            pb: 10,
            height: "100%",
          }}
          onSubmit={formik.handleSubmit}
        >
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("first_name")}
            margin="normal"
            label="First Name"
          />
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("last_name")}
            margin="normal"
            label="Last Name"
          />
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("company_name")}
            margin="normal"
            label="Company Name"
          />

          <CustomTextField
            form={formik}
            field={formik.getFieldProps("phone")}
            margin="normal"
            label="Phone"
          />
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("email")}
            margin="normal"
            label="Email Address"
          />

          <Box sx={{ display: "flex", mt: 2 }}>
            <Button type="submit" sx={{ minWidth: 160 }} variant="contained">
              Save
            </Button>
            <Button
              sx={{ minWidth: 160, ml: 2 }}
              variant="outlined"
              color="error"
              onClick={() => {
                setIsEditable(false);
              }}
            >
              Cancel
            </Button>
            {/* <CustomButton label={"Save"} /> */}
          </Box>
        </Box>
      ) : (
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            // alignSelf: "center",
            // alignItems: "center",
            mt: 1,
            p: 2,
            height: "100%",
          }}
        >
          <Box component={"div"}>
            <Box
              component={"div"}
              sx={{
                width: "100%",
                display: "inline-flex",
                alignItems: "flex-end",
                justifyContent:'flex-end'
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                edit
              </Button>
            </Box>

            <LabelTextCard
              valueStyle={{ textTransform: "capitalize" }}
              label={"First Name :"}
              value={GET_USER?.first_name}
            />
            <LabelTextCard label={"Last Name :"} value={GET_USER?.last_name} />
            <LabelTextCard
              valueStyle={{
                color: !GET_USER?.company_name ? "#ccc" : "",
              }}
              label={"Company Name :"}
              value={GET_USER?.company_name || "Edit to Add Company Name.."}
            />
            <LabelTextCard
              valueStyle={{
                color: !GET_USER?.phone ? "#ccc" : "",
              }}
              label={"Phone :"}
              value={GET_USER?.phone || "Edit to Phone.."}
            />
            <LabelTextCard label={"Email :"} value={GET_USER?.email} />
          </Box>
        </Box>
      )}
    </>
  );
}
