// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  emailValidationSchema,
  postalCodeValidationSchema,
  stringValidationSchema,
} from "../../constant/validationSchema";
import { CustomTextField } from "../InputFields/TextFields";
import CustomButton from "../ActionButtons/CustomButton";
import CustomAutoComplete from "../InputFields/SelectFields/CustomAutoComplete";
import GoogleAutoComplete from "../InputFields/SelectFields/GoogleAutoComplete";
import { CANADA_CITIES, CANADA_STATES } from "../../constant/CanadaStateCities";
import EditIcon from "@mui/icons-material/Edit";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeleteConfirmationDialog } from "../DeleteDetails/DeleteConfirmationDialog";
import { ThemeColors } from "../../constant/ThemeColors";
import { KeyboardArrowLeftRounded } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

const HandleAddressUpdate = ({ address, onUpdate, onClose }) => {
  const [isDeleted, setIsDeleted] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      street: address?.street,
      state: address?.state,
      city: address?.city,
      pin_code: address?.pin_code,
      lat: address?.lat,
      lang: address?.lang,
      place_id: address?.place_id,
    },
    validationSchema: Yup.object().shape({
      state: stringValidationSchema,
      city: stringValidationSchema,
      street: stringValidationSchema,
      pin_code: postalCodeValidationSchema,
    }),
    onSubmit: (values) => {
      handleChange(values);
    },
  });

  const selectedState = formik.values.state;

  React.useEffect(() => {
    formik.values.city = "";
  }, [selectedState]);

  const handleDelete = () => {
    ApiClient.address.delete(address.id).then((res) => {
      if (res) {
        setIsDeleted(true);
        onClose()
        CustomToaster.success(res.message);
      }
    });
  };

  const handleChange = (data) => {
    ApiClient.address.put(address.id, data).then((res) => {
      if (res) {
        formik.dirty = false;
        onClose()
        CustomToaster.success(res.message);
      }
    });
  };

  if (isDeleted) {
    return <></>;
  }

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        width: "96%",
        marginX:'auto',
        pb: 2,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid
        container
        paddingX={2}
        columnSpacing={{ md: 1, lg: 2 }}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <GoogleAutoComplete
            form={formik}
            field={formik.getFieldProps("street")}
            margin="normal"
            label="Street"
            googleAddress={(formattedAddress) => {
              const { formatted, lat, lang, place_id } = formattedAddress;
              formik.setFieldValue("state", formatted.state || "");
              formik.setFieldValue("city", formatted.city || "");
              formik.setFieldValue("pin_code", formatted.postalCode || "");
              formik.setFieldValue("lat", lat || "");
              formik.setFieldValue("lang", lang || "");
              formik.setFieldValue("place_id", place_id || "");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomAutoComplete
            form={formik}
            field={formik.getFieldProps("state")}
            data={CANADA_STATES}
            label="State"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomAutoComplete
            form={formik}
            field={formik.getFieldProps("city")}
            data={selectedState ? CANADA_CITIES(selectedState) : []}
            disabled={!selectedState}
            label="City"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("pin_code")}
            margin="normal"
            label="Postal Code"
            placeholder="M2P 1R7"
            inputStyle={{ textTransform: 'uppercase' }}
          />
        </Grid>

        {address?.is_primary ? (  <Grid
            xs={12}
            item
            // display={"flex"}
            // justifyContent={'center'}
            // alignItems={"flex-end"}
            mt={2}
          >
            <CustomButton
              label="Save"
              onClick={formik.handleSubmit}
              sx={{
                position: {
                  md: 'relative',
                  xs: 'absolute'
                }
              }}
            /> </Grid>
        ) : (
          <Grid
            xs={12}
            item
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"space-between"}
            gap={2}
            mt={2}
            sx={{
              position: {
                md: 'relative',
                xs: 'absolute'
              },
              bottom: {
                md: 'auto',
                xs: 24
              },
              width: '96%',
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              color="error"
              sx={{ mt: -0.5, }}
            >
              <DeleteConfirmationDialog
                iconText="Delete"
                onSubmit={handleDelete}
              />
            </Button>
            <Button
              sx={{ py: 1.2 }}
              fullWidth
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export function UpdateAddress({ address, onUpdate }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onUpdate();
  };

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
    onUpdate();
  };

  const [renderInput, setRenderInput] = React.useState("");

  const renderInputField = () => {

    if (address?.length === 1) {
      return address?.map((item, index) => (
        <Box sx={{ my: 2 }}>
          <HandleAddressUpdate address={item} onUpdate={onUpdate} onClose={handleClose} />
        </Box>))
    }
    return address?.map((item, index) => (
      // HandleAddressUpdate({ address:item, onUpdate })

      <Typography
        fullWidth
        onClick={() => {
          setRenderInput(
            <HandleAddressUpdate address={item} onUpdate={onUpdate} onClose={handleClose} />
          );
        }}
        // component={"button"}
        sx={{
          mx: 2,
          my: 0.5,
          mt: index === 0 ? 2 : 0.8,
          border: 0,
          padding: 0.8,
          py: 1.6,
          borderRadius: 2,
          color: "black",
          border: 0.2,
          borderColor: ThemeColors.borderColor,
          ":hover": {
            cursor: "pointer",
            boxShadow: ThemeColors.hoverBoxShadow,
          },
        }}
      >
        {`${item?.street}, ${item?.city}, ${item?.state},  ${item?.pin_code}`}
      </Typography>

    ));
  };

  return (
    <>
      <IconButton
        onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen();
          } else {
            handleClickOpenMd();
          }
        }}
        size="small"
      >
        <EditIcon fontSize="12px" />
      </IconButton>

      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle> Update Address</DialogTitle>
        <>
          <Box fullWidth sx={{ mb: 2 }} >

            {renderInput ? (
              <>
                <Typography
                  size="small"
                  onClick={() => setRenderInput("")}
                  variant="button"
                  sx={{
                    mx: 2,
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 8,
                    border: 1,
                    px: 1,
                    ':hover': {
                      cursor: 'pointer'
                    },
                    width: "max-content",
                    fontSize: 12,
                    borderColor: ThemeColors.borderColor,
                  }}
                >
                  <KeyboardDoubleArrowLeftIcon /> Back
                </Typography>
                {renderInput}
              </>
            ) : (
              renderInputField()
            )}

          </Box>

          {/* <Box fullWidth sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ width: "300px" }}
              variant="contained"
              onClick={handleCloseMd}
            >
              Done
            </Button>
          </Box> */}
        </>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              Update Address
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

        </AppBar>
        {/* {renderInputField()} */}
        {renderInput ? (
          <>
            <Typography
              size="small"
              onClick={() => setRenderInput("")}
              variant="button"
              sx={{
                mx: 2,
                mt: 2,
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                border: 1,
                px: 1,
                ':hover': {
                  cursor: 'pointer'
                },
                width: "max-content",
                fontSize: 12,
                borderColor: ThemeColors.borderColor,
              }}
            >
              <KeyboardDoubleArrowLeftIcon /> Back
            </Typography>
            {renderInput}
          </>
        ) : (
          renderInputField()
        )}

        {/* <CustomButton onClick={handleClose} label="Done" /> */}
      </Dialog>
    </>
  );
}
