import React, { useEffect, useState } from "react";
import {
  BottomDrawer,
  ConfirmationDialog,
  CustomSwipableTabs,
  FloatingBottomRight,
  NavBar,
  NoResultFound,
} from "../../../components";
import Complete from "./JobList/Complete";
import InProgress from "./JobList/InProgress";
import Upcoming from "./JobList/Upcoming";
import { Box, Button, Container, Fab, ListItemButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import UnAssigned from "./JobList/UnAssigned";
import UnScheduled from "./JobList/UnScheduled";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { useSelector } from "react-redux";
import { jobStatus } from "../../../constant/GlobalVariable";
import { AddTaskOutlined } from "@mui/icons-material";
import FilterAlt from "@mui/icons-material/FilterAlt";
import { DateRange, DefinedRange } from "react-date-range";
import dayjs from "dayjs";


export const TeamVisits = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [jobList, setJobList] = useState("");
  const fetchJobs = (filter) => {
    ApiClient.job
      .get('?user_id='+userId)
      .then((res) => {
        if (res) {
          setJobList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const [filteredData, setFilteredData] = useState("");
  const searchText = useSelector((state) => state?.searchBarText);
  useEffect(() => {
    if (searchText && jobList) {
      const searchTextLowerCase = searchText.toLowerCase();
      const searchWords = searchTextLowerCase.split(" ");

      const filteredData = jobList.filter((item) => {
        const jobNameLowerCase = item?.name.toLowerCase();
        const firstNameLowerCase =
          item?.client_details?.first_name.toLowerCase();
        const lastNameLowerCase = item?.client_details?.last_name.toLowerCase();
        const companyNameLowerCase =
          item?.client_details?.company_name?.toLowerCase();
        const address = item?.client_details;
        const addressStrings =
          `${address.street}, ${address.city}, ${address.state}, ${address.pin_code}`.toLowerCase();

        return (
          searchWords.some((word) => firstNameLowerCase?.includes(word)) ||
          searchWords.some((word) => jobNameLowerCase?.includes(word)) ||
          searchWords.some((word) => companyNameLowerCase?.includes(word)) ||
          searchWords.some((word) => lastNameLowerCase?.includes(word)) ||
          searchWords.some((word) => addressStrings.includes(word))
        );
      });

      setFilteredData(filteredData);
    } else {
      setFilteredData("");
    }
  }, [searchText]);

  const filterJob = (id) => {
    if (jobList) {
      return filteredData
        ? filteredData?.filter((item) => {
          if (item?.job_status == id) {
            return item;
          }
        })
        : jobList?.filter((item) => {
          if (item?.job_status == id) {
            return item;
          }
        });
    } else {
      return [];
    }
  };

  const tabData = [
    // { label: "UN-ASSIGNED", value: <UnAssigned jobs={filterJob(1)} /> },
    // {
    //   label: "Draft",
    //   value: <UnScheduled jobs={[...filterJob(jobStatus.DRAFT)]} />,
    // },
    {
      label: "UPCOMING",
      value: <Upcoming jobs={filterJob(jobStatus.UPCOMING)} />,
    },
    {
      label: "IN PROGRESS",
      value: <InProgress jobs={filterJob(jobStatus.INPROGRESS)} />,
    },
    {
      label: "COMPLETE",
      value: <Complete jobs={filterJob(jobStatus.COMPLETE)} />,
    },
  ];

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const [drawerState, setDrawerState] = useState(false);
  const [filterBtn, setFilterBtn] = useState('')

  const handleButtonClick = (buttonText) => {
    const currentDate = new Date();
    let newStartDate = null;
    let newEndDate = null;

    if (buttonText === 'Today') {
      newStartDate = currentDate;
      newEndDate = currentDate;
    } else if (buttonText === 'Yesterday') {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      newStartDate = yesterday;
      newEndDate = yesterday;
    } else if (buttonText === 'Last 7 days') {
      const last7Days = new Date(currentDate);
      last7Days.setDate(currentDate.getDate() - 6); // 6 days ago
      newStartDate = last7Days;
      newEndDate = currentDate;
    } else if (buttonText === 'Last 14 days') {
      const last14Days = new Date(currentDate);
      last14Days.setDate(currentDate.getDate() - 13); // 13 days ago
      newStartDate = last14Days;
      newEndDate = currentDate;
    }
    setFilterBtn(buttonText);
    // Update the state with the new dates
    setState([
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection'
      }
    ]);
  };


  const actions = [
    <>
      <Container sx={{ maxWidth: '460px !important' }}>
        <Box sx={{
          display: "grid", gridTemplateColumns: 'auto auto auto',
          gap: 1
        }}>
          <Button variant={filterBtn ==='Today' ?"contained":"outlined"} onClick={() => handleButtonClick('Today')}>
            Today
          </Button>
          <Button variant={filterBtn ==='Yesterday' ?"contained":"outlined"}  onClick={() => handleButtonClick('Yesterday')}>
            Yesterday
          </Button>
          <Button variant={filterBtn ==='Last 7 days' ?"contained":"outlined"}  onClick={() => handleButtonClick('Last 7 days')}>
            Last 7 days
          </Button>
          <Button variant={filterBtn ==='Last 14 days' ?"contained":"outlined"}  onClick={() => handleButtonClick('Last 14 days')}>
            Last 14 days
          </Button>
          <Button variant={filterBtn ==='Custom' ?"contained":"outlined"}  >
            Custom
          </Button>

        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4, alignItems: 'center' }}>
          <DateRange
            editableDateInputs={false}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}

          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => {
            setDrawerState(false)
            fetchJobs()
          }} >Cancel</Button>
          <Button onClick={() => {
            setDrawerState(false)
            fetchJobs(`?from=${dayjs(state[0]?.startDate).format('YYYY-MM-DD HH:mm:ss')}&to=${state[0]?.endDate ? dayjs(state[0]?.endDate).format('YYYY-MM-DD HH:mm:ss') : dayjs().format('YYYY-MM-DD HH:mm:ss')}`)
          }}>Ok</Button>
        </Box>
      </Container>
    </>,

  ];



  return (
    <>
      <NavBar
        title="Jobs"
        noFilter
        goBack

        
        // onRight={
        //   <BottomDrawer
        //     icon={<FilterAlt />}
        //     title="Filter by Date"
        //     data={actions}
        //     state={drawerState}
        //     setState={setDrawerState}
        //   />
        // }
      />
      {!jobList ? (
        <LoadingEffect />
      ) : (
        <>
          {!jobList?.length ? (
            <NoResultFound />
          ) : (
            <CustomSwipableTabs tabsData={tabData} />
          )}
        </>
      )}

      <Fab
        color="primary"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          transition: "ease",
        }}
        onClick={() => {
          navigate("/jobs/add");
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};


