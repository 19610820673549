import { Box, Typography } from "@mui/material";
import React from "react";
import {
  JobCard,
  UpComingJobCard,
} from "../../../../components/Cards/DisplayCards";
import { NoResultFound } from "../../../../components";

export function Upcoming({ jobs }) {
  if (!jobs?.length) {
    return <NoResultFound>No Upcoming Job Found</NoResultFound>;
  }

  return (
    <>
      <Typography>
      Total Upcoming :{" "}
        <Typography component={"strong"} style={{ fontWeight: "700" }}>
          {jobs.length}
        </Typography>
      </Typography>
      <Box
        py={2}
        overflow={"hidden"}
        my={1}
        display={"flex"}
        flexWrap={"wrap"}
        gap={2}
      >
        {jobs.map((item, index) => (
          <UpComingJobCard key={index} job={item} />
        ))}
      </Box>
    </>
  );
}

export default Upcoming;
