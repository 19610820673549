import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Fab, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useNavigate } from "react-router-dom";

const options = [
    {
        label: "Create Team",
        icon: <GroupsIcon />,
        url: "/team/add",
    },
    {
        label: "Create Client",
        icon: <PersonAddAlt1Icon />,
        url: "/clients/add",
    },
    {
        label: "Create Quotes",
        icon: <RequestQuoteIcon />,
        url: "/quote/add",
    },
    {
        label: "Create Material",
        icon: <Inventory2Icon />,
        url: "/material",
    },
];



export const FloatingActionButton = ({ option = false }) => {
    const navigate = useNavigate()
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);

    const handleToggleOptions = () => {
        setIsOptionsOpen((prev) => !prev);
    };


    return (
        <>
            {isOptionsOpen && (
                <>
                    {option ? option?.map((item, index) => (
                        <Tooltip title={item?.label} placement="left">
                            <Fab
                                onClick={item?.onClick}
                                color="secondary"
                                variant="extended"
                                sx={{
                                    position: "fixed",
                                    bottom: (theme) => theme.spacing(2.5 + (index + 1) * 8),
                                    right: (theme) => theme.spacing(2),
                                }}

                            >
                                <Typography sx={{ display: "flex", columnGap: 0.5 }}>
                                    {item?.icon}
                                    <Typography
                                        component={"p"}
                                        sx={{
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {item?.label}
                                    </Typography>
                                </Typography>
                            </Fab>
                        </Tooltip>
                    ))
                        :

                        options?.map((item, index) => (
                            <Tooltip title={item?.label} placement="left">
                                <Fab
                                    onClick={() => {
                                        navigate(item?.url)
                                    }}
                                    color="secondary"
                                    variant="extended"
                                    sx={{
                                        position: "fixed",
                                        bottom: (theme) => theme.spacing(2.5 + (index + 1) * 8),
                                        right: (theme) => theme.spacing(2),
                                    }}

                                >
                                    <Typography sx={{ display: "flex", columnGap: 0.5 }}>
                                        {item?.icon}
                                        <Typography
                                            component={"p"}
                                            sx={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item?.label}
                                        </Typography>
                                    </Typography>
                                </Fab>
                            </Tooltip>
                        ))
                    }
                </>
            )}

            <Fab
                color="primary"
                sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                    transition: "ease",
                }}
                onClick={handleToggleOptions}
            >
                {isOptionsOpen ? <CloseIcon /> : <AddIcon />}
            </Fab>
        </>
    );
};

