import { CircularProgress, Container } from '@mui/material'
import React from 'react'

export const LoadingEffect = () => {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', height:'500px' }}>

      <CircularProgress />
    </Container>
  )
}
