import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { JobAdd, JobDetail, JobUpdate, Jobs, UpdateJobFull } from '../../pages/Jobs'

export const JobsRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Jobs />} />
                <Route path='add' element={<JobAdd />} />
                <Route path='update/:job_id' element={<JobUpdate />} />
                <Route path='update/:job_id/full' element={<UpdateJobFull />} />
                <Route path='detail/:jobId' element={<JobDetail />} />
            </Routes>
        </>
    )
}
