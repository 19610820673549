import { LocalizationProvider, MobileTimePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import React from "react";

export function CustomTimePicker({ variant = "outlined",
  name,
  form,
  onChange,
  type = "text",
  rightIcon = false,
  leftIcon = false,
  onRightClick = false,
  onLeftClick = false,
  onBlur = false,
  ...rest }) {
  const errorText = form.touched[name] && form.errors[name];
  return (
    <>
      <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          onAccept={(e) => {
            form.setFieldValue(name, e?.$d);
          }}
          sx={{ width: "100%" }}
          defaultValue={dayjs(form.values[name] || '')} slotProps={{
            textField: {
              name: name,
              // ...field,
              // ...rest,
              error: !!errorText,
              helperText: errorText,
              fullWidth: "fullWidth",
              onBlur: () => {
                form.handleBlur(name);
              },
            },
          }}

        />
      </LocalizationProvider>
    </>
  );
}
