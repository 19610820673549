import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { UpdatePhone } from "../../UpdateDetails/UpdatePhone";

export const LabelTextPhoneCard = ({
    label,
    value,
    addButton = "",
    onUpdate=false
}) => {




    return (
        <>
            <Box sx={{ borderRadius: 1,  mb:2.2 }}>
                <Box
                    my={1}
                    sx={{
                        background: (theme) =>
                            theme?.palette?.mode === "light"
                                ? theme?.palette.grey[0]
                                : theme?.palette.grey[900],
                        // pl: 2,
                        pr: 1,
                        py: 0.3,
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                    }}
                >
                    <Box >

                        <Typography
                            sx={{
                                fontWeight: "400",
                                fontSize: 14,
                                textTransform: "capitalize",
                            }}
                        >
                            {label}
                        </Typography>

                        {typeof value === 'object' ? <>
                            {value?.map(item => (
                                <Typography
                                    key={item.id}
                                    noWrap={true}
                                    sx={{
                                        fontWeight: "700",
                                        textOverflow: "ellipsis",
                                        maxWidth: window.innerWidth * 0.85
                                    }}
                                >
                                    {item?.phone}
                                </Typography>
                            ))}

                        </>

                            :
                            <Typography
                                noWrap={true}
                                sx={{
                                    fontWeight: "700",
                                    textOverflow: "ellipsis",
                                    maxWidth: window.innerWidth * 0.85
                                }}
                            >
                                {value}
                            </Typography>}

                    </Box>
                  {onUpdate && <UpdatePhone phones={value} onUpdate={onUpdate} />}
                </Box>
                {addButton}
            </Box>


        </>
    );
};
