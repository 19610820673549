import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  emailValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../constant/validationSchema";
import { CustomTextField } from "../InputFields/TextFields";
import CustomButton from "../ActionButtons/CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import { ServiceCard, TextArrowCard } from "../Cards/DisplayCards";
import { NoResultFound } from "../NoResult/NoResult";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import CustomUnitSelect from "../InputFields/SelectFields/CustomUnitSelect";
import { FloatingBottomRight } from "./FloatingBottomRight";
import { CustomDialogWrapper } from "../Wrappers";
import { AddCustomText } from "../AddAdditionalContact";
import ServiceGlobalSearch from '../globalSearch/ServiceGlobalSearch'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

const CreateService = ({ id, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      unit_id: "",
      price: "",
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      description: stringValidationSchema,
      unit_id: numericValidationSchema,
      price: numericValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      createService(values, resetForm);
    },
  });

  const createService = (data, resetForm) => {
    ApiClient.service
      .post(data, id)
      .then((res) => {
        CustomToaster.success(res?.message);
        resetForm();
        onSubmit();
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        mt: 1,
        mx: 2,
        pb: 1,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container columnSpacing={{ md: 1, lg: 2 }} justifyContent="center">
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("name")}
            margin="normal"
            label="Service Name"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("description")}
            margin="normal"
            label="Description"
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomUnitSelect form={formik} name={"unit_id"} />
          {/* <CustomTextField
            form={formik}
            field={formik.getFieldProps("unit_id")}
            margin="normal"
            label="Unit Id"
          /> */}
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("price")}
            margin="normal"
            label="Price "
          />
        </Grid>
        <Grid
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "flex-end",
            gap: 1,
            mt: 3,
          }}
          item
          xs={12}
          width={"100%"}
        >
          <Button onClick={onClose} sx={{ color: "#A9A9A9" }}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Grid>

        <Grid sx={{ display: { md: "none" } }} item xs={12} width={"100%"}>
          <CustomButton label={"Save"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export function AddServiceActionButton({
  label = " Add Service to Job",
  onSubmit = false,
}) {
  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
    setSelected({
      cat: null,
      subCat: null,
      services: null,
      serviceIds: {},
    })
  };

  const [selected, setSelected] = React.useState({
    cat: null,
    subCat: null,
    services: null,
    serviceIds: {},
  });

  // catergory handling
  const [catergory, setCategory] = React.useState("");
  const fetchCategory = () => {
    ApiClient.serviceCategory
      .get()
      .then((res) => {
        if (res) {
          setCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Subcatergory handling
  const [subCatergory, setSubCategory] = React.useState("");
  const fetchSubCategory = () => {
    ApiClient.serviceSubCategory.get(selected.cat).then((res) => {
      if (res) {
        setSubCategory(res?.data);
      }
    });
  };

  //Service list handling
  const [services, setService] = React.useState("");
  const fetchServices = () => {
    ApiClient.service.get(selected.subCat).then((res) => {
      if (res) {
        setService(res?.data);
      }
    });
  };

  React.useEffect(() => {
    if (selected.services) {
      if (onSubmit) {
        onSubmit(selected.serviceIds);
        handleCloseMd();
        setSelected({
          cat: null,
          subCat: null,
          services: null,
          serviceIds: {},
        });
      }
    } else if (selected.subCat) {
      fetchServices();
    } else if (selected.cat) {
      fetchSubCategory();
    } else {
      fetchCategory();
    }
  }, [selected]);

  return (
    <>
      <Typography
        onClick={() => {
          handleClickOpenMd();
        }}
        sx={{
          padding: 0,
          mt: -1,
          ":hover": {
            cursor: "pointer",
          },
          fontSize: 16,
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <AddCircleIcon htmlColor="#5E95FF" sx={{ p: 0.2 }} />
        Add Service
      </Typography>

      <Dialog
        fullScreen
        open={openMd}
        onClose={handleCloseMd}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 0.5, flex: 1, display: "flex" }}
              component="div"
            >
              {selected.cat ? (
                <IconButton
                  sx={{ mr: 0.8, ml: -1 }}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    if (selected.services) {
                      setSelected((prevState) => ({
                        ...prevState,
                        services: null,
                      }));
                    } else if (selected.subCat) {
                      setSelected((prevState) => ({
                        ...prevState,
                        subCat: null,
                      }));
                    } else if (selected.cat) {
                      setSelected((prevState) => ({ ...prevState, cat: null }));
                    }
                  }}
                  aria-label="close"
                  size="small"
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              ) : (
                ""
              )}

              <Typography
                sx={{ alignSelf: "flex-end" }}
                component={"span"}
                variant="h6"
              >
                {label}
              </Typography>
            </Typography>
            <ServiceGlobalSearch setSelected={setSelected} selected={selected} />

            <IconButton
              sx={{ mr: -2 }}
              edge="end"
              color="inherit"
              onClick={handleCloseMd}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          {
            // selected.services ? (
            //   <>
            //     <Box
            //       component={"form"}
            //       sx={{
            //         flex: 1,
            //         gap: 2,
            //         display: "flex",
            //         flexDirection: "column",
            //       }}
            //     >
            //       {Object.values(selected.serviceIds)?.map((value, index) => (
            //         <EditableServiceCard key={services.id} service={value} />
            //       ))}
            //     </Box>
            //   </>
            // ) :
            selected.subCat ? (
              <>
                {!services ? (
                  <LoadingEffect />
                ) : (
                  <>
                    <CustomDialogWrapper
                      fullWidth
                      element={
                        <Button sx={{ mb: 2, width: '100%' }} variant="outlined">
                          Add Service
                        </Button>
                      }
                      title={"Add Service"}
                    >
                      <CreateService
                        onSubmit={fetchServices}
                        id={selected.subCat}
                      />
                    </CustomDialogWrapper>

                    {services?.length ? (
                      <>

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: { lg: 4 },
                          }}
                        >
                          {services?.map((service) => (
                            <ServiceCard
                              selectable
                              hideButton
                              selectedIds={selected.serviceIds}
                              key={service.id}
                              onClick={(service, isSelected) => {
                                if (isSelected) {
                                  setSelected((prevState) => {
                                    const updatedServiceIds = {
                                      ...prevState.serviceIds,
                                    };
                                    delete updatedServiceIds[service.id];
                                    return {
                                      ...prevState,
                                      serviceIds: updatedServiceIds,
                                    };
                                  });
                                } else {
                                  setSelected((prevState) => ({
                                    ...prevState,
                                    serviceIds: {
                                      ...prevState.serviceIds,
                                      [service.id]: service,
                                    },
                                  }));
                                }
                              }}
                              service={service}
                            />
                          ))}
                        </Box>
                        <CustomButton
                          onClick={() => {
                            setSelected((prevState) => ({
                              ...prevState,
                              services: true,
                            }));
                          }}
                          label={"Continue"}
                        />
                      </>
                    ) : (
                      <>
                        <NoResultFound>No Service Found</NoResultFound>
                      </>
                    )}
                  </>
                )}
              </>
            ) : selected.cat ? (
              //SubCategory list here
              <>
                {!subCatergory ? (
                  <LoadingEffect />
                ) : (
                  <>
                    <AddCustomText fullWidth element={<Button sx={{ width: '100%' }} variant="outlined">Add Category</Button>} title={'Add Category'} label={'Category'} api={ApiClient.serviceSubCategory} name={'name'} onUpdate={fetchSubCategory}

                      initialValues={{ servicecategoryid: selected.cat }}
                    />

                    {subCatergory?.length ? (
                      <>
                        {subCatergory?.map((cat) => (
                          <TextArrowCard
                            key={cat.id}
                            title={cat.name}
                            onClick={() => {
                              setSelected((prevState) => ({
                                ...prevState,
                                subCat: cat.id,
                              }));
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <NoResultFound>No Service Found</NoResultFound>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              //category listing
              <>
                {!catergory ? (
                  <LoadingEffect />
                ) : (
                  <>
                    <AddCustomText fullWidth element={<Button sx={{ width: '100%' }} variant="outlined">Add Industry</Button>} title={'Add Industry'} label={'Industry'} api={ApiClient.serviceCategory} name={'name'} onUpdate={fetchCategory} />

                    {catergory?.length ? (
                      <>
                        {catergory?.map((cat) => (
                          <TextArrowCard
                            key={cat.id}
                            title={cat.name}
                            onClick={() => {
                              setSelected((prevState) => ({
                                ...prevState,
                                cat: cat.id,
                              }));
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <NoResultFound>No Service Found</NoResultFound>
                      </>
                    )}
                  </>
                )}
              </>
            )
          }
        </Box>
      </Dialog>
    </>
  );
}
