import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from "../../../../constant/ThemeColors";
import {
  ReadableDate,
  ReadableDateTime,
} from "../../../../utils/ReadableDateTime";
import ReadableAddress from "../../../../utils/ReadableAddress";

function WalkThroughCard({ id, url = "/walk-through/detail/" }) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        navigate(url + "3");
      }}
      px={1}
      borderRadius={1}
      boxShadow={"0 0 3px -3px"}
      width={{ lg: "32%", md: "48%", xs: "100%" }}
      sx={{
        ":hover": {
          cursor: "pointer",
          boxShadow: ThemeColors.hoverBoxShadow,
        },
        border: 0.5,
        borderBottom: 4,
        borderColor: ThemeColors.borderColor,
      }}
    >
      <List dense>
        <ListItem sx={{ justifyContent: "space-between" }}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 18,
              fontWeight: "600",
              textTransform: "capitalize",
            }}
            primary={"Client Name"}
            secondary={"789 Yonge Street, Amigo Beach, Ontario, M4W 2G8"}
          />
        </ListItem>
        <ListItem sx={{ justifyContent: "space-between" }}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 16,
              fontWeight: "600",
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            primary={ReadableDateTime("2023-12-15")}
          />
        </ListItem>

        <ListItem sx={{ justifyContent: "space-between" }}>
          <Stack direction="row" spacing={1}>
            <Chip
              label="Assign to Jason drake"
              sx={{ color: "#FFB92F", background: "#FFEFD6", fontWeight: "500" }}
            />
            <Chip
              label="Filled out"
              sx={{ color: "#587D00", background: "#E7FFB2", fontWeight: "500" }}
            />
            <Chip
              label="Move to quote"
              sx={{ color: "#fff", background: "#0169EE", fontWeight: '500' }}
            />
          </Stack>
        </ListItem>
      </List>
    </Box>
  );
}

export { WalkThroughCard };
