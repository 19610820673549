import { Box, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CompleteJobCard } from "../../../components/Cards/DisplayCards";
import { NoResultFound } from "../../../components";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { useSelector } from "react-redux";
import ApiClient from "../../../constant/API_URLS";
import { jobStatus } from "../../../constant/GlobalVariable";

function Complete({ filterDate }) {


  const [jobList, setJobList] = useState("");
  const jobs = jobList?.data || []

  const fetchJobs = (filter) => {
    ApiClient.job
      .get(filter)
      .then((res) => {
        if (res) {
          setJobList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJobs(filterDate + `${filterDate ? '&' : '?'}status=${jobStatus.COMPLETE}&page=1`);
  }, [filterDate]);

  const [filteredData, setFilteredData] = useState("");
  const searchText = useSelector((state) => state?.searchBarText);
  useEffect(() => {
    if (searchText && jobs?.length) {
      const searchTextLowerCase = searchText.toLowerCase();
      const searchWords = searchTextLowerCase.split(" ");

      const filteredData = jobs.filter((item) => {
        const jobNameLowerCase = item?.name?.toLowerCase();
        const firstNameLowerCase =
          item?.client_details?.first_name?.toLowerCase();
        const lastNameLowerCase = item?.client_details?.last_name?.toLowerCase();
        const companyNameLowerCase =
          item?.client_details?.company_name?.toLowerCase();
        const address = item?.client_details;
        const addressStrings =
          `${address.street}, ${address.city}, ${address.state}, ${address.pin_code}`?.toLowerCase();

        return (
          searchWords.some((word) => firstNameLowerCase?.includes(word)) ||
          searchWords.some((word) => jobNameLowerCase?.includes(word)) ||
          searchWords.some((word) => companyNameLowerCase?.includes(word)) ||
          searchWords.some((word) => lastNameLowerCase?.includes(word)) ||
          searchWords.some((word) => addressStrings.includes(word))
        );
      });

      setFilteredData(filteredData);
    } else {
      setFilteredData("");
    }
  }, [searchText]);



  if (!jobList) {
    return <LoadingEffect />
  }

  if (!jobs?.length) {
    return <NoResultFound>No Complete Job Found</NoResultFound>;
  }

  return (
    <>

      <Typography>
        Total Complete :{" "}
        <Typography component={"strong"} style={{ fontWeight: "700" }}>
          {jobs.length}
        </Typography>
      </Typography>


      <Box
        py={2}
        overflow={"hidden"}
        my={1}
        display={"flex"}
        flexWrap={"wrap"}
        gap={2}
      >

        {jobs.map((item, index) => (
          <CompleteJobCard key={index} job={item} />
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', mt: 4 }}>
      <Pagination onChange={(e, value) => {
        fetchJobs(filterDate + `${filterDate ? '&' : '?'}status=${jobStatus.COMPLETE}&page=${value}`);
      }} size='large' page={jobList?.current_page} count={jobList?.last_page} color="primary" />
      </Box>
    </>
  );
}

export default Complete;
