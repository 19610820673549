export function parseAddress(addressData) {
  const addressObj = {
    city: '',
    state: '',
    country: '',
    postalCode: '',
    street: '',
  };

  addressData?.forEach((addressComponent) => {
    const types = addressComponent.types;
    const longName = addressComponent.long_name;
    const shortName = addressComponent.short_name;

    if (types.includes('locality')) {
      addressObj.city = longName;
    } else if (types.includes('administrative_area_level_1')) {
      addressObj.state = longName;
    } else if (types.includes('country')) {
      addressObj.country = longName;
    } else if (types.includes('postal_code')) {
      addressObj.postalCode = shortName; // Google Places API provides the short_name for postal code
    } else if (types.includes('street_number')) {
      addressObj.street = longName;
    } else if (types.includes('route')) {
      addressObj.street +=
        (addressObj.street.length > 0 ? ', ' : '') + longName;
    } else if (
      types.includes('neighborhood') ||
      types.includes('sublocality')  ||
      types.includes('route')
    ) {
      addressObj.street +=
        (addressObj.street.length > 0 ? ', ' : '') + longName;
    }
  });

  return addressObj;
}
