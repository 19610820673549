import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component={"div"} sx={{ p: 0 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function CustomSwipableTabs({ tabsData, tabContainerStyle = {}, currentTab=0 }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(currentTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [isSticky, setIsSticky] = React.useState(false);
  const handleScroll = () => {
    if (window.scrollY > 70) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          top: 'auto',
          zIndex: 999,
          bgcolor: isSticky
            ? "background.paper"
            : (theme) =>
              theme.palette.mode === "light"
                ? "rgba(0,0,0,0.05)"
                : "rgba(255,255,255,0.08)",
          borderRadius: 1,
          boxShadow: isSticky ? "0 3px 5px 0px" : 0,
          width: "100%",
          ...tabContainerStyle
        }}
        
      >
        <Tabs
          sx={{
            borderRadius: 1,
            px: 1,
          }}

          value={value}
          onChange={handleChange}
          // indicatorColor="secondary"
          TabIndicatorProps={{
            style: {
              height: 3,
              borderRadius: 1,

            },
          }}
          textColor="primary"
          variant="scrollable"
          scrollButton
          allowScrollButtonsMobile
          aria-label="full width tabs example"

        >
          {tabsData.map((tab, index) => (
            <Tab
              sx={{
                // bgcolor: (theme) => theme.palette.mode === 'light' ? 'rgba(0,0,0,0.05)' : 'rgba(255,255,255,0.08)',
                minWidth: 120,
                px: 2,
                fontSize: 15,

              }}
            
              key={index}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      {/* {tabsData.map((tab, index) => (
          <TabPanel key={index} value={value} index={index} dir={theme.direction}>
            {tab.value}
          </TabPanel>
        ))} */}
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabsData.map((tab, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {tab.value}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
}
