import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LiveTracking from '../../pages/LiveTracking'

export const LiveTrackingRoute = () => {
    return (
        <>
            <Routes>
                <Route index element={<LiveTracking />} />
            </Routes>
        </>
    )
}
