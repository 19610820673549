import { createSlice } from '@reduxjs/toolkit';

const initialState = 'sear';

const searchBarText = createSlice({
  name: 'searchBarText',
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSearchText } = searchBarText.actions;
export default searchBarText.reducer;
