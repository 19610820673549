import {
  Box,
  List,
  ListItemText,
  ListItem,
  Typography,
  colors,
} from "@mui/material";
import React from "react";
import { ThemeColors } from "../../../../constant/ThemeColors";
import CalculateTotalServiceMaterial from "../../../../utils/CalculateTotalServiceMaterial";
import DisplayUserAddress from "../../../../utils/DisplayUserAddress";
import { ReadableDate } from "../../../../utils/ReadableDateTime";
import { useNavigate } from "react-router-dom";
import { quoteStatus } from "../../../../constant/GlobalVariable";

export function SentQuoteCard({ quote, url }) {
  const navigate = useNavigate();

  const subTotal = CalculateTotalServiceMaterial(
    quote?.materials,
    quote?.services
  );

  const addTax = () => {
    let rate = quote?.quote_tax_rate || 0;
    let tax = (Number(subTotal) * Number(rate)) / 100;
    return {
      total: Number(subTotal) + (Number(subTotal) * Number(rate)) / 100,
      tax,
    };
  };
  return (
    <Box
      onClick={() => {
        if (url) {
          navigate(url + quote?.quote_id);
        }
      }}
      px={1}
      borderRadius={1}
      boxShadow={ThemeColors.boxShadow}
      border={0.5}
      borderBottom={quote?.is_approved ? 3 : 3}
      borderColor={
        quote?.is_approved === quoteStatus.ACCEPTED
          ? colors.green[600]
          : quote?.is_approved === quoteStatus.DECLINED
            ? colors.red[600]
            : colors.blue[500]
      }
      width={{ lg: "32%", md: "48%", xs: "100%" }}
      sx={{
        position: "relative",
        ":hover": {
          cursor: "pointer",
          boxShadow: ThemeColors.hoverBoxShadow,
        },
      }}
    >
      <List dense>
        <ListItem dense sx={{ justifyContent: "space-between" }}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 18,
              fontWeight: "600",
              textTransform: "capitalize",
            }}
            primary={quote.name}
          />

          <ListItemText
            primaryTypographyProps={{
              fontSize: 14,
            }}
            secondaryTypographyProps={{
              fontSize: 17,
              fontWeight: "600",
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
            primary={ReadableDate(quote.created_at)}
            secondary={
              "$" + addTax()?.total?.toFixed(2)}
          />
        </ListItem>
        <ListItem dense>
          <ListItemText
            primaryTypographyProps={{
              // fontSize: 18,
              fontWeight: "600",
            }}
            secondaryTypographyProps={{
              fontSize: 16,
              // fontWeight: "400",
            }}
            primary={
              quote.client_details?.first_name +
              " " +
              quote.client_details?.last_name
            }
            secondary={DisplayUserAddress(quote?.client_details)}
          />
        </ListItem>
      </List>

      <Typography
        sx={{
          position: "absolute",
          top: -12,
          right: 12,
          zIndex: 50,
          px: 1,
          background: "white",
          color:
            quote?.is_approved === quoteStatus.ACCEPTED
              ? colors.green[600]
              : quote?.is_approved === quoteStatus.DECLINED
                ? colors.red[600]
                : colors.blue[500],
        }}
      >
        {quote?.is_approved === quoteStatus.ACCEPTED ? (
          "Accepted"
        ) : (
          <>{quote?.is_approved === quoteStatus.DECLINED ? "Declined" : "Pending"}</>
        )}
      </Typography>
    </Box>
  );
}
