import { ThemeProvider, colors, createTheme } from '@mui/material'
import { amber, blue, deepOrange, grey } from '@mui/material/colors'
import React from 'react'
import { useSelector } from 'react-redux';

const ThemeContext = ({ children }) => {
    const themeMode = useSelector(state => state.navbar.themeMode);



    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: '#343541',
                paper: '#343541',
            }

        },
        components: {
            MuiDrawer: {
                background: 'rgba(255, 255, 255, 0.06)'
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        ':hover': {
                            background: 'rgba(255, 255, 255, 0.25)',
                        },
                        background: 'rgba(255, 255, 255, 0.10)',
                    },
                },

            }
        }
    });

    const lightTheme = createTheme({
        palette: {
            text: {
                primary: colors.common.black,
                secondary: colors.common.black,
            },
            

        },
        components: {
            MuiPaper: 'rgba(255, 255, 255, 0.25)',
            MuiDrawer: {
                background: 'rgba(255, 255, 255, 0.25)'
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        ':hover': {
                            background: 'rgba(255, 255, 255, 0.25)',
                        },
                        background: 'rgba(255, 255, 255, 0.10)',
                    },
                },

            }
        }

    });


    return (<>
        <ThemeProvider theme={themeMode === 'dark' ? darkTheme : lightTheme}>
            {children}
        </ThemeProvider></>)
}

export default ThemeContext