import { createSlice } from '@reduxjs/toolkit';
import { GET_TOKEN, GET_USER } from '../../utils/LocalStorageGetSet';

const initialState = {
    token: GET_TOKEN,
    user: GET_USER

};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        }
    },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
