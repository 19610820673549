import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
    JobDetailContainerListCard,
    LabelTextCard,
    NoResultFound,
} from "../../components";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ReadableDate } from "../../utils/ReadableDateTime";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import ApiClient from "../../constant/API_URLS";
import ReadableAddress from "../../utils/ReadableAddress";

function JobDetail() {
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get("id");

    function calculateTotal(jobData) {
        let total = 0;

        if (jobData.materials && Array.isArray(jobData.materials)) {
            jobData.materials.forEach((material) => {
                if (material.total && material.unit_price && material.unit_count) {
                    // total += parseFloat(material.total);
                    const unitPrice = parseFloat(material.unit_price);
                    const unitCount = parseFloat(material.unit_count);
                    total += unitPrice * unitCount;
                }
            });
        }
        if (jobData.services && Array.isArray(jobData.services)) {
            jobData.services.forEach((service) => {
                if (service.total && service.unit_price && service.unit_count) {
                    // total += parseFloat(service.total);
                    const unitPrice = parseFloat(service.unit_price);
                    const unitCount = parseFloat(service.unit_count);
                    total += unitPrice * unitCount;
                }
            });
        }

        return total.toFixed(2);
    }
    const [services, setServices] = useState([]);
    const [material, setMaterial] = useState([]);

    const navigate = useNavigate();
    const [jobDetail, setJobDetail] = useState("");

    const fetchJobDetail = () => {
        ApiClient.publicJobDetail
            .get("?id=" + jobId)
            .then((res) => {
                if (res) {
                    setJobDetail(res?.data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (jobId) {
            fetchJobDetail();
        }
    }, [jobId]);

    useEffect(() => {
        if (jobDetail && !services?.length && !material?.length) {
            setServices(jobDetail.services);
            setMaterial(jobDetail.materials);
        }
    }, [jobDetail]);

    // if (!jobDetail) {
    //     return <LoadingEffect />;
    // }
    // if (!jobDetail) {
    //     return <NoResultFound />;
    // }

    return (
        <>
            {jobDetail ? (
                <Container>
                    <Box
                        sx={{
                            alignContent: 'center',
                            textAlign: 'center'
                        }}>

                        <img src={'/assets/static/img/logo.png'} width={420} height={160} />
                        <Typography variant='h6'>
                            Job Detail #{jobDetail?.job_id}
                        </Typography>
                        <Divider />
                    </Box>
                    <Box
                        flexGrow
                        component={"div"}
                        sx={{
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            mt: 1,
                            pb: 4,
                        }}
                    >
                        <LabelTextCard
                            label={"Created on"}
                            value={ReadableDate(jobDetail?.created_at)}
                        />
                        {/* <LabelTextCard
            label={"Completed Date"}
            value={
              jobDetail?.end_time
                ? ReadableDate(jobDetail?.end_time)
                : "yet to complete"
            }
          /> */}
                        <LabelTextCard
                            label={"Name"}
                            value={
                                jobDetail?.client_details?.first_name +
                                " " +
                                jobDetail?.client_details?.last_name
                            }
                        />
                        <LabelTextCard
                            noWrap
                            label={"Address"}
                            value={`${ReadableAddress(jobDetail?.client_details)}`}
                        />

                        <LabelTextCard
                            label={"Team"}
                            value={
                                jobDetail?.team_details?.map((item, index) => {
                                    if (jobDetail?.team_details?.length - 1 == index) {
                                        return `${item?.first_name + " " + item?.last_name} `;
                                    }
                                    return `${item?.first_name + " " + item?.last_name}, `;
                                }) || "Yet to Assigned"
                            }
                        />

                        <JobDetailContainerListCard
                            hideCardButton
                            hideListButton
                            listData={services}
                            cardButton="service"
                            cardTitle="Services"
                        />
                        {material?.length ? (
                            <JobDetailContainerListCard
                                hideCardButton
                                hideListButton
                                listData={material}
                                jobId={jobId}
                                cardButton="material"
                                cardTitle="Material"
                            />
                        ) : (
                            ""
                        )}

                        <Box
                            sx={{
                                overflow: "hidden",
                                width: "100%",
                                pr: 0.5,
                                mb: 6,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    mt: 1,
                                    border: 0.8,
                                    borderColor: "#B7D4FF",
                                    background: "#F8F9FC",
                                    borderRadius: 1.6,
                                    borderBottom: 3,
                                    borderBottomColor: "#B7D4FF",
                                    px: 1,
                                }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography> Subtotal: </Typography>
                                        <Typography>${calculateTotal(jobDetail)}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography> Tax: </Typography>
                                        <Typography>$0.00</Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography> Grand Total: </Typography>
                                        <Typography>${calculateTotal(jobDetail)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            ) : (
                <LoadingEffect />
            )}
        </>
    );
}

export default JobDetail;
