import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  phoneValidationSchema,
  stringValidationSchema,
} from "../../constant/validationSchema";
import CustomButton from "../ActionButtons/CustomButton";
import { CustomTextField } from "../InputFields/TextFields";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function ConfirmationDialog({
  onSubmit,
  name,
  value,
  label = "",
  iconText = "",
  onClose,
  iconColor = "#f83a3a",
  children,
  id,
  text,
  title = "Are you sure?",
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
    if (onClose) {
      onClose();
    }
  };

  const handleCloseSuccess = () => {
    setOpenMd(false);
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleAgree = () => {
    onSubmit(id, handleCloseSuccess, rest);
  };

  return (
    <>
      {children ? (
        <Box onClick={handleClickOpenMd}>{children}</Box>
      ) : (
        <>
          {" "}
          {iconText ? (
            <Button
              sx={{
                color: iconColor,
              }}
              fullWidth
              onClick={handleClickOpenMd}
            >
              <Typography sx={{ fontSize: 14, pr: 1, fontWeight: "500" }}>
                {iconText}
              </Typography>
              <Delete />
            </Button>
          ) : (
            <IconButton
              sx={{
                color: iconColor,
              }}
              onClick={handleClickOpenMd}
            >
              <Delete fontSize="12px" />
            </IconButton>
          )}
        </>
      )}

      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <>
            <DialogContentText>
              {text ? text : <>Please Confirm this Action</>}
            </DialogContentText>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMd} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleAgree}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
