import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CustomTextField } from "../../components/InputFields/TextFields";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  confirmPasswordValidationSchema,
  emailValidationSchema,
  passwordValidationSchema,
} from "../../constant/validationSchema";

import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import { useNavigate, useParams } from "react-router-dom";

export function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  const login = (data) => {
    ApiClient.resetPassword.post(data).then((res) => {
      if (res) {
        // window.location = "/";
        CustomToaster.success(res.message);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      token,
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object().shape({
      password: passwordValidationSchema,
      password_confirmation: confirmPasswordValidationSchema,
    }),
    onSubmit: (values) => login(values),
  });

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={"/assets/static/img/logo.png"} />
          <Typography component="h1" variant="h5" mt={6}>
            Reset Password
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("password")}
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("password_confirmation")}
              margin="normal"
              fullWidth
              name="password_confirmation"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 3, p: 1, py: 1.4 }}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
