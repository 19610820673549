import React, { useEffect, useState } from "react";
import { setPageTitle } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { NavBar, NoResultFound } from "../../components";
import { Box, Pagination, Typography } from "@mui/material";
import { InvoiceCard } from "../../components/Cards/DisplayCards";
import ApiClient from "../../constant/API_URLS";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";

export const Invoices = () => {
  const dispatch = useDispatch();

  const [rawinvoiceList, setInvoiceList] = useState("");
  const invoiceList = rawinvoiceList?.data;
  const fetchInvoice = (filter = '?page=1') => {
    ApiClient.invoice
      .get(filter)
      .then((res) => {
        if (res) {
          setInvoiceList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  const [filteredData, setFilteredData] = useState("");
  const searchText = useSelector((state) => state?.searchBarText);
  useEffect(() => {
    if (searchText && invoiceList) {
      const searchTextLowerCase = searchText.toLowerCase();
      const filteredData = invoiceList.filter((item) => {
        const nameLowerCase = item.name.toLowerCase();
        const firstNameLowerCase = item?.clientInfo?.first_name.toLowerCase();
        const lastNameLowerCase = item?.clientInfo?.last_name.toLowerCase();
        const jobId = item?.job_id.toLowerCase();
        const invoice_number = item?.invoice_number.toLowerCase();
        return (
          searchTextLowerCase.includes(firstNameLowerCase) ||
          searchTextLowerCase.includes(lastNameLowerCase) ||
          firstNameLowerCase.includes(searchTextLowerCase) ||
          lastNameLowerCase.includes(searchTextLowerCase) ||
          nameLowerCase.includes(searchTextLowerCase) ||
          searchTextLowerCase.includes(nameLowerCase) ||
          jobId.includes(searchTextLowerCase) ||
          searchTextLowerCase.includes(jobId) ||
          invoice_number.includes(searchTextLowerCase) ||
          searchTextLowerCase.includes(invoice_number)
        );
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData("");
    }
  }, [searchText, rawinvoiceList]);

  const getList = () => {
    return filteredData ? filteredData : invoiceList;
  };

  return (
    <>
      <NavBar title="Invoices" noFilter />

      {invoiceList ? (
        <>
          {invoiceList?.length ? (
            <>
              <Box pt={1}>
                <Typography component={"strong"}>
                  Total Invoices: {getList()?.length}
                </Typography>
              </Box>{" "}
              <Box
                component={"div"}
                sx={{ py: 2, display: "flex", flexWrap: "wrap", gap: 2 }}
              >
                {getList()?.map((item, index) => (
                  <InvoiceCard key={item?.invoice_number} invoice={item} />
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', mt: 4 }}>
                <Pagination onChange={(e, value) => {
                  fetchInvoice(`?page=${value}`);
                }} size='large' page={rawinvoiceList?.current_page} count={rawinvoiceList?.last_page} color="primary" />
              </Box>
            </>
          ) : (
            <NoResultFound>No Invoice Found</NoResultFound>
          )}
        </>
      ) : (
        <LoadingEffect />
      )}
    </>
  );
};
