import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Invoices } from '../../pages/Invoices'
import InvoiceDetail from '../../pages/Invoices/detail'

export const InvoicesRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Invoices />} />
                <Route path='detail/:invoiceId' element={<InvoiceDetail />} />
            </Routes>
        </>
    )
}
