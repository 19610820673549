import { useCallback } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { PersonPinCircle } from "@mui/icons-material";
import ReadableAddress from "../../../utils/ReadableAddress";

export const LogisticsFleetVehicle = (props) => {
  const { onDeselect, onSelect, selected, vehicle } = props;

  const handleToggle = useCallback(() => {
    if (!selected) {
      onSelect?.(vehicle.member_info?.id);
    } else {
      onDeselect?.();
    }
  }, [onDeselect, onSelect, selected, vehicle]);

  const timesheet = vehicle?.timesheet[0] || {};
  return (
    <Stack component="li">
      <ButtonBase
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          p: 2,
          textAlign: "left",
          width: "100%",
        }}
        onClick={handleToggle}
      >
        <Avatar sx={{ mr: 2 }}>
          <SvgIcon>
            <PersonPinCircle />
          </SvgIcon>
        </Avatar>
        <div>
          <Typography sx={{ textTransform: "capitalize" }}>
            {vehicle?.member_info?.first_name +
              " " +
              vehicle?.member_info?.last_name}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            noWrap
            maxWidth={280}
          >
            {ReadableAddress(vehicle?.member_info)}
          </Typography>
        </div>
      </ButtonBase>
      <Collapse in={selected}>
        <Divider />
        <Box sx={{ p: 2 }}>
          {/* <Stack spacing={1}>
            <Typography color="text.secondary" variant="caption">
              Temperature (good)
            </Typography>
            <Stack alignItems="center" direction="row" spacing={2}>
              <LinearProgress
                value={8}
                sx={{ flexGrow: 1 }}
                variant="determinate"
              />
              <Typography color="text.secondary" variant="body2">
                {vehicle.temp}
              </Typography>
            </Stack>
          </Stack> */}
          <Timeline
            position="right"
            sx={{
              px: 3,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {/* {
                    "id": 6,
                    "job_id": "SKY005",
                    "user_id": 134,
                    "start_time": "2023-12-20T07:32:01.558Z",
                    "end_time": "2023-12-20T07:32:16.477Z",
                    "sheet_lat": "28.3739927",
                    "sheet_long": "77.3291475",
                    "is_deleted": 0,
                    "created_at": "2023-12-20T07:32:17.000000Z",
                    "updated_at": "2023-12-20T07:32:17.000000Z"
                } */}

            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  <Typography variant="body2">Job id</Typography>
                  <Typography color="text.secondary" variant="caption">
                    {timesheet?.job_id}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  <Typography variant="body2">Start Time</Typography>
                  <Typography color="text.secondary" variant="caption">
                    {timesheet?.start_time}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
            {timesheet?.end_time ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                </TimelineSeparator>
                <TimelineContent>
                  <div>
                    <Typography variant="body2">End Time</Typography>
                    <Typography color="text.secondary" variant="caption">
                      {timesheet?.end_time}
                    </Typography>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ) : (
              ""
            )}
          </Timeline>
        </Box>
      </Collapse>
    </Stack>
  );
};
