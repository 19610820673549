import { GroupAddOutlined, Groups, Groups2Rounded, Restore } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  colors,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from "../../../../constant/ThemeColors";
import { ReadableDate } from "../../../../utils/ReadableDateTime";
import Groups2Icon from "@mui/icons-material/Groups2";
import ReadableAddress from "../../../../utils/ReadableAddress";
import { DeleteConfirmationDialog } from "../../../DeleteDetails/DeleteConfirmationDialog";
import { ConfirmationDialog } from "../../../Wrappers";
import ApiClient from "../../../../constant/API_URLS";
import { jobStatus } from "../../../../constant/GlobalVariable";
import CustomToaster from "../../../../utils/CustomToaster";

export function ArchiveJobCard({ id, url = "/jobs/detail/", job,fetchArchive  }) {
  const navigate = useNavigate();

  const handleClick = (_, handleCloseSuccess) => {
    ApiClient.job
      .put(job?.job_id, { is_deleted: 0 })
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          handleCloseSuccess();
          if (fetchArchive) {
            fetchArchive()
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box
        // onClick={() => {
        //     navigate(url + job?.job_id);
        // }}
        px={1}
        borderRadius={1}
        boxShadow={"0 0 3px -3px"}
        width={{ lg: "32%", md: "48.5%", xs: "100%" }}
        sx={{
          ":hover": {
            // cursor: "pointer",
            boxShadow: ThemeColors.hoverBoxShadow,
          },
          border: 0.5,
          borderBottom: 4,
          borderColor: ThemeColors.borderColor,
        }}
      >
        <List dense>

          <ListItem sx={{ justifyContent: "space-between" }}>

            <ListItemIcon sx={{
              position: 'absolute',
              right:-22,
              zIndex:100,
              top:-14,
              
            }}>
              <ConfirmationDialog title={'Please confirm'} text={'Are you sure you want to proceed with the restore? This action will revert the system to its previous state'} onSubmit={handleClick}>
                <ListItemButton
                  sx={{
                    color: ThemeColors.primary,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Restore />
                  {/* <Delete /> */}
                  {/* <Typography component={"span"}>Delete</Typography> */}
                </ListItemButton>
              </ConfirmationDialog>
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                fontSize: 18,
               
                fontWeight: "600",
                textTransform: "capitalize",
              }}
              primary={job?.name}
            />

            <ListItemText
              primaryTypographyProps={{
                fontSize: 18,
                fontWeight: "700",
              }}
              sx={{
                // marginRight:1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
              primary={job.job_id}
            />

          </ListItem>
          <ListItem dense>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "500",
                // color: "GrayText",
              }}
              primary={
                job?.client_details?.first_name +
                " " +
                job?.client_details?.last_name
              }
              secondaryTypographyProps={{
                fontSize: 14,
                // color: "GrayText",
              }}
              secondary={ReadableAddress(job?.client_details)}
            />
          </ListItem>
        </List>
        <Divider />
        {job?.team_details?.length ? (
          <Typography
            display={"flex"}
            justifyContent={"space-between"}
            columnGap={1}
            px={2}
            py={1}
          >
            <Typography
              noWrap
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                py: 0.3,
              }}
            >
              <Typography component={"span"}>
                <Groups2Icon
                  sx={{ display: "flex", mr: 0.5 }}
                  htmlColor={ThemeColors.iconColor}
                />
              </Typography>
              <Typography
                component={"span"}
                noWrap
                sx={{
                  fontWeight: "400",
                  // color: "GrayText"
                }}
              >
                {job?.team_details.map((person, index) => {
                  if (person) {
                    if (index === job?.team_details?.length - 1) {
                      return `${person?.first_name + " " + person?.last_name}`;
                    }
                    return `${person?.first_name + " " + person?.last_name}, `;
                  }
                })}
              </Typography>
            </Typography>
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
