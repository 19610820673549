import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTab,
  setMenu,
  setThemeMode,
  setToken,
} from "../../redux/actions";
import styled from "@emotion/styled";
import { Avatar, Switch, Typography, colors } from "@mui/material";
import { GET_USER, SET_TOKEN, SET_USER } from "../../utils/LocalStorageGetSet";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { CloseRounded, Inventory, Inventory2Outlined, PunchClockOutlined, ShareLocation } from "@mui/icons-material";

export const LeftMenuDrawer = () => {
  const menuOpen = useSelector((state) => state.navbar.menuOpen);
  const currentTab = useSelector((state) => state.navbar.currentTab);
  const themeMode = useSelector((state) => state.navbar.themeMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const MaterialUISwitch = styled((props) => (
    <Switch
      checked={themeMode === "dark"}
      value={themeMode}
      onChange={(e) => {
        dispatch(setThemeMode(e.target.value === "dark" ? "light" : "dark"));
      }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 96,
    height: 36,
    padding: 7,
    alignSelf: "center",
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transitionDuration: "400ms",
      transform: "translateX(0px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(60px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const menuList = [
    {
      path: "/dashboard",
      label: "Dashboard",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.819"
          height="18"
          fill={`${0 === currentTab ? "#137ABF" : "#000"}`}
          viewBox="0 0 17.819 18"
        >
          <g
            id="Group_238"
            data-name="Group 238"
            transform="translate(11605 6356.712)"
          >
            <path
              id="Path_87"
              data-name="Path 87"
              d="M6.96,123.577H.9a.9.9,0,0,1-.9-.9v-4.852a.9.9,0,0,1,.9-.9H6.96a.9.9,0,0,1,.9.9v4.852A.9.9,0,0,1,6.96,123.577Zm-5.826-1.134h5.59v-4.379H1.134v4.379Z"
              transform="translate(-11605 -6462.42)"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M6.96,11.7H.9a.9.9,0,0,1-.9-.9V2.827a.9.9,0,0,1,.9-.9H6.96a.9.9,0,0,1,.9.9V10.8a.9.9,0,0,1-.9.9ZM1.134,10.567h5.59v-7.5H1.134v7.5Z"
              transform="translate(-11605 -6358.484)"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M110.5,6.927h-6.063a.9.9,0,0,1-.9-.9V1.178a.9.9,0,0,1,.9-.9H110.5a.9.9,0,0,1,.9.9V6.029A.9.9,0,0,1,110.5,6.927Zm-5.826-1.134h5.59V1.414h-5.59V5.793Z"
              transform="translate(-11698.579 -6356.992)"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M110.5,95.573h-6.063a.9.9,0,0,1-.9-.9V86.7a.9.9,0,0,1,.9-.9H110.5a.9.9,0,0,1,.9.9v7.977A.9.9,0,0,1,110.5,95.573Zm-5.826-1.134h5.59v-7.5h-5.59Z"
              transform="translate(-11698.579 -6434.285)"
            />
          </g>
        </svg>
      ),
    },
    {
      path: "/team",
      label: "Team",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.676"
          height="21.001"
          fill={`${1 === currentTab ? "#137ABF" : "#000"}`}
          viewBox="0 0 20.676 21.001"
        >
          <g
            id="Group_239"
            data-name="Group 239"
            transform="translate(9568.05 6356.092)"
          >
            <path
              id="Path_91"
              data-name="Path 91"
              d="M2088.649,84.293a5.076,5.076,0,0,1-5.07-5.07V71.24h10.139v7.984A5.075,5.075,0,0,1,2088.649,84.293Zm-3.734-11.718v6.648a3.734,3.734,0,0,0,7.469,0V72.576Z"
              transform="translate(-11646.361 -6419.385)"
            />
            <path
              id="Path_92"
              data-name="Path 92"
              d="M2183.665,82.231V81.018a2.867,2.867,0,0,0,2.864-2.863V72.993h-2.889V71.78h4.1v6.375a4.082,4.082,0,0,1-4.078,4.078Z"
              transform="translate(-11735.116 -6419.864)"
            />
            <path
              id="Path_93"
              data-name="Path 93"
              d="M2041.028,82.222a4.082,4.082,0,0,1-4.078-4.078V71.77h4.1v1.213h-2.889v5.161a2.866,2.866,0,0,0,2.863,2.863v1.213Z"
              transform="translate(-11605 -6419.855)"
            />
            <path
              id="Path_94"
              data-name="Path 94"
              d="M2173.107,22.424A2.427,2.427,0,1,1,2175.534,20,2.43,2.43,0,0,1,2173.107,22.424Zm0-3.639A1.212,1.212,0,1,0,2174.319,20,1.213,1.213,0,0,0,2173.107,18.785Z"
              transform="translate(-11723.62 -6371.779)"
            />
            <path
              id="Path_95"
              data-name="Path 95"
              d="M2042.927,22.424A2.427,2.427,0,1,1,2045.354,20,2.429,2.429,0,0,1,2042.927,22.424Zm0-3.639A1.212,1.212,0,1,0,2044.139,20,1.213,1.213,0,0,0,2042.927,18.785Z"
              transform="translate(-11608.148 -6371.779)"
            />
            <path
              id="Path_96"
              data-name="Path 96"
              d="M2099.8,8.2a3.65,3.65,0,1,1,3.651-3.651A3.654,3.654,0,0,1,2099.8,8.2Zm0-5.965a2.315,2.315,0,1,0,2.315,2.315,2.317,2.317,0,0,0-2.315-2.315Z"
              transform="translate(-11657.511 -6356.992)"
            />
          </g>
        </svg>
      ),
    },

    {
      path: "/clients",
      label: "Clients",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.357"
          height="21.526"
          fill={`${2 === currentTab ? "#137ABF" : "#000"}`}
          viewBox="0 0 19.357 21.526"
        >
          <g
            id="Group_240"
            data-name="Group 240"
            transform="translate(11013.662 6355.402)"
          >
            <path
              id="Path_65"
              data-name="Path 65"
              d="M627.573,14.037A6.224,6.224,0,1,1,633.8,7.814,6.231,6.231,0,0,1,627.573,14.037Zm0-11.28a5.057,5.057,0,1,0,5.057,5.057A5.062,5.062,0,0,0,627.573,2.757Z"
              transform="translate(-11631.51 -6356.992)"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M601.528,123.772h-8.782a3.633,3.633,0,0,1,1.07-2.626,7.387,7.387,0,0,1,3.153-1.814,14.348,14.348,0,0,1,4.56-.677.7.7,0,0,0,0-1.4c-3.276-.068-7.386.861-9.35,3.7a4.525,4.525,0,0,0-.687,3.7.718.718,0,0,0,.676.513h9.363a.7.7,0,0,0,0-1.4Z"
              transform="translate(-11605 -6459.158)"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M687.551,113.758l-2.531-.368-1.12-2.271a.578.578,0,0,0-1.007,0l-1.12,2.271-2.531.368a.588.588,0,0,0-.258.975l1.789,1.743q-.2,1.2-.411,2.392a.592.592,0,0,0,.858.659l2.178-1.145,2.178,1.145a.591.591,0,0,0,.856-.659q-.2-1.2-.411-2.392l1.789-1.743a.588.588,0,0,0-.258-.975Zm-4.155,3.382c-.439,0-1.009.455-1.377.649l-.324.17c.015-.091.031-.181.047-.272a4.405,4.405,0,0,0,.216-1.572c-.111-.419-.735-.828-1.026-1.111l-.287-.28.345-.05a3.65,3.65,0,0,0,1.523-.3,3.836,3.836,0,0,0,.716-1.311l.166-.336.14.284c.194.392.38,1.086.742,1.364a3.48,3.48,0,0,0,1.466.288l.4.058-.246.24c-.3.292-.952.718-1.066,1.152a4.269,4.269,0,0,0,.206,1.516l.056.329-.273-.144c-.38-.2-.973-.676-1.428-.676Z"
              transform="translate(-11682.277 -6453.48)"
            />
          </g>
        </svg>
      ),
    },
    {
      path: "/jobs",
      label: "Jobs",
      icon: (
        <svg
          fill={`${3 === currentTab ? "#137ABF" : "#000"}`}
          xmlns="http://www.w3.org/2000/svg"
          width="19.069"
          height="15.706"
          viewBox="0 0 19.069 15.706"
        >
          <g
            id="Group_241"
            data-name="Group 241"
            transform="translate(11331.04 6353.332)"
          >
            <path
              id="Path_68"
              data-name="Path 68"
              d="M291.35,5h-6.136a1.773,1.773,0,0,0-3.438,0h-6.136a1.681,1.681,0,0,0-1.679,1.679V17.687a1.681,1.681,0,0,0,1.679,1.679H291.35a1.681,1.681,0,0,0,1.679-1.679V6.679A1.681,1.681,0,0,0,291.35,5Zm-7.856-.469a.9.9,0,1,1-.9.9.906.906,0,0,1,.9-.9Zm8.664,13.156a.809.809,0,0,1-.808.808H275.639a.809.809,0,0,1-.808-.808V6.679a.809.809,0,0,1,.808-.808h6.134c0,.019.011.037.017.056s.011.039.017.058c.011.036.024.07.038.1l.018.048c.021.05.044.1.07.145l0,.005c.024.045.051.089.08.132.01.015.02.028.03.043s.042.058.064.086l.038.046c.022.025.044.051.068.074l.043.044c.024.024.051.047.076.069l.044.037c.031.025.064.049.1.072.01.007.02.016.031.023.044.03.09.057.136.083l.036.017c.036.018.071.037.109.052l.054.022c.032.013.065.025.1.036l.06.018.1.026.061.013c.037.007.074.012.111.017l.054.007c.056.005.111.009.169.009s.113,0,.169-.009l.054-.007c.037,0,.075-.01.111-.017l.061-.013c.034-.008.067-.017.1-.026l.06-.018.1-.036.054-.022c.037-.017.073-.034.109-.052l.036-.017a1.531,1.531,0,0,0,.136-.083l.031-.023c.033-.024.065-.047.1-.072l.044-.037c.026-.023.051-.045.076-.069s.029-.029.043-.044.046-.049.068-.074.026-.03.038-.046.044-.057.064-.086c.01-.014.021-.028.03-.043.029-.043.055-.086.08-.132l0-.005c.025-.047.049-.095.07-.145.007-.016.012-.032.019-.048.014-.034.027-.069.038-.1.006-.019.012-.039.017-.058s.012-.037.017-.056h6.134a.809.809,0,0,1,.808.808V17.687Z"
              transform="translate(-11605 -6356.992)"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M314.333,53.836a2.383,2.383,0,1,1,2.383-2.383A2.385,2.385,0,0,1,314.333,53.836Zm0-3.895a1.512,1.512,0,1,0,1.512,1.512A1.514,1.514,0,0,0,314.333,49.941Z"
              transform="translate(-11639.682 -6398.447)"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M309.49,113.1v-2.317c0-.118-.014.03,0-.024.028-.151.165-.29.419-.389a5.383,5.383,0,0,1,1.939-.141c.368,0,1.013-.079,1.335.158a.342.342,0,0,1,.138.239,4.735,4.735,0,0,1,.007,1.054V113.1a.436.436,0,0,0,.871,0v-1.278a4.546,4.546,0,0,0-.1-1.629c-.329-.833-1.376-.827-2.128-.834-1.226-.011-3.347-.125-3.343,1.59,0,.718,0,1.435,0,2.153a.436.436,0,0,0,.871,0h0Z"
              transform="translate(-11636.642 -6453.484)"
            />
            <path
              id="Path_71"
              data-name="Path 71"
              d="M392.824,58.885h-4.5a1.067,1.067,0,0,1-1.066-1.066v-1.7a1.067,1.067,0,0,1,1.066-1.066h4.5a1.067,1.067,0,0,1,1.066,1.066v1.7A1.067,1.067,0,0,1,392.824,58.885Zm-4.5-2.965a.2.2,0,0,0-.2.2v1.7a.2.2,0,0,0,.2.2h4.5a.2.2,0,0,0,.2-.2v-1.7a.2.2,0,0,0-.2-.2Z"
              transform="translate(-11708.433 -6403.907)"
            />
            <path
              id="Path_72"
              data-name="Path 72"
              d="M385.111,114.221h5.725a.436.436,0,0,0,0-.871h-5.725a.436.436,0,0,0,0,.871Z"
              transform="translate(-11706.087 -6457.129)"
            />
            <path
              id="Path_73"
              data-name="Path 73"
              d="M385.111,138.481h4.26a.436.436,0,0,0,0-.871h-4.26a.436.436,0,0,0,0,.871Z"
              transform="translate(-11706.087 -6479.276)"
            />
          </g>
        </svg>
      ),
    },

    {
      path: "/quote",
      label: "Quote",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.819"
          height="18"
          fill={`${4 === currentTab ? "#137ABF" : "#000"}`}
          viewBox="0 0 17.819 18"
        >
          <g
            id="Group_238"
            data-name="Group 238"
            transform="translate(11605 6356.712)"
          >
            <path
              id="Path_87"
              data-name="Path 87"
              d="M6.96,123.577H.9a.9.9,0,0,1-.9-.9v-4.852a.9.9,0,0,1,.9-.9H6.96a.9.9,0,0,1,.9.9v4.852A.9.9,0,0,1,6.96,123.577Zm-5.826-1.134h5.59v-4.379H1.134v4.379Z"
              transform="translate(-11605 -6462.42)"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M6.96,11.7H.9a.9.9,0,0,1-.9-.9V2.827a.9.9,0,0,1,.9-.9H6.96a.9.9,0,0,1,.9.9V10.8a.9.9,0,0,1-.9.9ZM1.134,10.567h5.59v-7.5H1.134v7.5Z"
              transform="translate(-11605 -6358.484)"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M110.5,6.927h-6.063a.9.9,0,0,1-.9-.9V1.178a.9.9,0,0,1,.9-.9H110.5a.9.9,0,0,1,.9.9V6.029A.9.9,0,0,1,110.5,6.927Zm-5.826-1.134h5.59V1.414h-5.59V5.793Z"
              transform="translate(-11698.579 -6356.992)"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M110.5,95.573h-6.063a.9.9,0,0,1-.9-.9V86.7a.9.9,0,0,1,.9-.9H110.5a.9.9,0,0,1,.9.9v7.977A.9.9,0,0,1,110.5,95.573Zm-5.826-1.134h5.59v-7.5h-5.59Z"
              transform="translate(-11698.579 -6434.285)"
            />
          </g>
        </svg>
      ),
    },
    {
      path: "/services",
      label: "Services",
      icon: (
        <svg
          fill={`${5 === currentTab ? "#137ABF" : "#000"}`}
          xmlns="http://www.w3.org/2000/svg"
          width="19.069"
          height="15.706"
          viewBox="0 0 19.069 15.706"
        >
          <g
            id="Group_241"
            data-name="Group 241"
            transform="translate(11331.04 6353.332)"
          >
            <path
              id="Path_68"
              data-name="Path 68"
              d="M291.35,5h-6.136a1.773,1.773,0,0,0-3.438,0h-6.136a1.681,1.681,0,0,0-1.679,1.679V17.687a1.681,1.681,0,0,0,1.679,1.679H291.35a1.681,1.681,0,0,0,1.679-1.679V6.679A1.681,1.681,0,0,0,291.35,5Zm-7.856-.469a.9.9,0,1,1-.9.9.906.906,0,0,1,.9-.9Zm8.664,13.156a.809.809,0,0,1-.808.808H275.639a.809.809,0,0,1-.808-.808V6.679a.809.809,0,0,1,.808-.808h6.134c0,.019.011.037.017.056s.011.039.017.058c.011.036.024.07.038.1l.018.048c.021.05.044.1.07.145l0,.005c.024.045.051.089.08.132.01.015.02.028.03.043s.042.058.064.086l.038.046c.022.025.044.051.068.074l.043.044c.024.024.051.047.076.069l.044.037c.031.025.064.049.1.072.01.007.02.016.031.023.044.03.09.057.136.083l.036.017c.036.018.071.037.109.052l.054.022c.032.013.065.025.1.036l.06.018.1.026.061.013c.037.007.074.012.111.017l.054.007c.056.005.111.009.169.009s.113,0,.169-.009l.054-.007c.037,0,.075-.01.111-.017l.061-.013c.034-.008.067-.017.1-.026l.06-.018.1-.036.054-.022c.037-.017.073-.034.109-.052l.036-.017a1.531,1.531,0,0,0,.136-.083l.031-.023c.033-.024.065-.047.1-.072l.044-.037c.026-.023.051-.045.076-.069s.029-.029.043-.044.046-.049.068-.074.026-.03.038-.046.044-.057.064-.086c.01-.014.021-.028.03-.043.029-.043.055-.086.08-.132l0-.005c.025-.047.049-.095.07-.145.007-.016.012-.032.019-.048.014-.034.027-.069.038-.1.006-.019.012-.039.017-.058s.012-.037.017-.056h6.134a.809.809,0,0,1,.808.808V17.687Z"
              transform="translate(-11605 -6356.992)"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M314.333,53.836a2.383,2.383,0,1,1,2.383-2.383A2.385,2.385,0,0,1,314.333,53.836Zm0-3.895a1.512,1.512,0,1,0,1.512,1.512A1.514,1.514,0,0,0,314.333,49.941Z"
              transform="translate(-11639.682 -6398.447)"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M309.49,113.1v-2.317c0-.118-.014.03,0-.024.028-.151.165-.29.419-.389a5.383,5.383,0,0,1,1.939-.141c.368,0,1.013-.079,1.335.158a.342.342,0,0,1,.138.239,4.735,4.735,0,0,1,.007,1.054V113.1a.436.436,0,0,0,.871,0v-1.278a4.546,4.546,0,0,0-.1-1.629c-.329-.833-1.376-.827-2.128-.834-1.226-.011-3.347-.125-3.343,1.59,0,.718,0,1.435,0,2.153a.436.436,0,0,0,.871,0h0Z"
              transform="translate(-11636.642 -6453.484)"
            />
            <path
              id="Path_71"
              data-name="Path 71"
              d="M392.824,58.885h-4.5a1.067,1.067,0,0,1-1.066-1.066v-1.7a1.067,1.067,0,0,1,1.066-1.066h4.5a1.067,1.067,0,0,1,1.066,1.066v1.7A1.067,1.067,0,0,1,392.824,58.885Zm-4.5-2.965a.2.2,0,0,0-.2.2v1.7a.2.2,0,0,0,.2.2h4.5a.2.2,0,0,0,.2-.2v-1.7a.2.2,0,0,0-.2-.2Z"
              transform="translate(-11708.433 -6403.907)"
            />
            <path
              id="Path_72"
              data-name="Path 72"
              d="M385.111,114.221h5.725a.436.436,0,0,0,0-.871h-5.725a.436.436,0,0,0,0,.871Z"
              transform="translate(-11706.087 -6457.129)"
            />
            <path
              id="Path_73"
              data-name="Path 73"
              d="M385.111,138.481h4.26a.436.436,0,0,0,0-.871h-4.26a.436.436,0,0,0,0,.871Z"
              transform="translate(-11706.087 -6479.276)"
            />
          </g>
        </svg>
      ),
    },
    {
      path: "/material",
      label: "Material",
      icon: (
        <svg
          fill={`${6 === currentTab ? "#137ABF" : "#000"}`}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <g
            id="Group_242"
            data-name="Group 242"
            transform="translate(10732.15 6355.432)"
          >
            <path
              id="Path_74"
              data-name="Path 74"
              d="M884.636,19.56h-5.573a6.221,6.221,0,0,1-6.214-6.214V7.774a6.221,6.221,0,0,1,6.214-6.214h5.573a6.221,6.221,0,0,1,6.214,6.214v5.573A6.221,6.221,0,0,1,884.636,19.56ZM879.064,2.536a5.244,5.244,0,0,0-5.238,5.238v5.573a5.244,5.244,0,0,0,5.238,5.238h5.573a5.244,5.244,0,0,0,5.238-5.238V7.775a5.244,5.244,0,0,0-5.238-5.238h-5.573Z"
              transform="translate(-11605 -6356.992)"
            />
            <path
              id="Path_75"
              data-name="Path 75"
              d="M927.183,48.119a4.26,4.26,0,0,0-1.862-2.772,3,3,0,0,0-2.673-.048,11.1,11.1,0,0,0-3.67,2.523,4.158,4.158,0,0,0-.631,5.326c.375.494,1.222.008.842-.493a3.023,3.023,0,0,1-.264-3.119,7.525,7.525,0,0,1,2.757-2.618,4.451,4.451,0,0,1,2.4-.943,2.014,2.014,0,0,1,1.542,1,2.632,2.632,0,0,1,.3,2.928,7.417,7.417,0,0,1-2.633,2.541c-.448.26-.919.447-1.345.081a1.421,1.421,0,0,1-.573-1.3c.2-1.043,1.416-1.843,2.215-2.388a.511.511,0,0,0,.09-.761A.411.411,0,0,0,923.1,48c-1.053.72-2.418,1.692-2.678,3.043a2.383,2.383,0,0,0,.873,2.207.5.5,0,0,0,.2.115c1.443.963,3.333-.718,4.3-1.712a4.087,4.087,0,0,0,1.391-3.53Z"
              transform="translate(-11645.42 -6396.203)"
            />
          </g>
        </svg>
      ),
    },
    {
      path: "/time-sheet",
      label: "Time Sheet",
      icon: (
        <svg
          fill={`${7 === currentTab ? "#137ABF" : "#000"}`}
          xmlns="http://www.w3.org/2000/svg"
          width="18.978"
          height="18.07"
          viewBox="0 0 18.978 18.07"
        >
          <g
            id="Group_243"
            data-name="Group 243"
            transform="translate(10447.605 6357)"
          >
            <path
              id="Path_76"
              data-name="Path 76"
              d="M1234.433,16.294l-.8-.209a9.158,9.158,0,0,0,3.919-7.339A9.608,9.608,0,0,0,1234.2,1.79a8.4,8.4,0,0,0-5.446-1.8,10.088,10.088,0,0,0-3.328.553c-.576.214-.327,1.144.256.928a8.558,8.558,0,0,1,7.1.488,8.468,8.468,0,0,1,3.743,5.95,7.284,7.284,0,0,1-1.648,5.619,10.037,10.037,0,0,1-1.891,1.828V13.926a.482.482,0,0,0-.963,0v2.36a.49.49,0,0,0,.353.464l1.8.472a.482.482,0,0,0,.256-.928Z"
              transform="translate(-11666.184 -6356.992)"
            />
            <path
              id="Path_77"
              data-name="Path 77"
              d="M1169.019,24.71a8.125,8.125,0,0,1-6.889-.578,8.73,8.73,0,0,1-3.694-6,7.264,7.264,0,0,1,1.5-5.513,9.321,9.321,0,0,1,1.777-1.786v1.424a.482.482,0,0,0,.963,0V9.9a.49.49,0,0,0-.354-.464l-1.8-.472a.482.482,0,0,0-.256.928l.811.212a8.936,8.936,0,0,0-3.674,7.245,9.9,9.9,0,0,0,3.357,6.97,8.191,8.191,0,0,0,5.314,1.835,9.439,9.439,0,0,0,3.2-.515c.576-.214.326-1.145-.256-.928Z"
              transform="translate(-11605 -6365.083)"
            />
            <path
              id="Path_78"
              data-name="Path 78"
              d="M1211.483,31.392a1.248,1.248,0,0,0-1.246-1.246H1208.7v-.37a1.248,1.248,0,0,0-1.246-1.246h-.821a1.248,1.248,0,0,0-1.246,1.246v.37h-1.17a1.247,1.247,0,0,0-1.246,1.246v1.895h.557v3.17h7.248v-3.17h.707V31.392Zm-5.451-1.616a.6.6,0,0,1,.6-.6h.821a.6.6,0,0,1,.6.6v.348h-2.023Zm4.1,6.035h-5.957V33.359h.9V34h.645v-.642h2.905V34h.645v-.642h.867v2.453Zm.707-3.17h-1.574v-.76h-.645v.76h-2.905v-.76h-.645v.76h-1.453V31.392a.6.6,0,0,1,.6-.6h6.021a.6.6,0,0,1,.6.6Z"
              transform="translate(-11645.019 -6381.694)"
            />
          </g>
        </svg>
      ),
    },
    // {
    //     path: "/payments",
    //     label: "Payments",
    //     icon: (
    //         <svg
    //             fill={`${8 === currentTab ? "#137ABF" : "#000"}`}
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="21.056"
    //             height="19.737"
    //             viewBox="0 0 21.056 19.737"
    //         >
    //             <g
    //                 id="Group_244"
    //                 data-name="Group 244"
    //                 transform="translate(10157.72 6356.432)"
    //             >
    //                 <path
    //                     id="Path_79"
    //                     data-name="Path 79"
    //                     d="M1486.281,67.725h-13.038a2.786,2.786,0,0,1-2.783-2.783V59.585h1.087v5.357a1.7,1.7,0,0,0,1.7,1.7h13.038a1.7,1.7,0,0,0,1.7-1.7v-8.56a1.7,1.7,0,0,0-1.7-1.7h-6.264V53.6h6.264a2.786,2.786,0,0,1,2.783,2.783v8.56A2.786,2.786,0,0,1,1486.281,67.725Z"
    //                     transform="translate(-11625.728 -6404.42)"
    //                 />
    //                 <path
    //                     id="Path_80"
    //                     data-name="Path 80"
    //                     d="M1560.4,31.528h-1.087V29.7a.464.464,0,0,0-.463-.463h-5.7V28.15h5.7A1.551,1.551,0,0,1,1560.4,29.7v1.828Z"
    //                     transform="translate(-11699.669 -6381.664)"
    //                 />
    //                 <path
    //                     id="Path_81"
    //                     data-name="Path 81"
    //                     d="M1452.673,11.347a5.393,5.393,0,1,1,5.393-5.394A5.4,5.4,0,0,1,1452.673,11.347Zm0-9.7a4.307,4.307,0,1,0,4.307,4.307A4.312,4.312,0,0,0,1452.673,1.647Z"
    //                     transform="translate(-11605 -6356.992)"
    //                 />
    //                 <path
    //                     id="Path_82"
    //                     data-name="Path 82"
    //                     d="M1493.037,27.575l-2.354-1.045-.112-3.957h-.011v-.4l1.086-.016.1,3.654,1.728.768Z"
    //                     transform="translate(-11643.7 -6376.307)"
    //                 />
    //                 <path
    //                     id="Path_83"
    //                     data-name="Path 83"
    //                     d="M1609.2,96.01h-2.329a2.062,2.062,0,0,1-2.06-2.06V91.54a2.062,2.062,0,0,1,2.06-2.06h2.329v6.529Zm-2.329-5.443a.975.975,0,0,0-.973.973v2.41a.975.975,0,0,0,.973.973h1.242V90.568Z"
    //                     transform="translate(-11745.863 -6436.504)"
    //                 />
    //             </g>
    //         </svg>
    //     ),
    // },
    {
      path: "/walk-through",
      label: "Walk Through Form",
      icon: <PunchClockOutlined htmlColor={`${8 === currentTab ? "#137ABF" : "#000"}`} />,
  },

    {
      path: "/invoices",
      label: "Invoices",
      icon: (
        <svg
          fill={`${9 === currentTab ? "#137ABF" : "#000"}`}
          xmlns="http://www.w3.org/2000/svg"
          width="19.901"
          height="20.325"
          viewBox="0 0 19.901 20.325"
        >
          <g
            id="Group_245"
            data-name="Group 245"
            transform="translate(9859.152 6356.882)"
          >
            <path
              id="Path_84"
              data-name="Path 84"
              d="M1749.009,20.435a2.748,2.748,0,0,1-2.175-.935c-1.325-1.527-.937-4.386-.921-4.508l.055-.388,3.493-.057L1749.308.11l3.15,3.444L1754.948.6l2.534,2.969L1760.293.61l2.361,2.933,3.1-3.431V17.5a2.959,2.959,0,0,1-2.949,2.9c-.109.009-.231.015-.363.016v.009s-10.088.009-13.426.009Zm-2.223-4.926a5.142,5.142,0,0,0,.742,3.391,1.845,1.845,0,0,0,1.481.618c2.323,0,7.91,0,11.1-.007-1.135-1.19-1.144-3.23-1.072-4.2l-12.248.2Zm13.3-1.135-.085.539c-.007.039-.583,3.937,1.739,4.51a3.015,3.015,0,0,0,.991.064,2.039,2.039,0,0,0,2.1-1.991V2.5l-2.219,2.458L1760.235,2l-2.79,2.933-2.493-2.92-2.466,2.93L1750.251,2.5l.128,12.032,9.7-.158Z"
              transform="translate(-11605 -6356.992)"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M1806.609,74.267h5.924a.459.459,0,0,0,0-.917h-5.924a.459.459,0,0,0,0,.917Z"
              transform="translate(-11658.778 -6422.291)"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M1806.609,93.917h9.119a.459.459,0,0,0,0-.917h-9.119a.459.459,0,0,0,0,.917Z"
              transform="translate(-11658.778 -6439.81)"
            />
          </g>
        </svg>
      ),
    },
    {
      path: "/archives",
      label: "Archive",
      icon: (
       <Inventory2Outlined htmlColor={`${10 === currentTab ? "#137ABF" : "#000"}`} />
      ),
    },
    {
      path: "/live-tracking",
      label: "Live Tracking",
      icon: (
       <ShareLocation htmlColor={`${11 === currentTab ? "#137ABF" : "#000"}`} />
      ),
    },

    {
      path: "/",
      label: "Logout",
      icon: <ExitToAppIcon />,
    },
  ];

  const list = () => (
    <Box
      sx={{ minWidth: "300px",
    
    
      overflowY: "auto", // Enable vertical scrollbar
        scrollbarWidth: "thin", // Set scrollbar width
        scrollbarColor: "#ccc transparent", // Set scrollbar color (change #555 to your desired color)

        // For WebKit browsers (like Chrome and Safari)
        "&::-webkit-scrollbar": {
          width: 4 // Set scrollbar width
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ccc", // Set scrollbar thumb color (change #555 to your desired color)
          borderRadius: 1 // Set border radius for the thumb
        },

        "&::-webkit-scrollbar-track": {
          // backgroundColor: "transparent" // Set scrollbar track color
        }
      
      
      }}
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List sx={{ paddingTop: 0 }}>
        <ListItem
          onClick={() => {
            dispatch(setCurrentTab(""));
            if (window.innerWidth < 900) {
              dispatch(setMenu(false));
            }
            navigate("/admin-profile");
          }}
          disablePadding
        >
          <ListItemButton sx={{ paddingX: 2 }}>
            <ListItemIcon>
              <Avatar>
                <p>
                  {GET_USER?.first_name?.charAt(0)?.toUpperCase() +
                    GET_USER?.last_name?.charAt(0)?.toUpperCase()}
                </p>
              </Avatar>
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{ textTransform: "capitalize" }}
              className="text-black"
              primary={GET_USER?.first_name + " " + GET_USER?.last_name}
              secondary="Admin"
            />
            <ListItemIcon>
              <CloseRounded
                sx={{ ml: 6 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenu(false);
                }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Divider />
        {menuList.map((item, index) => (
          <React.Fragment key={item.label + "-4"}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ paddingX: 2 }}
                onClick={() => {
                  if (item.label == "Logout") {
                    dispatch(setToken(null));
                    SET_TOKEN("");
                    SET_USER({});
                  } else {
                    dispatch(setCurrentTab(index));
                    navigate(item.path);
                    if (window.innerWidth < 900) {
                      dispatch(setMenu(false));
                    }
                  }
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      color: index === currentTab ? "#137ABF" : "",
                      // fontSize: 12,
                    },
                  }}
                  primary={item.label}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {/* <Typography
                component={"div"}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <MaterialUISwitch />
            </Typography> */}
    </Box>
  );

  const handleMenu = (e) => {
    dispatch(setMenu(e));
  };

  return (
    <>
      {window.innerWidth >= 900 ? (
        <Drawer
          variant="persistent"
          anchor={"left"}
          open={menuOpen}
          onClose={() => handleMenu(false)}
          sx={{ minWidth: 300, display: { xs: "none", md: "block" } }}
        >
          {list()}
        </Drawer>
      ) : (
        <Drawer
          anchor={"left"}
          open={menuOpen}
          onClose={() => handleMenu(false)}
          sx={{ minWidth: 300, display: { md: "none" } }}
        >
          {list()}
        </Drawer>
      )}
    </>
  );
};
