import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";

function CustomUnitSelect({ form, name, label = "Unit" }) {
  const [units, setUnits] = React.useState([]);

  const handleChange = (event) => {
    form.setFieldValue(name, event.target.value);
  };

  const errorText = form.touched[name] && form.errors[name];

  const fetchUnits = () => {
    ApiClient.unit
      .get()
      .then((res) => {
        if (res) {
          setUnits(res?.data);
        } else {
          CustomToaster.error("Error While Fetching Units");
        }
      })
      .catch((err) => {
        CustomToaster.error("Error While Fetching Units");
      });
  };

  React.useEffect(() => {
    fetchUnits();
  }, []);

  return (
    <FormControl
      fullWidth
      error={!!errorText}
      helperText={errorText}
      onBlur={() => {
        form.handleBlur(name);
      }}
    >
      <InputLabel id="unitselect">{label}</InputLabel>
      <Select
        labelId="unitselect"
        id="unitselect"
        label={label}
        value={form.values[name]}
        onChange={handleChange}
      >
        {units?.map((item, index) => (
          <MenuItem key={item?.id + index} value={item?.id}>
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomUnitSelect;
