import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
    Box,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
    colors,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from '../../../../constant/ThemeColors';
import { ReadableDate } from '../../../../utils/ReadableDateTime';
import ReadableAddress from '../../../../utils/ReadableAddress';

export function CompleteJobCard({ id, url = "/jobs/detail/", job }) {
    const navigate = useNavigate();

    return (
        <>
            <Box
                onClick={() => {
                    navigate(url + job?.job_id);
                }}
                px={1}
                borderRadius={1}
                boxShadow={"0 0 3px -3px"}
                width={{ lg: "32%", md: "48%", xs: "100%" }}
                sx={{
                    ":hover": {
                        cursor: "pointer",
                        boxShadow: ThemeColors.hoverBoxShadow,
                    },
                    border: 0.5,
                    borderBottom: 4,
                    borderColor: ThemeColors.borderColor,
                }}
            >
                <List dense>
                    <ListItem sx={{ justifyContent: "space-between", gap: 5, alignItems: 'baseline' }}>
                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 18,
                                marginRight: 1,
                                fontWeight: "600",
                                textTransform: 'capitalize'
                            }}
                            primary={
                                job?.name
                            }
                        />

                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 17,
                                fontWeight: "600",
                            }}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                            }}
                            primary={<Chip label={job.job_id} />}
                        />
                    </ListItem>
                    <ListItem dense>


                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 16,
                                fontWeight: "500",
                                // color: "GrayText",
                            }}
                            primary={
                                job?.client_details?.first_name +
                                " " +
                                job?.client_details?.last_name
                            }
                            secondaryTypographyProps={{
                                fontSize: 14,
                                // color: "GrayText",
                            }}
                            secondary={ReadableAddress(job?.client_details)}
                        />
                    </ListItem>
                </List>
                <Divider />
                <Typography

                    display={"flex"}
                    justifyContent={"space-between"}
                    columnGap={1}
                    px={2}
                    py={1}
                >

                    <Typography
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 0.5,
                            py: 0.3
                        }}
                    >
                        <Typography component={"span"}>
                            <AccessTimeIcon sx={{ display: 'flex' }} htmlColor={ThemeColors.iconColor} />
                        </Typography>
                        <Typography
                            component={"span"}
                            noWrap
                            sx={{ fontWeight: "400", }}
                        >
                            <Typography
                                component={"span"}
                                noWrap
                                sx={{
                                    fontSize: 15, fontWeight: "300",
                                    // color: "GrayText"
                                }}
                            >
                                Completed:{" "}
                            </Typography>
                            <Typography
                                component={"span"}
                                noWrap
                            >{ReadableDate(job?.end_time)}</Typography>
                        </Typography>
                    </Typography>


                </Typography>
            </Box>
        </>
    );
}