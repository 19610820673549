import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Input from "@mui/material/Input";
import ApiClient from "../../../constant/API_URLS";
import { GET_TOKEN } from "../../../utils/LocalStorageGetSet";

export const CustomFileInput = ({
  variant = "outlined",
  field,
  form,
  onChange,
  singleImage=false,
  customIcon = false,
  multiple = false,
  ...rest
}) => {
  const errorText = form.touched[field.name] && form.errors[field.name];

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    uploadFiles(Array?.from(event.target.files));
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            sx={{ borderRadius: 100, p: 0.4 }}
            color="secondary"
            variant="determinate"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 20 }}>
          <Typography variant="subtitle2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  function uploadFiles(fileInput) {
    const data = new FormData();
    for (let i = 0; i < fileInput.length; i++) {
      data.append("files[]", fileInput[i]);
    }

    const request = new XMLHttpRequest();
    request.open("POST", ApiClient.uploadFiles);

    // Set the Authorization header with a bearer token
    request.setRequestHeader("Authorization", `Bearer ${GET_TOKEN || ""}`);

    // Upload progress event
    request.upload.addEventListener("progress", function (e) {
      const percentCompleted = (e.loaded / e.total) * 100;
      setUploadProgress(percentCompleted);
    });

    // Request finished event
    request.addEventListener("load", function () {
      if (request.status === 200) {
        if (singleImage) {
          form.setFieldValue(field.name, [...JSON.parse(request.response).data]);
        } else {

          form.setFieldValue(field.name, [...form.values[field.name], ...JSON.parse(request.response).data]);
        }
        setUploadProgress(0);
      }
    });

    request.send(data);
  }



  return (
    <>
      {customIcon ? (
        <>
          <label
            htmlFor="fileInput"
            style={{ ":hover": { cursor: "pointer" } }}
          >
            <Typography
              component={"div"}
              sx={{ height: "100%", ":hover": { cursor: "pointer" } }}
            >
              {customIcon}
              {uploadProgress ? (
                <LinearProgressWithLabel value={uploadProgress} />
              ) : (
                ""
              )}
            </Typography>
          </label>
          <Input
            id="fileInput"
            inputProps={{
              multiple: multiple,
            }}
            onChange={handleFileChange}
            onBlur={form.handleBlur(field.name)}
            sx={{
              display: "none",
            }}
            type="file"
          />
        </>
      ) : (
        <>
          <TextField
            {...rest}
            error={!!errorText}
            helperText={errorText}
            fullWidth
            onChange={handleFileChange}
            onBlur={form.handleBlur(field.name)}
            inputProps={{
              multiple: multiple,
            }}
            InputProps={{
              startAdornment: <CloudUploadIcon sx={{ display: "none" }} />,
            }}
            variant={variant}
            type="file"
          />
          {uploadProgress ? (
            <LinearProgressWithLabel value={uploadProgress} />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};
