import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { LeftMenuDrawer } from "./LeftMenuDrawer";
import { Button, Collapse } from "@mui/material";
import { CloseOutlined, KeyboardArrowLeft } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setLeftIcon, setMenu } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { setSearchText } from "../../redux/searchBarTop";

export function NavBar({
  title = "Dashboard",
  goBack = false,
  isSearch = true,
  isFilter = true,
  onRight = false,
  noFilter = false,
  noSearch = false,
}) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const navBar = useSelector((state) => state.navbar);
  const [search, setSearch] = React.useState(false);

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    borderRadius: 100,
    maxWidth: "100%",
    background: search
      ? alpha(theme.palette.common.white, 0.15)
      : "transparent",
    [theme.breakpoints.up("md")]: {
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      width: "40ch",
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(1),
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
      width: "25ch",
    },
  }));

  const handleMenu = (e) => {
    dispatch(setMenu(e));
  };
  const [searchTextValue, setSearchTextValue] = React.useState("");

  React.useEffect(() => {
    if (searchTextValue) {
      let timer;
      const delay = 500;
      timer = setTimeout(() => {
        dispatch(setSearchText(searchTextValue));
      }, delay);
      return () => {
        clearTimeout(timer);
      };
    } else {
      dispatch(setSearchText(""));
    }
  }, [searchTextValue]);

  return (
    <>
      <AppBar
        sx={{
          position: "fixed",
          height: 64,
          width: { md: navBar.menuOpen ? "calc(100% - 300px)" : "100%" },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {goBack ? (
            <>
              {!search ? (
                <>
                  <IconButton
                    // size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 1, ml: -0.5 }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {!search ? (
                <>
                  {navBar.menuOpen ? (
                    <></>
                  ) : (
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{ mr: 1, ml: -0.5 }}
                      onClick={() => {
                        navBar.menuOpen ? handleMenu(false) : handleMenu(true);
                      }}
                    >
                      <MenuIcon />{" "}
                    </IconButton>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, textTransform: "capitalize" }}
          >
            {title}
          </Typography>

          <Toolbar
            variant="dense"
            sx={{
              padding: "0px !important",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {isSearch && !noSearch ? (
              <Collapse
                sx={{ display: { md: "none" } }}
                timeout={1000}
                in={search}
                orientation="horizontal"
                collapsedSize={40}
              >
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setSearch(true);
                    }}
                    sx={{
                      mr: -5,
                      mt: 0.2,
                      zIndex: 100,
                      background: search ? "transparent" : "",
                    }}
                  >
                    <SearchIcon htmlColor="#fff" />
                  </IconButton>
                  <StyledInputBase
                    value={searchTextValue}
                    autoFocus={search}
                    name="searchInput"
                    onInput={(e) => {
                      setSearchTextValue(e?.target?.value || "");
                    }}
                    placeholder={"Search " + title + "..."}
                  />

                  <IconButton
                    onClick={() => {
                      setSearch(false);
                      setSearchTextValue("");
                    }}
                    sx={{
                      mt: 0.2,
                      ml: 0.4,
                      zIndex: 100,
                    }}
                  >
                    <CloseOutlined htmlColor="#fff" />
                  </IconButton>
                </Typography>
              </Collapse>
            ) : (
              <></>
            )}

            {isSearch && !noSearch ? (
              <Typography
                component={"div"}
                sx={{
                  display: { md: "flex", xs: "none" },
                  alignItems: "center",
                }}
              >
                <IconButton
                  sx={{
                    mr: -5,
                    mt: 0.2,
                    zIndex: 100,
                    background: "transparent",
                  }}
                >
                  <SearchIcon htmlColor="#fff" />
                </IconButton>
                <StyledInputBase
                  value={searchTextValue}
                  autoFocus
                  name="searchInput"
                  onInput={(e) => {
                    setSearchTextValue(e?.target?.value || "");
                  }}
                  placeholder={"Search " + title + "..."}
                />
              </Typography>
            ) : (
              <></>
            )}

            {isFilter && !noFilter && !search ? (
              <IconButton
                sx={{
                  mt: 0.2,
                  ml: 0.5,
                  zIndex: 100,
                }}
              >
                <FilterAltIcon htmlColor="#fff" />
              </IconButton>
            ) : (
              ""
            )}

            {onRight ? <>{onRight}</> : ""}
          </Toolbar>
        </Toolbar>
      </AppBar>

      <LeftMenuDrawer />
    </>
  );
}
