import React, { useEffect, useState } from "react";
import {
  BottomDrawer,
  ConfirmationDialog,
  CustomSwipableTabs,
  FloatingBottomRight,
  NavBar,
  NoResultFound,
} from "../../components";
import Complete from "./JobList/Complete";
import InProgress from "./JobList/InProgress";
import Upcoming from "./JobList/Upcoming";
import { Box, Button, Container, Fab, ListItemButton, Pagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";
import UnScheduled from "./JobList/UnScheduled";
import FilterAlt from "@mui/icons-material/FilterAlt";
import { DateRange, DefinedRange } from "react-date-range";
import dayjs from "dayjs";


export const Jobs = () => {
  const navigate = useNavigate();

  const [filterDate, setFilterDate] = useState('')

  const tabData = [
    // { label: "UN-ASSIGNED", value: <UnAssigned jobs={filterJob(1)} /> },
    {
      label: "Draft",
      value: <UnScheduled filterDate={filterDate} />,
    },
    {
      label: "UPCOMING",
      value: <Upcoming filterDate={filterDate} />,
    },
    {
      label: "IN PROGRESS",
      value: <InProgress filterDate={filterDate} />,
    },
    {
      label: "COMPLETE",
      value: <Complete filterDate={filterDate} />,
    },
  ];

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const [drawerState, setDrawerState] = useState(false);
  const [filterBtn, setFilterBtn] = useState('')

  const handleButtonClick = (buttonText) => {
    const currentDate = new Date();
    let newStartDate = null;
    let newEndDate = null;

    if (buttonText === 'Today') {
      newStartDate = currentDate;
      newEndDate = currentDate;
    } else if (buttonText === 'Yesterday') {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      newStartDate = yesterday;
      newEndDate = yesterday;
    } else if (buttonText === 'Last 7 days') {
      const last7Days = new Date(currentDate);
      last7Days.setDate(currentDate.getDate() - 6); // 6 days ago
      newStartDate = last7Days;
      newEndDate = currentDate;
    } else if (buttonText === 'Last 14 days') {
      const last14Days = new Date(currentDate);
      last14Days.setDate(currentDate.getDate() - 13); // 13 days ago
      newStartDate = last14Days;
      newEndDate = currentDate;
    }
    setFilterBtn(buttonText);
    // Update the state with the new dates
    setState([
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection'
      }
    ]);
  };




  const actions = [
    <>
      <Container sx={{ maxWidth: '460px !important' }}>
        <Box sx={{
          display: "grid", gridTemplateColumns: 'auto auto auto',
          gap: 1
        }}>
          <Button variant={filterBtn === 'Today' ? "contained" : "outlined"} onClick={() => handleButtonClick('Today')}>
            Today
          </Button>
          <Button variant={filterBtn === 'Yesterday' ? "contained" : "outlined"} onClick={() => handleButtonClick('Yesterday')}>
            Yesterday
          </Button>
          <Button variant={filterBtn === 'Last 7 days' ? "contained" : "outlined"} onClick={() => handleButtonClick('Last 7 days')}>
            Last 7 days
          </Button>
          <Button variant={filterBtn === 'Last 14 days' ? "contained" : "outlined"} onClick={() => handleButtonClick('Last 14 days')}>
            Last 14 days
          </Button>
          {/* <Button variant={filterBtn === 'Custom' ? "contained" : "outlined"}  >
            Clear
          </Button> */}

        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4, alignItems: 'center' }}>
          <DateRange
            editableDateInputs={false}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}

          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => {
            setDrawerState(false)
            // fetchJobs()
            setFilterDate('')
          }} >Cancel</Button>
          <Button onClick={() => {
            setDrawerState(false)
            setFilterDate(`?from=${dayjs(state[0]?.startDate).format('YYYY-MM-DD HH:mm:ss')}&to=${state[0]?.endDate ? dayjs(state[0]?.endDate).format('YYYY-MM-DD HH:mm:ss') : dayjs().format('YYYY-MM-DD HH:mm:ss')}`)
          }}>Ok</Button>
        </Box>
      </Container>
    </>,

  ];


  const [currentTab, setCurrentTab] = useState(0)
  const [searchParams] = useSearchParams();
  const tab = searchParams?.get("tab");
  useEffect(() => {
    if (tab) {
      if (tab?.toLowerCase() == 'upcoming') {
        setCurrentTab(1)
      } else if (tab?.toLowerCase() == 'inprogress') {
        setCurrentTab(2)
      } else if (tab?.toLowerCase() == 'completed') {
        setCurrentTab(3)
      }

    }
  }, [tab])

  return (
    <>
      <NavBar
        title="Jobs"
        noFilter
        onRight={
          <BottomDrawer
            icon={<FilterAlt />}
            title="Filter by Date"
            data={actions}
            state={drawerState}
            setState={setDrawerState}
          />
        }
      />

      <CustomSwipableTabs tabsData={tabData} currentTab={currentTab} />
      


      <Fab
        color="primary"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          transition: "ease",
        }}
        onClick={() => {
          navigate("add");
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
