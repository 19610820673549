import { toast } from "react-hot-toast";

function CustomToaster(message) {
  toast(message);
}

CustomToaster.success = (message) => toast.success(message);
CustomToaster.error = (message) => toast.error(message);
CustomToaster.info = (message) => toast(message);
CustomToaster.promise = (promise) => toast.promise(promise, {
  loading: 'Loading...',
  success: (result) => `Success: ${result}`,
  error: (error) => `Error: ${error}`
});

export default CustomToaster;