import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Dashboard, EditProfile } from '../../pages/Dashboard'

export const DashboardRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Dashboard />} />
                <Route path='edit-profile' element={<EditProfile />} />
            </Routes>
        </>
    )
}
