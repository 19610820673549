import React from "react";
import { CustomSwipableTabs, NavBar } from "../../components";
import { Box, Container, Typography } from "@mui/material";
import Profile from "./ProfileTabs/Profile";
import ChangePassword from "./ProfileTabs/ChangePassword";
import Taxes from "./ProfileTabs/Taxes";

export const EditProfile = () => {
  const tabData = [
    { label: "Profile", value: <Profile /> },
    { label: "Change Password", value: <ChangePassword /> },
    { label: "Taxes", value: <Taxes /> },
  ];

  return (
    <>
      <NavBar title="Profile"  noFilter noSearch/>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 0,
        }}
      >
        <Typography variant="h5">Admin Profile</Typography>

        <Box sx={{ my: 1 }}>

          <CustomSwipableTabs
            tabContainerStyle={{
              // background: "none",
              // alignItems: "flex-start",
              // justifyContent: "none",
              // borderBottom: "1.6px solid #F2F2F2",
              // borderRadius: 0,
            }}
            tabsData={tabData}
          />


        </Box>
      </Box>
    </>
  );
};
