import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BadgeIcon from "@mui/icons-material/Badge";
import { Tab, Tabs, Typography } from "@mui/material";
import { DateRange, DefinedRange } from "react-date-range";
import { ThemeColors } from "../../../constant/ThemeColors";

import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import MergedDateAndTime from "../../../utils/MergedDateAndTime";

const slots = {
  leftArrowIcon: ArrowLeft,
  rightArrowIcon: ArrowRight,
};

export function DateTimeDrawer({ id, isOpen, onClose }) {
  const navigate = useNavigate();
  const open = isOpen;

  const [currentComponent, setCurrentComponent] = React.useState("date");

  const handleCurrentComponentChange = (event, nextCurrentComponent) => {
    if (nextCurrentComponent !== null) {
      setCurrentComponent(nextCurrentComponent);
    }
  };

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());

  const RenderDrawer = () => (
    <Box
      role="presentation"
      sx={{
        maxHeight: "80%",
      }}
    >
      <List sx={{ paddingTop: 0 }}>
        <ListItem disablePadding>
          <ListItemButton sx={{ paddingX: 2 }}>
            <ListItemText
              primary={"Select Date & Time"}
              primaryTypographyProps={{ fontSize: "15px" }}
            />
            <ListItemAvatar onClick={handleClose} className="flex justify-end">
              <Avatar>
                <CloseIcon />
              </Avatar>
            </ListItemAvatar>
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          className={`hover:bg-[#fff]`}
          sx={{ mx: 0, mt: 2, display: "flex", flexDirection: "column" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2} sx={{ width: "80%" }} alignItems="center">
              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={currentComponent}
                onChange={handleCurrentComponentChange}
                exclusive
              >
                <ToggleButton
                  sx={{ border: 0, borderBottom: 1, mr: 1 }}
                  value={"date"}
                >
                  {dayjs(date).format("DD/MM/YYYY")}
                </ToggleButton>
                <ToggleButton
                  sx={{ border: 0, borderBottom: 1, ml: 1 }}
                  value={"time"}
                >
                  {dayjs(time).format("hh:mm A")}
                </ToggleButton>
              </ToggleButtonGroup>
              {currentComponent === "date" && (
                <DateCalendar
                  value={dayjs(date)}
                  onChange={(e) => setDate(e.$d)}
                  slots={slots}
                />
              )}

              {currentComponent === "time" && (
                <Box>
                  <TimeClock
                    value={dayjs(time)}
                    onChange={(e) => {
                      setTime(e.$d);
                    }}
                    slots={slots}
                    showViewSwitcher
                    ampmInClock
                  />
                </Box>
              )}
            </Stack>
          </LocalizationProvider>
          <Box
            sx={{
              my: 1,
              width: "85%",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button variant='outlined' onClick={handleDone}>Done</Button>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  const handleClose = () => {
    const mergedTime = MergedDateAndTime(date, time);
    onClose(mergedTime, false);
  };
  const handleDone = () => {
    const mergedTime = MergedDateAndTime(date, time);
    onClose(mergedTime, true);
  };

  return (
    <div>
      <Drawer
        className="rounded-full"
        anchor={"bottom"}
        open={open}
        sx={{
          maxHeight: "20%",
        }}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          },
        }}
      >
        {RenderDrawer()}
      </Drawer>
    </div>
  );
}
