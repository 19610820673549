import React, { useState } from "react";
import {
  ClientSelectRadioCard,
  CustomDatePicker,
  CustomTimePicker,
  NavBar,
  SelectAddressRadioCard,
  TeamSelectRadioCard,
} from "../../../components";
import { Box, Divider, Typography } from "@mui/material";
import { useFormik } from "formik";
import { ClientCard } from "../../../components/Cards";
import CustomButton from "../../../components/ActionButtons/CustomButton";

export function AssignTeam() {
  const [selectValue, setSelectValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [clientId, setClientId] = useState("");

  const formik = useFormik({
    initialValues: {
      date: "",
      time: "",
      team: [],
    },
    onSubmit: (values, { resetForm }) => {
      // updateJob(values, resetForm);
    },
  });

  return (
    <>
      <NavBar title="Assign Team To Fill" noFilter noSearch goBack />
      <ClientSelectRadioCard clientId={clientId} onSubmit={setSelectValue} />
      {selectValue?.id ? (
        <>
          {/* <SelectAddressRadioCard
            data={selectValue}
            onSubmit={setSelectedAddress}
            hideEdit
          /> */}
          {/* <Divider /> */}

          <Box sx={{ my: 2 }}>
            <ClientCard data={selectValue} />
          </Box>

          <Box
            m={1}
            mt={4}
            display={"flex"}
            flexDirection={"column"}
            rowGap={1}
            gap={2}
          >
            <Typography sx={{ mb: -1 }}>Team</Typography>
            <TeamSelectRadioCard
              form={formik}
              name={"team"}
              alreadyTeam={formik.values.team}
            />

            <Typography sx={{ mb: -1 }}>Data & Time</Typography>
            <Box display={"flex"} rowGap={1} gap={1}>
              <CustomDatePicker form={formik} name="date" />
              <CustomTimePicker form={formik} name="time" />
            </Box>

            <Box
              sx={{
                // position: "fixed",
                // zIndex: 100,
                // bottom: 20,
                // left: 0,
                // right: 0,
                // maxWidth: "400px",
                // width: "90%",
                // display: "flex",
                // py: 1,
                // margin: "auto",
                fontSize: 18,
              }}
              gap={2}
            >
              <CustomButton label="Submit" onClick={formik.handleSubmit} />
            </Box>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}
