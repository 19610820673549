import {
  Box,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ThemeColors } from "../../../constant/ThemeColors";

export const MaterialCard = ({
  material,
  onClick = false,
  hideButton = false,
  selectable = false,
  selectedIds = {},
  onMore = false,
  fullWidth
}) => {
  const [selected, setSelected] = useState(false);

  return (
    <Box
      onClick={() => {
        onClick ? (
          <>
            {setSelected(!selected)}
            {selected ? onClick(material, true) : onClick(material)}
          </>
        ) : (
          <></>
          // navigate(`${url}`)
        );
      }}
      mb={2}
      borderRadius={1}
      border={1}
      borderBottom={3}
      borderColor={ThemeColors.borderColor}
      p={1}
      sx={{
        width: fullWidth ? '100%' : { xs: "100%", md: "49%", lg: "32.5%" },
        boxShadow: ThemeColors.boxShadow,
        ":hover": {
          cursor: "pointer",
          boxShadow: ThemeColors.hoverBoxShadow,
        },
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontSize: 18,
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        {material?.name}
        {hideButton ? (
          ""
        ) : (
          <>
            {onMore ? (
              <>{onMore}</>
            ) : (
              <IconButton sx={{ padding: 0.3 }}>
                <MoreHorizIcon />
              </IconButton>
            )}
          </>
        )}
        {selectable ? (
          <>
            {selected || selectedIds[material.id] ? (
              <>
                {selectedIds[material.id] && !selected ? setSelected(true) : ""}
                <CheckCircleIcon htmlColor="#1976D2" />
              </>
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </>
        ) : (
          ""
        )}
      </Typography>
      <Box sx={{ overflow: 'auto' }} component={'div'} >
        {material?.metarial_image?.map((img) => {
          return <img src={img.fileurl} height={80} width={80} />
        })}
      </Box>

      <Typography
        component={'div'}
        variant="body1"
        sx={{
          fontWeight: 400,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxHeight: "3.6em",
        }}
      >

        <Typography
          variant="span"
          sx={{
            color: (theme) => theme.palette.primary,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Description:{"  "}{" "}
        </Typography>
        <Typography
          variant="span"
          sx={{
            fontSize: 16,
            color: "#adacac",
            fontWeight: 400,
          }}
        >
          {material?.description}
        </Typography>
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          mt: 1,
        }}
      >
        Unit Price
        <Typography component={"span"} ml={1.5} fontWeight={"600"}>
          $ {material?.sales_price}
        </Typography>
      </Typography>
    </Box>
  );
};
