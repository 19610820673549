import { Box, Typography } from "@mui/material";
import React from "react";
import { CompleteJobCard } from "../../../../components/Cards/DisplayCards"; 
import { NoResultFound } from "../../../../components";

function Complete({ jobs }) {
  if (!jobs?.length) {
    return <NoResultFound>No Complete Job Found</NoResultFound>;
  }

  return (
    <>

      <Typography>
        Total Complete :{" "}
        <Typography component={"strong"} style={{ fontWeight: "700" }}>
          {jobs.length}
        </Typography>
      </Typography>


      <Box
        py={2}
        overflow={"hidden"}
        my={1}
        display={"flex"}
        flexWrap={"wrap"}
        gap={2}
      >

        {jobs.map((item, index) => (
          <CompleteJobCard key={index} job={item} />
        ))}
      </Box>

    </>
  );
}

export default Complete;
