import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Outlet, Navigate, useLocation, BrowserRouter } from "react-router-dom";
import LayoutContainer from './layout.context';
import NotFound from '../components/ErrorPages/NotFound';
import { Forgot, Login, MailSentScreen, ResetPassword } from '../pages/auth';
import { ClientsRoutes, DashboardRoutes, InvoicesRoutes, JobsRoutes, LiveTrackingRoute, MaterialRoutes, PaymentsRoutes, QuoteRoutes, ServicesRoutes, TeamRoutes, TimeSheetRoutes, WalkThroughRoutes } from './Routes';
import JobDetail from '../pages/publicPages/JobDetail';
import NoteDetail from '../pages/publicPages/NoteDetail';
import QuoteDetail from '../pages/publicPages/QuoteDetail';
import { Dashboard, EditProfile } from '../pages/Dashboard';
import { fetchTaxDetail } from '../redux/constantSlice';
import Archives from '../pages/Archives';


const PrivateRoute = () => {
    const location = useLocation();
    const token = useSelector(state => state.auth?.token);
    const taxList = useSelector(state => state?.constant?.taxList);
    const dispatch = useDispatch();
    if (token && !taxList?.length) {
        dispatch(fetchTaxDetail())
    }
    return token ? (
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};




const RoutesContext = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<MailSentScreen />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/jobdetails" element={<JobDetail />} />
                <Route path="/notedetails" element={<NoteDetail />} />
                <Route path="/quotedetails" element={<QuoteDetail />} />

                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<LayoutContainer />}>
                        <Route index element={<DashboardRoutes />} />
                        <Route path='/team/*' element={<TeamRoutes />} />
                        <Route path='/admin-profile/*' element={<EditProfile />} />
                        <Route path='/dashboard/*' element={<DashboardRoutes />} />
                        <Route path='/clients/*' element={<ClientsRoutes />} />
                        <Route path='/jobs/*' element={<JobsRoutes />} />
                        <Route path='/quote/*' element={<QuoteRoutes />} />
                        <Route path='/services/*' element={<ServicesRoutes />} />
                        <Route path='/material/*' element={<MaterialRoutes />} />
                        <Route path='/time-sheet/*' element={<TimeSheetRoutes />} />
                        <Route path='/payments/*' element={<PaymentsRoutes />} />
                        <Route path='/invoices/*' element={<InvoicesRoutes />} />
                        <Route path='/archives/*' element={<Archives />} />
                        <Route path='/live-tracking/*' element={<LiveTrackingRoute />} />
                        <Route path='/walk-through/*' element={<WalkThroughRoutes />} />
                    </Route>
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesContext