import * as Yup from 'yup'



export const stringValidationSchema = Yup.string().typeError('Must be string').required('Field is required')
export const emailValidationSchema = Yup.string().required('Email is required').matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format');
// export const emailValidationSchema = Yup.string().email('Invalid email address').required('Email is required');
export const phoneValidationSchema = Yup.string().matches(/^\d{10}$/, 'Phone number must be exactly 10 digits').required('Phone number is required')
export const passwordValidationSchema = Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required')
export const confirmPasswordValidationSchema = Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Please confirm your password');


export const urlValidationSchema = Yup.string().url('Invalid URL').required('URL is required');
export const dateValidationSchema = Yup.date().typeError('Invalid date').required('Date is required');
export const timeValidationSchema = Yup.date().typeError('Invalid Time').required('Time is required');
export const arrayValidationSchema = Yup.array().required('This field is required').min(1, 'This field is required');
export const checkboxValidationSchema = Yup.boolean().oneOf([true], 'Must be checked');
export const nonNegativeNumericValidationSchema = Yup.number().typeError('Must be a non-negative number').min(0, 'Must be a non-negative number').required('This field is required');

export const maxTextLengthValidationSchema = (maxLength) => Yup.string().max(maxLength, `Must be at most ${maxLength} characters`);
export const numericValidationSchema = Yup.number().typeError('Must be a number').required('This field is required');

export const positiveNumericValidationSchema = Yup.number().typeError('Must be a positive number').positive('Must be a positive number').required('This field is required');


export const postalCodeValidationSchema = Yup.string()
.typeError('Must be a string')
.required('Field is required')
.matches(/^\d{5}(-\d{4})?$/, 'Invalid US ZIP code');

