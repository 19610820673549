import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { TimeSheet, TimeSheetDetail } from '../../pages/TimeSheet'
import CollapsibleTable from '../../pages/TimeSheet/TimesheetTables/testTable'

export const TimeSheetRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<TimeSheet />} />
                <Route path='detail' element={<CollapsibleTable />} />
            </Routes>
        </>
    )
}
