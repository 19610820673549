import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Profile, Team, TeamAdd, TeamVisits } from '../../pages/Team'

export const TeamRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Team />} />
                <Route path='add' element={<TeamAdd />} />
                <Route path='profile/:userId' element={<Profile />} />
                <Route path='profile/:userId/visits' element={<TeamVisits />} />
            </Routes>
        </>
    )
}
