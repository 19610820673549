
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Close } from '@mui/icons-material';

export const LogisticsFleetDrawer = (props) => {
  const { children, container, open, onClose } = props;

  return (
    <Drawer
      anchor="left"
      hideBackdrop
      ModalProps={{
        // container,
        // sx: {
        //   pointerEvents: 'none',
        //   position: 'absolute',
        // },
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          pointerEvents: 'auto',
          position: 'absolute',
        },
      }}
      // SlideProps={{ container }}
      variant="temporary"
    >
      <Stack
        alignItems="flex-center"
        direction="row"
        justifyContent="space-between"
        sx={{ p: 2 }}
      >
        <Typography variant="h5">Team Member</Typography>
        <IconButton onClick={onClose}>
          <SvgIcon>
            <Close />
          </SvgIcon>
        </IconButton>
      </Stack>
      {children}
    </Drawer>
  );
};


