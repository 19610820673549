import React, { useEffect, useState } from "react";
import {
  BottomDrawer,
  CustomSwipableTabs,
  NavBar,
  NoResultFound,
} from "../../../components";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  Typography,
  colors,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ClientCard } from "../../../components/Cards";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import Scheduled from "./Scheduled";
import {
  CloudDownload,
  Delete,
  Edit,
  Forward10TwoTone,
  ForwardSharp,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails/DeleteConfirmationDialog";
import { ReadableDateTime } from "../../../utils/ReadableDateTime";
import { ThemeColors } from "../../../constant/ThemeColors";
import { quoteStatus } from "../../../constant/GlobalVariable";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import CustomToaster from "../../../utils/CustomToaster";

function QuoteDetial() {
  const { quote_id } = useParams();
  const navigate = useNavigate();

  const [quoteDetail, setQuoteDetail] = useState("");

  const fetchDetail = () => {
    ApiClient.quote
      .get("?quote_id=" + quote_id)
      .then((res) => {
        if (res) {
          setQuoteDetail(res?.data[0] || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const handleDelete = (_, handleCloseSuccess) => {
    ApiClient.quote.delete(quote_id).then(res => {
      if (res) {
        handleCloseSuccess()
        CustomToaster.success(res?.message)
        navigate(-1)
      }
    }).catch(err => {
      console.log(err);
    })
  };

  const actions = [
    <>
      {quoteDetail?.is_approved !== quoteStatus?.ACCEPTED ? (
        <ListItemButton
          onClick={() => {
            navigate("/quote/update/" + quote_id);
          }}
          sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Edit />
          <Typography component={"span"}>Edit</Typography>
        </ListItemButton>
      ) : (
        ""
      )}
    </>,
    <>
      <DeleteConfirmationDialog onSubmit={handleDelete}>
        <ListItemButton
          sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Delete />
          <Typography component={"span"}>Delete</Typography>
        </ListItemButton>
      </DeleteConfirmationDialog>
    </>,
  ];

  return (
    <>
      <NavBar
        title={
          quoteDetail?.quote_id ? "#" + quoteDetail?.quote_id : "Loading...."
        }
        noFilter
        noSearch
        goBack
        onRight={<BottomDrawer title="Action" data={actions} />}
      />
      {!quoteDetail ? (
        <LoadingEffect />
      ) : (
        <>
          {!quoteDetail?.quote_id ? (
            <>
              <NoResultFound />
            </>
          ) : (
            <Box p={1}>
              {quoteDetail?.is_approved === quoteStatus.ACCEPTED ? (
                <Box py={1} px={0.5}>
                  <Button
                    onClick={() => {
                      navigate("/jobs/add?quote=" + quoteDetail?.quote_id);
                    }}
                    variant="contained"
                    color="warning"
                  >
                    Convert To Job
                  </Button>
                </Box>
              ) : (
                ""
              )}

              {quoteDetail?.is_approved === quoteStatus.DECLINED ? (
                <Box py={1} px={0.5}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: colors.red[600],
                    }}
                  >
                    Declined Reason:
                  </Typography>
                  <Box
                    py={1.5}
                    px={0.5}
                    sx={{
                      paddingX: 2,
                      mb: 2,
                      border: 0.5,
                      borderRadius: 1,
                      borderColor: colors.red[200],
                    }}
                  >
                    {quoteDetail?.decline_message}
                  </Box>
                </Box>
              ) : (
                ""
              )}
              <Box px={0.5}>
                <Typography variant="h6" mb={1}>
                  Client Detail:
                </Typography>
                {/* <Divider mb={1} /> */}
                <ClientCard data={quoteDetail?.client_details} />
              </Box>
              <Box py={1.5} px={0.5}>
                <Typography variant="h6">
                  {new Date(quoteDetail?.schedule_date) > new Date()
                    ? `Scheduled Date:`
                    : "Sent Date: "}
                </Typography>
                {ReadableDateTime(quoteDetail?.schedule_date)}
              </Box>
              <Box py={1.5} px={0.5}>
                <Typography variant="h6">Email:</Typography>
                {quoteDetail?.schedule_email}
              </Box>
              
              <Scheduled quoteDetail={quoteDetail} />

              <Box py={1.5} px={0.5}>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">Term & Conditions:</Typography>
                  {quoteDetail?.term_condi_file[0]?.fileurl && (
                    <>
                      <Button
                        download={quoteDetail?.term_condi_file[0]?.filename}
                        href={quoteDetail?.term_condi_file[0]?.fileurl}
                        component={"a"}
                        style={{ textTransform: "capitalize" }}
                        variant='text'
                        startIcon={<CloudDownload />}
                      >
                        Download
                      </Button>
                    </>
                  )}
                </Box>
                <Box
                  py={1.5}
                  px={0.5}
                  sx={{
                    paddingX: 2,
                    border: 0.5,
                    borderRadius: 1,
                    borderColor: ThemeColors.borderColor,
                  }}
                >
                  {quoteDetail?.term_condi_text}
                </Box>
              </Box>
              {quoteDetail?.notes ? <Box py={1} px={0.5}>
                <Typography variant="h6">Note:</Typography>
                <Box
                  py={1.5}
                  px={0.5}
                  sx={{
                    paddingX: 2,
                    border: 0.5,
                    borderRadius: 1,
                    borderColor: ThemeColors.borderColor,
                  }}
                >
                  {quoteDetail?.notes}
                </Box>
              </Box> : ''}

             
            </Box>
          )}
        </>
      )}
    </>
  );
}

export { QuoteDetial };
