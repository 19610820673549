import { CircularProgress, Container } from '@mui/material'
import React from 'react'

export const NoResultFound = ({ children }) => {
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', height: '500px', textTransform: 'capitalize' }}>

            {'No Result Found'}
            {/* {children || 'No Result To Show'} */}
        </Container>
    )
}
