import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { usePlacesAutocomplete } from '../../../utils/GoogleMap';
import { parseAddress } from '../../../utils/ParseGoogleAddress';

export default function GoogleAutoComplete({ field, form, label, data, disabled, googleAddress, ...otherProps }) {
    const errorText = form?.touched[field.name] && form?.errors[field.name];
    const [suggestions, fetchSuggestions, selectedPlace, fetchPlaceDetails] = usePlacesAutocomplete()
    const [selectedPlaceId, setSelectedPlace] = React.useState(null);


    React.useEffect(() => {
        let debounceTimer;
        const handleDebouncedFetch = () => {
            fetchSuggestions(field.value);
        };
        if (field.value || field.value === null) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(handleDebouncedFetch, 500);
        }
        return () => clearTimeout(debounceTimer);
    }, [field.value]);

    React.useEffect(() => {
        if (selectedPlaceId !== selectedPlace?.place_id) {
            fetchPlaceDetails(selectedPlaceId)
        }
    }, [selectedPlaceId])


    React.useEffect(() => {
        if (googleAddress && selectedPlace) {
            googleAddress({
                formatted: parseAddress(selectedPlace?.address_components),
                lat: selectedPlace?.geometry.location.lat,
                lang: selectedPlace?.geometry.location.lng,
                // place_id: selectedPlace?.place_id,
                ...selectedPlace
            })
        }
    }, [selectedPlace])

    return (

        <>
            <Autocomplete
                disablePortal
                freeSolo
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                fullWidth
                disabled={disabled}
                sx={{ mt: 2, mb: 1 }}
                options={suggestions}
                value={field.value}
                onChange={(event, newValue) => {
                    setSelectedPlace(newValue?.place_id)
                    form.setFieldValue(field.name, newValue?.structured_formatting?.main_text || null)
                }}
                onBlur={() => form.setFieldTouched(field.name)}
                renderInput={(params) => (
                    <TextField
                        error={!!errorText}
                        helperText={errorText}
                        label={label}
                        {...params}
                        {...field}
                        inputProps={{
                            ...params.inputProps,
                        }}
                        placeholder={label}
                    />
                )}
                renderOption={(props, option) => {
                    const matches =
                        option.structured_formatting.main_text_matched_substrings || [];

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}

            />


            {/* <Autocomplete
                id="google-map-demo"
                sx={{ width: 300 }}
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText="No locations"
                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Add a location" fullWidth />
                )}
                renderOption={(props, option) => {
                    const matches =
                        option.structured_formatting.main_text_matched_substrings || [];

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            /> */}



        </>

    );
}
