// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import './App.css';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import ThemeContext from './context/theme.context';
import RoutesContext from './context/routes.context';
import ReduxContext from './context/redux.context';
import { Toaster } from 'react-hot-toast';
import CustomToaster from './utils/CustomToaster';



function App() {

  return (
    <ReduxContext>
      <ThemeContext>
        <Toaster />
        <RoutesContext />
      </ThemeContext>
    </ReduxContext>
  );
}

export default App;
