import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  colors,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from "../../../../constant/ThemeColors";
import { ReadableDate } from "../../../../utils/ReadableDateTime";
import ReadableAddress from "../../../../utils/ReadableAddress";

export function UpComingJobCard({ id, url = "/jobs/detail/", job }) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        onClick={() => {
          navigate(url + job?.job_id);
        }}
        px={1}
        borderRadius={1}
        boxShadow={"0 0 3px -3px"}
        width={{ lg: "32%", md: "48%", xs: "100%" }}
        sx={{
          position: "relative",
          ":hover": {
            cursor: "pointer",
            boxShadow: ThemeColors.hoverBoxShadow,
          },
          border: 0.5,
          borderBottom: 4,
          borderColor: job?.schedule_date
            ? new Date(job?.schedule_date) < new Date()
              ? colors.red[300]
              : ThemeColors.borderColor
            : ThemeColors.borderColor,
          // borderColor: ThemeColors.borderColor,
        }}
      >
        <List dense>
        <ListItem sx={{ justifyContent: "space-between", gap: 5, alignItems: 'baseline' }}>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 18,
                marginRight:1,
                fontWeight: "600",
                textTransform: 'capitalize'
              }}
              primary={
                job?.name
              }
            />

            <ListItemText
              primaryTypographyProps={{
                fontSize: 17,
                fontWeight: "600",
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
              primary={<Chip label={job.job_id} />}
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "500",
                // color: "GrayText",
              }}
              primary={
                job?.client_details?.first_name +
                " " +
                job?.client_details?.last_name
              }
              secondaryTypographyProps={{
                fontSize: 14,
                // color: "GrayText",
              }}
              secondary={ReadableAddress(job?.client_details)}
            />
          </ListItem>
        </List>
        <Divider />
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          columnGap={1}
          px={2}
          py={1}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              py: 0.3,
            }}
          >
            <Typography component={"span"}>
              <AccessTimeIcon
                sx={{ display: "flex" }}
                htmlColor={ThemeColors.iconColor}
              />
            </Typography>
            <Typography
              component={"span"}
              noWrap
              sx={{
                fontWeight: "400",
                // color: "GrayText"
              }}
            >
              {ReadableDate(job?.schedule_date)}
            </Typography>
          </Typography>
        </Typography>
        {job?.schedule_date ? (
          new Date(job?.schedule_date) < new Date() ? (
            <Typography
              sx={{
                position: "absolute",
                top: -12,
                right: 12,
                background: '#fff',
                color: colors.red[300],
                px: 1
              }}
            >
              Late
            </Typography>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
