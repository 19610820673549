import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SelectInput from "@mui/material/Select/SelectInput";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { NoResultFound } from "../../NoResult/NoResult";
import { LoadingEffect } from "../../SimmerEffects/LoadingEffect";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../ActionButtons/CustomButton";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function TeamSelectRadioCard({
  label = " Teams",
  alreadyTeam = "",
  onSubmit = false,
  form = false,
  name='team',
  disabled = false,
}) {
  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const [selectedTeam, setSelectedTeam] = React.useState({});

  const handleChange = (team) => {
    let isExists = selectedTeam[team.id];
    if (isExists === undefined) {
      setSelectedTeam((prevState) => ({ ...prevState, [team.id]: team }));
    } else {
      const updatedSelectedTeam = { ...selectedTeam };
      delete updatedSelectedTeam[team.id];
      setSelectedTeam(updatedSelectedTeam);
    }
  };

  const handleSubmit = () => {
    const resultArray = Object.values(selectedTeam);
    if (form) {
      form.setFieldValue(name, resultArray);
    } else if (onSubmit) {
      onSubmit(resultArray);
    }
    handleCloseMd();
  };

  const [data, setData] = React.useState("");
  const [options, setOptions] = React.useState("");

  const getTeamList = () => {
    ApiClient.team
      .get()
      .then((res) => {
        if (res) {
          setData(res?.data);
          const result = res?.data.map((item) => ({
            id: item.id,
            label: `${item.first_name} ${item.last_name}`,
          }));
          setOptions(result);
        }
      })
      .catch((error) => {
        CustomToaster.error(error?.message);
      });
  };

  React.useEffect(() => {
    getTeamList();

    if (alreadyTeam?.length) {
      alreadyTeam.map((team) => {
        handleChange(team);
      });
    }
  }, []);

  const NameCardList = ({ data, onClick }) => {
    const alphabetOrder = data
      .map((item) => item.first_name.charAt(0).toUpperCase())
      .sort()
      .reduce(function (acc, curr) {
        if (!acc.includes(curr)) acc.push(curr);
        return acc;
      }, []);

    return (
      <List disablePadding>
        {alphabetOrder.map((char, index) => (
          <React.Fragment key={char + index}>
            <Typography
              variant="h6"
              sx={{ px: 1, py: 1, fontWeight: 500, fontSize: "16px" }}
            >
              {char}
            </Typography>
            <Typography component="div">
              {data.map((item) =>
                item?.first_name.charAt(0).toUpperCase() === char ? (
                  <NameCard
                    key={item?.id}
                    user={item}
                    onClick={() => onClick(item)}
                  />
                ) : null
              )}
            </Typography>
          </React.Fragment>
        ))}
        <Typography sx={{ mb: 36, mt: 8, pt: 4 }}></Typography>
      </List>
    );
  };

  const NameCard = ({ user, onClick }) => {
    return (
      <>
        <Typography
          sx={{
            borderRadius: 1,
            ":hover": {
              boxShadow: "0 3px 12px -8px #a0a",
              cursor: "pointer",
            },
          }}
        >
          <ListItem
            components={"div"}
            onClick={onClick}
            secondaryAction={
              <IconButton edge="end" aria-label="detail-page">
                {selectedTeam[user.id] ? (
                  <>
                    <CheckCircleIcon htmlColor="#1976D2" />
                  </>
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                {user?.first_name.charAt(0).toUpperCase() +
                  (user?.last_name
                    ? user?.last_name?.charAt(0).toUpperCase()
                    : "")}
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              primary={
                user?.first_name.charAt(0).toUpperCase() +
                user?.first_name.slice(1) +
                " " +
                (user?.last_name
                  ? user?.last_name?.charAt(0).toUpperCase() +
                    user?.last_name?.slice(1)
                  : "")
              }
            />
          </ListItem>

          <Divider
            sx={{
              borderRadius: 4,
            }}
          />
        </Typography>
      </>
    );
  };

  const generateArray = (data) => {
    return data.map((item) => ({
      id: item.id,
      label: `${item.first_name} ${item.last_name}`,
    }));
  };

  const errorText = form && form?.touched[name] && form?.errors[name];

  return (
    <>
      <Typography
        onClick={() => {
          if (!disabled) {
            handleClickOpenMd();
          }
        }}
        sx={{
          padding: 0,
          mt: -1,
          ":hover": {
            cursor: "pointer",
          },
          fontSize: 16,
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <>
          <Autocomplete
            freeSolo
            multiple
            disablePortal
            limitTags={2}
            id="Team list"
            options={options}
            getOptionLabel={(option) => option.label}
            fullWidth
            value={generateArray(Object.values(selectedTeam))}
            sx={{ mt: 1, mx: 0, p: 0 }}
            readOnly
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errorText}
                helperText={errorText}
                placeholder={
                  alreadyTeam?.length
                    ? ""
                    : generateArray(Object.values(selectedTeam))?.length
                    ? ""
                    : "Select Team"
                }
              />
            )}
          />
        </>
      </Typography>

      <Dialog
        fullScreen
        open={openMd}
        onClose={handleCloseMd}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 0.5, flex: 1, display: "flex" }}
              component="div"
            >
              <Typography
                sx={{ alignSelf: "flex-end" }}
                component={"span"}
                variant="h6"
              >
                {label}
              </Typography>
            </Typography>

            <IconButton
              sx={{ mr: -2 }}
              edge="end"
              color="inherit"
              onClick={handleCloseMd}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 0 }}>
          {!data ? (
            <LoadingEffect />
          ) : (
            <>
              {!data?.length ? (
                <NoResultFound>No Team Found</NoResultFound>
              ) : (
                <NameCardList data={data} onClick={handleChange} />
              )}
            </>
          )}
        </Box>
        <CustomButton onClick={handleSubmit} label="Continue" />
      </Dialog>
    </>
  );
}
