import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SelectInput from "@mui/material/Select/SelectInput";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { NoResultFound } from "../../NoResult/NoResult";
import { LoadingEffect } from "../../SimmerEffects/LoadingEffect";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function SingleJobSelect({
  label = " Job",
  onSubmit = false,
  jobId = false

}) {
  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const [value, setValue] = React.useState("");

  const handleChange = (client) => {
    setValue(`${client?.name} (#${client?.job_id})`);
    onSubmit(client);
    handleCloseMd();
  };

  const [data, setData] = React.useState("");

  const getClientList = () => {
    ApiClient.allJobs
      .get()
      .then((res) => {
        if (res) {
          setData(res?.data);
        }
      })
      .catch((error) => {
        CustomToaster.error(error?.message);
      });
  };

  React.useEffect(() => {
    getClientList();
  }, []);

  React.useEffect(() => {
    if (jobId && data?.length && !value) {
      let client = data.filter((item) => item.job_id == jobId)[0];
      handleChange(client)
    }
  }, [jobId, data]);


  
  return (
    <>
      <Typography
        onClick={() => {
          handleClickOpenMd();
        }}
        sx={{
          padding: 0,
          mt: -1,
          ":hover": {
            cursor: "pointer",
          },
          fontSize: 16,
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <>
          {!data ? <LoadingEffect /> : <Autocomplete
            disablePortal
            id="client list"
            options={[]}
            value={value}
            fullWidth
            sx={{ mt: 2, mx: 1, p: 0 }}
            readOnly
            renderInput={(params) => (
              <TextField  {...params} label="" />
            )}
          />}

        </>
      </Typography>

      <Dialog
        fullScreen
        open={openMd}
        onClose={handleCloseMd}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 0.5, flex: 1, display: "flex" }}
              component="div"
            >
              <Typography
                sx={{ alignSelf: "flex-end" }}
                component={"span"}
                variant="h6"
              >
                {label}
              </Typography>
            </Typography>

            <IconButton
              sx={{ mr: -2 }}
              edge="end"
              color="inherit"
              onClick={handleCloseMd}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          {!data ? (
            <LoadingEffect />
          ) : (
            <>
              {!data?.length ? (
                <NoResultFound>No Client Found</NoResultFound>
              ) : (
                <NameCardList data={data} onClick={handleChange} />
              )}
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
}

const NameCardList = ({ data, onClick }) => {
  const navigate = useNavigate();

  const alphabetOrder = data
    .map((item) => item.name.charAt(0).toUpperCase())
    .sort()
    .reduce(function (acc, curr) {
      if (!acc.includes(curr)) acc.push(curr);
      return acc;
    }, []);

  return (
    <List disablePadding>
      {alphabetOrder.map((char, index) => (
        <React.Fragment key={char + index}>
          <Typography
            variant="h6"
            sx={{ px: 1, py: 1, fontWeight: 500, fontSize: "16px" }}
          >
            {char}
          </Typography>
          <Typography component="div">
            {data.map((item) =>
              item?.name.charAt(0).toUpperCase() === char ? (
                <NameCard
                  key={item?.id}
                  user={item}
                  onClick={() => onClick(item)}
                />
              ) : null
            )}
          </Typography>
        </React.Fragment>
      ))}
      <Typography sx={{ mb: 36, mt: 8, pt: 4 }}></Typography>
    </List>
  );
};

const NameCard = ({ user, onClick }) => {
  return (
    <>
      <Typography
        sx={{
          borderRadius: 1,
          ":hover": {
            boxShadow: "0 3px 12px -8px #a0a",
            cursor: "pointer",
          },
        }}
      >
        <ListItem
          components={"div"}
          onClick={onClick}
          secondaryAction={
            <IconButton edge="end" aria-label="detail-page">
              <KeyboardArrowRightIcon className="!fill-[#B1BAC4]" />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              {user?.name.charAt(0).toUpperCase()}
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            primary={
              `${user.name} (#${user.job_id})`
            }
            primaryTypographyProps={{textTransform:'capitalize'}}
          />
        </ListItem>

        <Divider
          sx={{
            borderRadius: 4,
          }}
        />
      </Typography>
    </>
  );
};


