import { Box, Typography } from '@mui/material'
import React from 'react'
import { ThemeColors } from '../../../../constant/ThemeColors'
import DisplayUserAddress from '../../../../utils/DisplayUserAddress'

export function ClientCard({ data }) {
    return (
        <>
            <Box sx={{
                border: 1,
                borderBottom: 3,
                borderColor: ThemeColors.borderColor,
                p: 1.4,
                borderRadius: 1,
                display: 'flex',
                flexDirection: "column",
                gap: 0.5
            }}>
                <Typography variant='h6' >{data?.first_name+' '+data?.last_name}</Typography>
                <Typography variant='p' sx={{ fontSize: 18, color: 'GrayText' }} >{DisplayUserAddress(data)}</Typography>
                <Typography sx={{ fontWeight: '500' }}>{data?.phone}</Typography>
                <Typography sx={{ fontWeight: '500' }}>{data?.email}</Typography>

            </Box>
        </>
    )
}
