import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  emailValidationSchema,
  postalCodeValidationSchema,
  stringValidationSchema,
} from "../../constant/validationSchema";
import { CustomTextField } from "../InputFields/TextFields";
import CustomButton from "../ActionButtons/CustomButton";
import CustomAutoComplete from "../InputFields/SelectFields/CustomAutoComplete";
import GoogleAutoComplete from "../InputFields/SelectFields/GoogleAutoComplete";
import { CANADA_CITIES, CANADA_STATES } from "../../constant/CanadaStateCities";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function AddAddress({ id, onUpdate, customTitle='' }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => {
    setOpenMd(true);
  };
  const handleCloseMd = () => {
    setOpenMd(false);
  };

  const addNewAddress = (data, resetForm) => {
    ApiClient.address.post(data, id).then((res) => {
      if (res) {
        resetForm();
        handleClose();
        handleCloseMd();
        CustomToaster.success(res.message);
        onUpdate();
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      street: "",
      state: "",
      city: "",
      state: "",
      pin_code: "",
      lat: null,
      lang: null,
      place_id: null,
    },
    validationSchema: Yup.object().shape({
      state: stringValidationSchema,
      city: stringValidationSchema,
      street: stringValidationSchema,
      pin_code: postalCodeValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      addNewAddress(values, resetForm);
    },
  });

  const selectedState = formik.values.state;
  React.useEffect(() => {
    formik.values.city = "";
  }, [selectedState]);

  return (
    <>
      <Typography
        onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen();
          } else {
            handleClickOpenMd();
          }
        }}
        sx={{
          padding: 0,
          mt: -1,
          ":hover": {
            cursor: "pointer",
          },
          color: (theme) => theme.palette.grey[600],
          fontSize: 14,
          fontWeight: "500",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {customTitle ? (
          <>{customTitle}</>
        ) : (
          <>
            {" "}
            <AddCircleIcon htmlColor="#5E95FF" sx={{ p: 0.3 }} />
            Add Another Address
          </>
        )}
      </Typography>

      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <DialogTitle> Add Another Address </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
              width: "100%",
              pb: 2,
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              paddingX={2}
              columnSpacing={{ md: 1, lg: 2 }}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <GoogleAutoComplete
                  form={formik}
                  field={formik.getFieldProps("street")}
                  margin="normal"
                  label="Street"
                  googleAddress={(formattedAddress) => {
                    const { formatted, lat, lang, place_id } = formattedAddress;
                    formik.setFieldValue("state", formatted.state || "");
                    formik.setFieldValue("city", formatted.city || "");
                    formik.setFieldValue(
                      "pin_code",
                      formatted.postalCode || ""
                    );
                    formik.setFieldValue("lat", lat || "");
                    formik.setFieldValue("lang", lang || "");
                    formik.setFieldValue("place_id", place_id || "");
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomAutoComplete
                  form={formik}
                  field={formik.getFieldProps("state")}
                  data={CANADA_STATES}
                  label="State"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomAutoComplete
                  form={formik}
                  field={formik.getFieldProps("city")}
                  data={selectedState ? CANADA_CITIES(selectedState) : []}
                  disabled={!selectedState}
                  label="City"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  form={formik}
                  field={formik.getFieldProps("pin_code")}
                  margin="normal"
                  label="Postal Code"
                  placeholder="M2P 1R7"
                  inputStyle={{ textTransform: "uppercase" }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMd}>Cancel</Button>
          <Button onClick={formik.handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              Add Another Address
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            width: "100%",
            overflow: "auto",
            pb: 16,
          }}
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            paddingX={2}
            columnSpacing={{ md: 1, lg: 2 }}
            justifyContent="center"
          >
            <Grid item xs={12} md={6}>
              <GoogleAutoComplete
                form={formik}
                field={formik.getFieldProps("street")}
                margin="normal"
                label="Street"
                googleAddress={(formattedAddress) => {
                  const { formatted, lat, lang, place_id } = formattedAddress;
                  formik.setFieldValue("state", formatted.state || "");
                  formik.setFieldValue("city", formatted.city || "");
                  formik.setFieldValue("pin_code", formatted.postalCode || "");
                  formik.setFieldValue("lat", lat || "");
                  formik.setFieldValue("lang", lang || "");
                  formik.setFieldValue("place_id", place_id || "");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAutoComplete
                form={formik}
                field={formik.getFieldProps("state")}
                data={CANADA_STATES}
                label="State"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAutoComplete
                form={formik}
                field={formik.getFieldProps("city")}
                data={selectedState ? CANADA_CITIES(selectedState) : []}
                disabled={
                  !selectedState || !CANADA_CITIES(selectedState).length
                }
                label="City"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                form={formik}
                field={formik.getFieldProps("pin_code")}
                margin="normal"
                label="Postal Code"
                placeholder="M2P 1R7"
                inputStyle={{ textTransform: "uppercase" }}
              />
            </Grid>

            <Grid item xs={12} width={"100%"}>
              <CustomButton label={"Save"} />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}
