import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ServiceDetail, Services, ServicesList, SubCategory } from '../../pages/Services'

export const ServicesRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Services />} />
                <Route path=':catId' element={<SubCategory />} />
                <Route path=':catId/:subCatId' element={<ServicesList />} />
                <Route path=':catId/:subCatId/:serviceId' element={<ServiceDetail />} />
            </Routes>
        </>
    )
}
