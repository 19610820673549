function setLocalStorageItem(key, value) {
    localStorage.setItem(key, value);
}

function getLocalStorageItem(key) {
    return localStorage.getItem(key);
}

export const SET_TOKEN = (token) => { setLocalStorageItem('token', token) }
export const GET_TOKEN = getLocalStorageItem('token') || null




export const SET_USER = (user) => { setLocalStorageItem('user', JSON.stringify(user)) }
let user = getLocalStorageItem('user')
export const GET_USER = user ? JSON.parse(user) : null
