import React, { useEffect } from 'react'
import { NavBar } from '../../../../components'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import ApiClient from '../../../../constant/API_URLS';

export function ServiceDetail() {
    const { catId, subCatId, serviceId } = useParams();

    const fetchDetail = () => {
        ApiClient.service.get(serviceId).then(res => {
            if (res) {
                console.log(res);
            }
        })
    }

    useEffect(() => {
        fetchDetail()
    }, [])

    return (
        <>
            <NavBar  noFilter noSearch goBack title={'Service Detail'} isSearch={false} isFilter={false} />

            <Box p={1}>


                index
            </Box>


        </>
    )
}
