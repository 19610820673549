export const ThemeColors = {
    primary:'#3D75E1',
    secondary:'#E1EDFF',
    accent:'',
    primaryText:'#000',
    secondaryText:'#3D75E1',
    borderColor: '#E1EDFF',
    iconColor: '#3D75E1',
    hoverBoxShadow: '#E1EDFF 0px 1px 2px 0px, #E1EDFF 0px 2px 6px 2px;',
    boxShadow: '#E1EDFF 0 0 5px -3px',
    grayBackGround:'#a8a8a83b'

}