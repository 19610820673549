import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
function CustomCheckBox({ label = "", form, name, ...rest }) {
  const errorText = form.touched[name] && form.errors[name];
  const { value } = form?.getFieldProps(name);

  return (
    <FormControlLabel
      control={
        <Checkbox
          error={!!errorText}
          helperText={errorText}
          color="primary"
          checked={value}
          {...form?.getFieldProps(name)}
          {...rest}
        />
      }
      label={label}
    />
  );
}

export default React.memo(CustomCheckBox);
