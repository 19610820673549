import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NameCardList } from '../../components/Cards'
import ApiClient from '../../constant/API_URLS'
import CustomToaster from '../../utils/CustomToaster'
import { NavBar, NoResultFound, FloatingActionButton } from '../../components'
import { LoadingEffect } from '../../components/SimmerEffects/LoadingEffect'
import { Box, Pagination } from '@mui/material'


export const Team = () => {
  const [rawData, setData] = useState('');
  const data = rawData?.data

  const [filteredData, setFilteredData] = useState('');
  const searchText = useSelector(state => state?.searchBarText);
  useEffect(() => {
    if (searchText && data) {
      const searchTextLowerCase = searchText.toLowerCase();
      const searchWords = searchTextLowerCase.split(' ');

      const filteredData = data.filter((item) => {
        const firstNameLowerCase = item?.first_name.toLowerCase();
        const lastNameLowerCase = item?.last_name.toLowerCase();
        const addressStrings = item.additionaladdress.map((address) =>
          `${address.street}, ${address.city}, ${address.state}, ${address.pin_code}`.toLowerCase()
        );

        const addressMatch = addressStrings.some((address) => {
          return searchWords.some((word) => address.includes(word));
        });

        return (
          searchWords.some((word) => firstNameLowerCase?.includes(word)) ||
          searchWords.some((word) => lastNameLowerCase?.includes(word)) ||
          addressMatch
        );
      });


      setFilteredData(filteredData);
    } else {
      setFilteredData('')
    }
  }, [searchText])



  const getTeamList = (filter = '?page=1') => {
    ApiClient.team.get(filter).then(res => {
      setData(res)
    }).catch(error => {
      CustomToaster.error(error?.message)
    })
  }



  const dispatch = useDispatch()
  useEffect(() => {
    getTeamList()
  }, []);




  return (
    <>
      <NavBar title='Team' isFilter={false} />

      {!data ? <LoadingEffect /> : <>

        {!data?.length ? <NoResultFound /> :
          <>

            <NameCardList data={filteredData || data} url='/team/profile/' />
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', mt: 4 }}>
              <Pagination onChange={(e, value) => {
                getTeamList(`?page=${value}`);
              }} size='large' page={rawData?.current_page} count={rawData?.last_page} color="primary" />
            </Box>
          </>
        }
      </>}

      <FloatingActionButton />

    </>
  )
}
