export default function MergedDateAndTime(date, time) {
  let d = new Date(date);
  let t = new Date(time);
  // if (!(date instanceof Date)) {
  //   throw new Error("The 'date' argument should be a Date object.");
  // }

  // if (!(time instanceof Date) || time.getUTCHours() !== time.getHours()) {
  //   throw new Error("The 'time' argument should be a Date object with only time information.");
  // }

  const mergedDateTime = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    t.getHours(),
    t.getMinutes(),
    t.getSeconds(),
    t.getMilliseconds()
  );

  return mergedDateTime;
}
