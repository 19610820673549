import React from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { NameCard } from "./NameCard";


export const NameCardList = ({ data, url = '/quote/client-quote', noClick = false, secondaryAction = false }) => {
    const navigate = useNavigate();


    const alphabetOrder = data
        .map((item) => item.first_name.charAt(0).toUpperCase())
        .sort()
        .reduce(function (acc, curr) {
            if (!acc.includes(curr)) acc.push(curr);
            return acc;
        }, []);

    return (
        <List disablePadding>
            {alphabetOrder.map((char, index) => (
                <React.Fragment key={char + index}>
                    <Typography variant="h6" sx={{ px: 1, py: 1, fontWeight: 500, fontSize: '16px' }}>
                        {char}
                    </Typography>
                    <Typography component="div" >
                        {data.map((item) =>
                            item?.first_name.charAt(0).toUpperCase() === char ? (
                                <NameCard
                                    key={item?.id}
                                    user={item}
                                    onClick={() => {
                                        if (noClick) {
                                        } else {
                                            navigate(url + item?.id)
                                        }
                                    }}

                                    secondaryAction={secondaryAction}
                                />
                            ) : null
                        )}
                    </Typography>
                </React.Fragment>
            ))}
            <Typography sx={{ mb: 0, mt: 0, pt: 4, }} ></Typography>
        </List>
    );
};

