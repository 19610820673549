import { Autocomplete, TextField } from "@mui/material";
import React from "react";

function CustomAutoComplete({
  field,
  form,
  label,
  data,
  disabled,
  name,
  ...otherProps
}) {
  const errorText = form?.touched[field?.name] && form?.errors[field?.name];

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      sx={{
        mt: 2,
        mb: 1,
      }}
      disablePortal
      isOptionEqualToValue={(option) => option.value}
      options={data || []}
      value={field?.value} // Set the value from Formik's field value
      onChange={(event, newValue) => {
        form.setFieldValue(field.name, newValue?.value);
      }}
      onBlur={() => form.setFieldTouched(field.name)}
      renderInput={(params) => (
        <TextField
          error={!!errorText}
          helperText={errorText}
          label={label}
          {...params}
          {...field}
          inputProps={{
            ...params.inputProps,
          }}
          placeholder={label}
        />
      )}
    />
  );
}

export default CustomAutoComplete;
