import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

export function CustomPopover({ children, element }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const renderChildrenWithProps = () => {
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    onClose: handleClose,
                });
            }
            return child;
        });
    };


    return (
        <div>
            <Box onClick={handleClick}>
                {element || (
                    <IconButton>
                        <MoreHoriz />
                    </IconButton>
                )}
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    // horizontal: 'left',
                }}
            >
                <Box sx={{ p: 1 }}>{renderChildrenWithProps()}</Box>
            </Popover>
        </div>
    );
}
