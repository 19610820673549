import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Box,
  Button,
  Pagination,
  Typography,
  colors,
} from "@mui/material";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { useSelector } from "react-redux";
import { FilterAlt, KeyboardArrowRight } from "@mui/icons-material";
import { ThemeColors } from "../../../constant/ThemeColors";
import React, { useEffect, useState } from "react";
import {
  CustomDatePicker,
  CustomDialogWrapper,
  NoResultFound,
  TeamSelectRadioCard,
} from "../../../components";
import dayjs from "dayjs";
import {
  ReadableDate,
  ReadableDateTime,
} from "../../../utils/ReadableDateTime";
import MapDisplay from "../../../components/Cards/DisplayCards/MapDisplay";
import { useFormik } from "formik";

const tdWidth = {
  width: 100,
  minWidth: 100,
  maxWidth: 200,
};

export default function GroupByJobTable() {
  const [timeSheet, setTimeSheet] = useState("");
  const [pagination, setPagination] = useState("");

  const [filterValues, setFilterValues] = useState("&from=&to=");

  const fetchTimeSheet = (filter = "?page=1") => {
    ApiClient.timeSheetDateWise
      ?.get(filter + filterValues)
      ?.then((res) => {
        if (res) {
          setTimeSheet(res?.data);
          setPagination(res?.pagination);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTimeSheet();
  }, [filterValues]);

  const [initialValues, setInitialValues] = useState({
    from: "",
    to: "",
  });

  const RenderFilter = ({ onClose }) => {
    const formik = useFormik({
      initialValues,
      onSubmit: (values, { resetForm }) => {
        onClose();
        setInitialValues({ ...values });
        setFilterValues(
          `&from=${
            values.from ? dayjs(values.from)?.format("YYYY-MM-DD") : ""
          }&to=${values.to ? dayjs(values.to)?.format("YYYY-MM-DD") : ""}`
        );
      },
    });

    return (
      <Box sx={{ p: 2 }}>
        <Typography sx={{ mb: 0 }}>Date Range</Typography>
        <Box display={"flex"} rowGap={1} gap={1}>
          <CustomDatePicker form={formik} name="from" />
          <CustomDatePicker form={formik} name="to" />
        </Box>

        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Button
            onClick={formik?.handleSubmit}
            variant="contained"
            size="large"
            sx={{ margin: "auto", my: 4, width: "60%" }}
          >
            Search
          </Button>
        </Box>
      </Box>
    );
  };

  if (!timeSheet) {
    return <LoadingEffect />;
  }
  if (timeSheet && !timeSheet?.length) {
    return <NoResultFound>No Record Found</NoResultFound>;
  }
  return (
    <>
      <CustomDialogWrapper
        title="Filter"
        element={
          <IconButton
            sx={{
              position: "absolute",
              top: 24,
              right: 16,
              background: colors.grey[400],
              ":hover": {
                background: colors.grey[500],
              },
            }}
          >
            <FilterAlt htmlColor="white" />
          </IconButton>
        }
      >
        <RenderFilter />
      </CustomDialogWrapper>

      {!timeSheet ? <LoadingEffect /> : ""}

      {timeSheet && !timeSheet?.length ? (
        <NoResultFound>No Record Found</NoResultFound>
      ) : (
        <>
          <Table stickyHeader sx={{ p: 2 }} aria-label="sticky table">
            <TableHead sx={{ margin: 2, padding: 2 }}>
              <TableRow>
                <TableCell
                  // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                  align="left"
                >
                  Job
                </TableCell>
                <TableCell
                  // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                  align="left"
                >
                  Team
                </TableCell>
                <TableCell
                  // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                  align="right"
                >
                  Start Time
                </TableCell>
                <TableCell
                  // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                  align="right"
                >
                  End Time
                </TableCell>
                <TableCell
                  // sx={{ minWidth: tdWidth?.minWidth, width: tdWidth }}
                  align="right"
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timeSheet?.map((item) => (
                <Row item={item} />
                // <TableRow>
                //   <TableCell
                //     sx={{ borderBottom: "unset", padding: 0 }}
                //     colSpan={5}
                //   >
                //     <TableContainer
                //       sx={{
                //         minWidth: 600,
                //         padding: 0,
                //         mt: 2,
                //         borderRadius: 2,
                //         border: 0.5,
                //         borderBottom: 2,
                //         // borderColor: '#E1EDFF',
                //         borderColor: colors.blue[300],
                //         boxShadow: ThemeColors.boxShadow,
                //       }}
                //     >
                //       <Table aria-label="collapsible table">
                //         <TableBody>
                //           <Row item={item} />
                //         </TableBody>
                //       </Table>
                //     </TableContainer>
                //   </TableCell>
                // </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              mt: 4,
            }}
          >
            <Pagination
              onChange={(e, value) => {
                fetchTimeSheet(`?page=${value}`);
              }}
              size="large"
              page={pagination?.current_page}
              count={pagination?.last_page}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
}
function Row(props) {
  const { item } = props;
  const [open, setOpen] = React.useState(true);

  function calculateDiffrence(teamMember) {
    const startTime = new Date(teamMember.sheet_start_date);
    const endTime = new Date(teamMember.sheet_end_date);
    return Math.round((endTime - startTime) / 1000);
  }
  function calculateTotalTime(data) {
    let totalTime = 0;
    data.forEach((job) => {
      job.work_info.forEach((teamMember) => {
        totalTime += calculateDiffrence(teamMember);
      });
    });

    return totalTime;
  }
  function formatTime(totalSeconds) {
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const timeParts = [];
    if (days > 0) {
      timeParts.push(`${days}`);
    }
    if (hours > 0) {
      timeParts.push(`${hours}`);
    }
    if (minutes > 0) {
      timeParts.push(`${minutes}`);
    }
    if (seconds > 0 || timeParts.length === 0) {
      timeParts.push(`${seconds}`);
    }

    return timeParts.join(":");
  }

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ background: "#E1EDFF" }}>
        <TableCell colSpan={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
            <Typography sx={{ textTransform: "capitalize" }}>
              {ReadableDate(item?.job_date)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          sx={{
            minWidth: tdWidth?.minWidth,
            width: tdWidth,
            whiteSpace: "nowrap",
          }}
          align="right"
        >
          {formatTime(calculateTotalTime(item?.sheet))}
        </TableCell>
      </TableRow>

      {item?.sheet?.map((item2) => {
        let length = item2?.work_info?.length;
        return (
          <>
            {item2?.work_info?.map((item, index) => (
              <>
                {open ? (
                  <TableRow
                    hover={index}
                    sx={{
                      "&>*": {
                        borderBottom: "unset",
                      },
                    }}
                  >
                    {!index && (
                      <TableCell
                        sx={{ whiteSpace: "nowrap" }}
                        align="left"
                        rowSpan={length}
                      >
                        {item2?.job_name} ({item2?.job_id})
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        borderBottom: "unset",
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                      }}
                      align="left"
                    >
                      <MapDisplay item={item} /> {item?.user_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "unset",
                        whiteSpace: "nowrap",
                      }}
                      align="right"
                    >
                      {dayjs(item?.sheet_start_date).format("hh:mm:ss a")}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "unset",
                        whiteSpace: "nowrap",
                      }}
                      align="right"
                    >
                      {dayjs(item?.sheet_end_date).format("hh:mm:ss a")}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "unset",
                      }}
                      align="right"
                    >
                      {formatTime(calculateDiffrence(item))}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </>
            ))}
          </>
        );
      })}

      {/* <TableRow>
        <TableCell
          align="right"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {item?.sheet?.map((item) => (
                  <TableRow>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.job_name} ({item?.job_id})
                    </TableCell>
                    <TableCell align="right" colSpan={4}>
                      {item?.work_info?.map((item) => (
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <TableRow sx={{ "&>*": { borderBottom: "unset" } }}>
                              <TableCell
                                sx={{
                                  // minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  // maxWidth:tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                  whiteSpace: "nowrap",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                align="left"
                              >
                                <MapDisplay item={item} /> {item?.user_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  maxWidth: tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                  whiteSpace: "nowrap",
                                }}
                                align="right"
                              >
                                {dayjs(item?.sheet_start_date).format(
                                  "hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  maxWidth: tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                  whiteSpace: "nowrap",
                                }}
                                align="right"
                              >
                                {dayjs(item?.sheet_end_date).format(
                                  "hh:mm:ss a"
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: tdWidth?.minWidth,
                                  width: tdWidth?.width,
                                  maxWidth: tdWidth?.maxWidth,
                                  borderBottom: "unset",
                                }}
                                align="right"
                              >
                                {formatTime(calculateDiffrence(item))}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}
