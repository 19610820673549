import React, { useEffect, useState } from "react";
import {
  BottomDrawer,
  CustomSwipableTabs,
  NavBar,
  NoResultFound,
} from "../../../components";
import { Box, ListItemButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { JobInfo } from "./JobInfo";
import { JobNotes } from "./JobNotes";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { Delete, Edit, GroupAdd } from "@mui/icons-material";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails/DeleteConfirmationDialog";
import CustomToaster from "../../../utils/CustomToaster";
import TodoTab from "./TodoTabs/TodoTab";
import Inprogress from "./TodoTabs/Inprogress";
import Completed from "./TodoTabs/Completed";
import { todoStatus } from "../../../constant/GlobalVariable";

export function Todo({ jobDetail }) {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const handleDelete = (_, handleCloseSuccess) => {
    ApiClient.job
      .delete(jobId)
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          handleCloseSuccess();
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [todoList, setTodoList] = useState("");

  const fetchTodo = () => {
    ApiClient.todo
      .get("?job_id=" + jobId)
      .then((res) => {
        if (res) {
          setTodoList(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterTodo = (id) => {
    if (todoList) {
      return todoList?.filter((item) => {
        if (item?.todo_status == id) {
          return item;
        }
      });
    } else {
      return [];
    }
  };

  const actions = [
    <>
      <ListItemButton
        onClick={() => {
          navigate("/jobs/update/" + jobId + "/full");
        }}
        sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
      >
        <Edit />
        <Typography component={"span"}>Edit</Typography>
      </ListItemButton>
    </>,
    <>
      <DeleteConfirmationDialog onSubmit={handleDelete}>
        <ListItemButton
          sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Delete />
          <Typography component={"span"}>Delete</Typography>
        </ListItemButton>
      </DeleteConfirmationDialog>
    </>,
  ];

  useEffect(() => {
    fetchTodo();
  }, []);

  return (
    <>
      <Box
        flexGrow
        component={"div"}
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >

        <TodoTab
          todoList={todoList}
          fetchTodo={fetchTodo}
          jobDetail={jobDetail}
        />

      </Box>
    </>
  );
}
