import { Button } from "@mui/material";
import React from "react";

function CustomButton({ label = "Submit", sx, ...rest }) {
  return (
    <>
      <Button
        fullWidth
        sx={{
          position: "fixed",
          zIndex: 100,
          bottom: 20,
          left: 0,
          right: 0,
          maxWidth: "400px",
          width: "90%",
          display: "flex",
          py: 1,
          margin: "auto",
          fontSize: 18,
          ...sx,
        }}
        type="submit"
        variant="contained"
        {...rest}
      >
        {label}
      </Button>

    </>
  );
}

export default CustomButton;
