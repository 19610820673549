import { Box, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SentQuoteCard } from "../../../components/Cards/DisplayCards";
import { NoResultFound } from "../../../components";
import ApiClient from "../../../constant/API_URLS";
import { useSelector } from "react-redux";
import { quoteStatus } from "../../../constant/GlobalVariable";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";

export function Sent() {
  const [quoteList, setQuoteList] = useState("");
  const quotes = quoteList?.data || [];

  const getQuoteList = (filter = "") => {
    ApiClient.quote
      .get(filter)
      .then((res) => {
        if (res) {
          setQuoteList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQuoteList(`?status=${quoteStatus.SENT}`);
  }, []);

  const [filteredData, setFilteredData] = useState("");
  const searchText = useSelector((state) => state?.searchBarText);

  useEffect(() => {
    if (searchText && quotes) {
      const searchTextLowerCase = searchText.toLowerCase();
      const filteredData = quotes.filter((item) => {
        const nameLowerCase = item.name.toLowerCase();
        const firstNameLowerCase =
          item?.client_details?.first_name.toLowerCase();
        const lastNameLowerCase = item?.client_details?.last_name.toLowerCase();
        return (
          searchTextLowerCase.includes(firstNameLowerCase) ||
          searchTextLowerCase.includes(lastNameLowerCase) ||
          firstNameLowerCase.includes(searchTextLowerCase) ||
          lastNameLowerCase.includes(searchTextLowerCase) ||
          nameLowerCase.includes(searchTextLowerCase) ||
          searchTextLowerCase.includes(nameLowerCase)
        );
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData("");
    }
  }, [searchText]);

  if (!quoteList) {
    return <LoadingEffect />;
  }

  if (!quotes?.length) {
    return <NoResultFound />;
  }

  return (
    <>
      <Box
        py={2}
        overflow={"hidden"}
        my={1}
        display={"flex"}
        flexWrap={"wrap"}
        gap={1.2}
        rowGap={2}
      >
        {(filteredData || quotes).map((item) => (
          <SentQuoteCard url="detail/" key={item.quote_id} quote={item} />
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          mt: 4,
        }}
      >
        <Pagination
          onChange={(e, value) => {
            getQuoteList(`?status=${quoteStatus.SCHEDULED}&page=${value}`);
          }}
          size="large"
          page={quoteList?.current_page}
          count={quoteList?.last_page}
          color="primary"
        />
      </Box>
    </>
  );
}
