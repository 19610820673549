import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLeftIcon, setPageTitle } from "../../../redux/navbarSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { NavBar } from "../../../components";
import { useFormik } from "formik";
import {
  confirmPasswordValidationSchema,
  emailValidationSchema,
  passwordValidationSchema,
  phoneValidationSchema,
  postalCodeValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomAutoComplete from "../../../components/InputFields/SelectFields/CustomAutoComplete";
import {
  CANADA_CITIES,
  CANADA_STATES,
} from "../../../constant/CanadaStateCities";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { MapModal, usePlacesAutocomplete } from "../../../utils/GoogleMap";
import GoogleAutoComplete from "../../../components/InputFields/SelectFields/GoogleAutoComplete";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate } from "react-router-dom";
import CustomCheckBox from "../../../components/InputFields/CustomCheckBox/CustomCheckBox";

export default function ClientAdd() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      password: passwordValidationSchema,
      confirm_password: confirmPasswordValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      ChangePassword(values, resetForm);
    },
  });

  const ChangePassword = (data, resetForm) => {
    ApiClient.changePassword
      .put('',{
        old_password: data?.old_password,
        new_password: data?.password,
        re_new_password: data?.confirm_password,
      })
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          resetForm();
          // navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          mt: 1,
          pb: 10,
          height: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <CustomTextField
          form={formik}
          field={formik.getFieldProps("old_password")}
          margin="normal"
          label="Old Password"
        />
        <CustomTextField
          form={formik}
          field={formik.getFieldProps("password")}
          margin="normal"
          label="New Password"
        />
        <CustomTextField
          form={formik}
          field={formik.getFieldProps("confirm_password")}
          margin="normal"
          label="Confirm Password"
        />

        <Button
          type="submit"
          variant="contained"
          sx={{
            maxWidth: "400px",
            width: "90%",
            display: "flex",
            py: 1,
            margin: "auto",
            fontSize: 18,
            mt: 4,
          }}
        >
          Save
        </Button>
      </Box>
    </>
  );
}
