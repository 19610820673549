export default function replaceAndRemoveTrailingSlash(url) {
    const cleanedUrl = url.replace(/\/+/g, '/'); // Replace consecutive slashes with a single slash
    const withoutTrailingSlash = cleanedUrl.replace(/\/+$/, ''); // Remove trailing slashes

    const hasQuery = withoutTrailingSlash.includes('?');
    if (hasQuery) {
        const parts = withoutTrailingSlash.split('?');
        return replaceAndRemoveTrailingSlash(parts[0]) + '?' + parts[1];
    }

    return withoutTrailingSlash;
}