import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={5000} direction="up" ref={ref} {...props} />;
});

export function CustomDialogWrapper({ title = "Action", element, children, onClose, fullWidth = false }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openMd, setOpenMd] = React.useState(false);
  const handleClickOpenMd = () => setOpenMd(true);
  const handleCloseMd = () => setOpenMd(false);

  const closeDialogBox = () => {
    handleCloseMd();
    handleClose();
    if (onClose) {
      onClose()
    }
  };

  const renderChildrenWithProps = () => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onClose: closeDialogBox,
        });
      }
      return child;
    });
  };

  return (
    <>
      <Box
        sx={{
          width: fullWidth ? fullWidth : 'max-content'
        }}
        onClick={() => {
          if (window.innerWidth < 900) {
            handleClickOpen();
          } else {
            handleClickOpenMd();
          }
        }}
      >
        {element}
      </Box>

      <Dialog fullWidth open={openMd} onClose={handleCloseMd}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{title} </DialogTitle>
          <IconButton
            sx={{ pr: 2 }}
            edge="start"
            color="inherit"
            onClick={handleCloseMd}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />
        <DialogContent>{renderChildrenWithProps()}</DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 0.5, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box py={1}>{renderChildrenWithProps()}</Box>
      </Dialog>
    </>
  );
}
