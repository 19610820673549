import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Material } from '../../pages/Material'
import { MaterialList } from '../../pages/Services'

export const MaterialRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Material />} />
                <Route path=':industryId/*' element={<MaterialList />} />
            </Routes>
        </>
    )
}
