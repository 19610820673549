import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { LogisticsFleetVehicle } from "./logistics-fleet-vehicle";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { NoResultFound } from "../../../components/NoResult/NoResult";

export const LogisticsFleetList = (props) => {
  const {
    onVehicleDeselect,
    onVehicleSelect,
    currentVehicleId,
    vehicles = false,
  } = props;

  return (
    <Stack
      component="ul"
      divider={<Divider />}
      sx={{
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: vehicles?.length ? 1 : 0,
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      {vehicles?.length ? (
        vehicles.map((vehicle) => {
          const selected = currentVehicleId
            ? currentVehicleId === vehicle?.member_info?.id
            : false;

          return (
            <LogisticsFleetVehicle
              key={vehicle.id}
              onDeselect={onVehicleDeselect}
              onSelect={onVehicleSelect}
              selected={selected}
              vehicle={vehicle}
            />
          );
        })
      ) : (
        <>{!vehicles ? <LoadingEffect /> : <NoResultFound />}</>
      )}
    </Stack>
  );
};
