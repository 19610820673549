import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import ApiClient from "../../constant/API_URLS";

import {
  BottomDrawer,
  CustomSwipableTabs,
  NavBar,
  NoResultFound,
} from "../../components";
import { Box, Button, Container, Divider, ListItemButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ClientCard } from "../../components/Cards";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import { Delete, Edit } from "@mui/icons-material";
import { DeleteConfirmationDialog } from "../../components/DeleteDetails/DeleteConfirmationDialog";
import { ReadableDateTime } from "../../utils/ReadableDateTime";
import { ThemeColors } from "../../constant/ThemeColors";
import Scheduled from "../Quote/QuoteDetail/Scheduled";


function QuoteDetail() {
  const [searchParams] = useSearchParams();
  const quoteId = searchParams.get("id");
  const [quoteDetail, setQuoteDetail] = useState("");

  const fetchQuote = () => {
    ApiClient.publicQuoteDetail
      .get("?id=" + quoteId)
      .then((res) => {
        if (res) {
          setQuoteDetail(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchQuote();
  }, [quoteId]);


  console.log(quoteDetail);
  return (<>
    {quoteDetail ? (
      <Container>
        <Box
          sx={{
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <img src={"/assets/static/img/logo.png"} width={420} height={160} />
          <Typography variant="h6">
            Quote Detail {quoteDetail?.quote_id && '#'+quoteDetail?.quote_id}
          </Typography>
          <Divider />
        </Box>

        <Box
          flexGrow
          component={"div"}
          sx={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            mt: 1,
            pb: 4,
          }}
        >
          {!quoteDetail?.quote_id ? (
            <>
              <NoResultFound />
            </>
          ) : (
            <Box p={1}>
              <Box px={0.5}>
                <Typography variant='h6' mb={1} >Client Detail:</Typography>
                {/* <Divider mb={1} /> */}
                <ClientCard data={quoteDetail?.client_details} />
              </Box>
              <Box py={1.5} px={0.5}>
                <Typography variant='h6' >
                  {new Date(quoteDetail?.schedule_date) > new Date() ? `Scheduled Date:` : 'Sent Date: '}

                </Typography>
                {ReadableDateTime(quoteDetail?.schedule_date)}
              </Box>
              <Box py={1.5} px={0.5}>
                <Typography variant='h6' >
                  Email:
                </Typography>
                {quoteDetail?.schedule_email}
              </Box>
              <Box py={1.5} px={0.5} >
                <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
                  <Typography variant='h6' >
                    Term & Conditions:
                  </Typography>
                  {quoteDetail?.term_condi_file[0]?.fileurl && <Typography download={quoteDetail?.term_condi_file[0]?.filename}
                    href={quoteDetail?.term_condi_file[0]?.fileurl}
                    component={'a'} style={{ textTransform: 'capitalize' }}>
                    Term's File
                  </Typography>}

                </Box>
                <Box py={1.5} px={0.5} sx={{ paddingX: 2, border: 0.5, borderRadius: 1, borderColor: ThemeColors.borderColor }}>

                  {quoteDetail?.term_condi_text}
                </Box>
              </Box>
              <Box py={1} px={0.5} >
                <Typography variant='h6' >
                  Note:
                </Typography>
                <Box py={1.5} px={0.5} sx={{ paddingX: 2, border: 0.5, borderRadius: 1, borderColor: ThemeColors.borderColor }}>

                  {quoteDetail?.notes}
                </Box>
              </Box>


              <Scheduled quoteDetail={quoteDetail} />
            </Box>
          )}

        </Box>
      </Container>
    ) : (
      <LoadingEffect />
    )}

  </>);
}

export default QuoteDetail;
