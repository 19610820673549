import { Outlet } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import { LeftMenuDrawer } from "../components/NavBar/LeftMenuDrawer";
import { Container } from "@mui/material";

const LayoutContainer = () => {
  const navBar = useSelector((state) => state.navbar);
  const menuOpen = navBar?.menuOpen;

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      position: "relative",
      marginTop: 48,
      padding: theme.spacing(3, 2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `${0}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
          marginLeft: `${0}px`,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: `${navBar.drawerWidth}px`,
        },
      }),
    })
  );

  return (
    <>
      <CssBaseline />

      <Main open={menuOpen}>
        <Outlet />
      </Main>
    </>
  );
};

export default LayoutContainer;
