import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

export const CustomTextField = ({
    variant = "outlined",
    field,
    form,
    onChange,
    type = "text",
    rightIcon = false,
    leftIcon = false,
    onRightClick = false,
    onLeftClick = false,
    onBlur = false,
    inputStyle = {},
    ...rest
}) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const errorText = form && (form.touched[field.name]) && form.errors[field.name];

    return (
        <TextField
            name={field?.name}
            {...field}
            
            error={!!errorText}
            helperText={errorText}
            fullWidth
            onBlur={() => {
                form?.handleBlur(field.name);
                <>
                    {onBlur ? <>{onBlur()}</> : ''}
                </>

            }}
            type={type === "password" ? (showPassword ? "text" : "password") : type}
            {...rest}
            inputProps={{
                style: {
                    ...inputStyle,

                }
            }}
            InputProps={{
                endAdornment:
                    type === "password" ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : rightIcon ? (
                        <InputAdornment position="center">
                            <IconButton
                                onClick={onRightClick ? onRightClick : () => { }}
                                aria-label="toggle password visibility"
                                edge="end"
                            >
                                {rightIcon}
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        ""
                    ),
                startAdornment: leftIcon ? (
                    <InputAdornment position="start">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={onLeftClick ? onLeftClick : () => { }}
                            edge="start"
                        >
                            {leftIcon}
                        </IconButton>
                    </InputAdornment>
                ) : (
                    ""
                ),
            }}
            variant={variant}

        />
    );

};
