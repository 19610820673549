import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import CustomButton from "../ActionButtons/CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ApiClient from "../../constant/API_URLS";
import { ServiceCard, TextArrowCard } from "../Cards/DisplayCards";
import { NoResultFound } from "../NoResult/NoResult";
import { LoadingEffect } from "../SimmerEffects/LoadingEffect";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Navigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1500} direction="up" ref={ref} {...props} />;
});

export function RadioListFullPageWrapper({
  label = "Select Option",
  handleClose,
  open = false,
  buttonText = 'Continue',
  children
}) {



  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 0.5, flex: 1, display: "flex" }}
              component="div"
            >
              <IconButton
                sx={{ mr: 0.8, ml: -1 }}
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                size="small"
              >
                <KeyboardBackspaceIcon />
              </IconButton>

              <Typography
                sx={{ alignSelf: "flex-end" }}
                component={"span"}
                variant="h6"
              >
                {label}
              </Typography>
            </Typography>

            {/* <IconButton
              sx={{ mr: -2 }}
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          
          {children}
         
        </Box>
      </Dialog>
    </>
  );
}
