import { GroupAddOutlined, Groups, Groups2Rounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  colors,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColors } from "../../../../constant/ThemeColors";
import Groups2Icon from "@mui/icons-material/Groups2";
import ReadableAddress from "../../../../utils/ReadableAddress";

export function UnScheduledJobCard({ id, url = "/jobs/detail/", job }) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        onClick={() => {
          navigate(url + job?.job_id);
        }}
        px={1}
        borderRadius={1}
        boxShadow={"0 0 3px -3px"}
        width={{ lg: "32%", md: "48%", xs: "100%" }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          ":hover": {
            cursor: "pointer",
            boxShadow: ThemeColors.hoverBoxShadow,
          },
          border: 0.5,
          borderBottom: 4,
          borderColor: ThemeColors.borderColor,
        }}
      >
        <List dense>
        <ListItem sx={{ justifyContent: "space-between", gap: 5, alignItems: 'baseline' }}>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 18,
                marginRight:1,
                fontWeight: "600",
                textTransform: 'capitalize'
              }}
              primary={
                job?.name
              }
            />

            <ListItemText
              primaryTypographyProps={{
                fontSize: 17,
                fontWeight: "600",
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
              primary={<Chip label={job.job_id} />}
            />
          </ListItem>
          <ListItem dense>


            <ListItemText
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "500",
                // color: "GrayText",
              }}
              primary={
                job?.client_details?.first_name +
                " " +
                job?.client_details?.last_name
              }
              secondaryTypographyProps={{
                fontSize: 14,
                // color: "GrayText",
              }}
              secondary={ReadableAddress(job?.client_details)}
            />

          </ListItem>
        </List>

        <Box>
          <Divider />

          <Typography
            display={"flex"}
            justifyContent={"space-between"}
            columnGap={1}
            px={2}
            py={1}
          >

            <Typography
              noWrap
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 0.5,
                py: 0.3,
              }}
            >
              <Typography component={"span"}>
                <Groups2Icon
                  sx={{ display: "flex", mr: 0.5 }}
                  htmlColor={ThemeColors.iconColor}
                />
              </Typography>
              <Typography
                component={"span"}
                noWrap
                sx={{
                  fontWeight: "400",
                  // color: "GrayText"
                }}
              >
                {job?.team_details.map((person, index) => {
                  if (person) {
                    if (index === job?.team_details?.length - 1) {
                      return `${person?.first_name + " " + person?.last_name}`;
                    }
                    return `${person?.first_name + " " + person?.last_name}, `;
                  }
                })}
              </Typography>
            </Typography>

            <Typography>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/jobs/update/" + job.job_id);
                }}
                sx={{
                  pr: 0.5,
                }}
                m={0}
                variant="outlined"
              >
                Schedule
                <KeyboardArrowRightIcon p={0} />
              </Button>
            </Typography>
          </Typography>
        </Box>

      </Box>
    </>
  );
}
