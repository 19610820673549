import React, { useEffect, useState } from "react";
import ApiClient from "../../../constant/API_URLS";
import {
  Box,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArchiveJobCard } from "../../../components/Cards/DisplayCards";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { NoResultFound } from "../../../components/NoResult/NoResult";
import { useSelector } from "react-redux";
import { NameCardList } from "../../../components/Cards";
import { Recycling, SettingsBackupRestore } from "@mui/icons-material";
import { ConfirmationDialog } from "../../../components";
import CustomToaster from "../../../utils/CustomToaster";

function Team() {
  const [rawteam, setTeam] = useState("");
  const team = rawteam?.data;

  const fetchArchive = (filter = `&page=1`) => {
    ApiClient.archives
      .get("?team=1" + filter)
      .then((res) => {
        if (res) {
          setTeam(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchArchive();
  }, []);

  const [filteredData, setFilteredData] = useState("");
  const searchText = useSelector((state) => state?.searchBarText);
  useEffect(() => {
    if (searchText && team) {
      const searchTextLowerCase = searchText.toLowerCase();
      const searchWords = searchTextLowerCase.split(" ");

      const filteredData = team.filter((item) => {
        const firstNameLowerCase = item?.first_name?.toLowerCase();
        const lastNameLowerCase = item?.last_name?.toLowerCase();
        const addressStrings = item.additionaladdress.map((address) =>
          `${address.street}, ${address.city}, ${address.state}, ${address.pin_code}`.toLowerCase()
        );

        const addressMatch = addressStrings.some((address) => {
          return searchWords.some((word) => address.includes(word));
        });

        return (
          searchWords.some((word) => firstNameLowerCase?.includes(word)) ||
          searchWords.some((word) => lastNameLowerCase?.includes(word)) ||
          addressMatch
        );
      });

      setFilteredData(filteredData);
    } else {
      setFilteredData("");
    }
  }, [searchText, rawteam]);

  const handleRestore = async (id, handleClose, rest) => {
    ApiClient.team
      .put(id, { is_deleted: 0 })
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          handleClose();
          fetchArchive();
        }
      })
      .catch((err) => {
        CustomToaster.error(err.message);
      });
  };
  return (
    <>
      {team ? (
        <>
          {team?.length ? (
            <>
              <Typography>
                Total :{" "}
                <Typography component={"strong"} style={{ fontWeight: "700" }}>
                  {team?.length}
                </Typography>
              </Typography>

              <NameCardList
                data={filteredData || team}
                noClick
                secondaryAction={
                  <ConfirmationDialog
                    onSubmit={handleRestore}
                    text="Are you sure you want to proceed with the restore? This action will revert the system to its previous state"
                  >
                    <Tooltip title="Restore">
                      <IconButton>
                        <SettingsBackupRestore />
                      </IconButton>
                    </Tooltip>
                  </ConfirmationDialog>
                }
              />
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Pagination
                  onChange={(e, value) => {
                    fetchArchive(`&page=${value}`);
                  }}
                  size="large"
                  page={rawteam?.current_page}
                  count={rawteam?.last_page}
                  color="primary"
                />
              </Box>
            </>
          ) : (
            <>
              <NoResultFound>No team found in archive</NoResultFound>
            </>
          )}
        </>
      ) : (
        <>
          <LoadingEffect />
        </>
      )}
    </>
  );
}

export default Team;
