import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const ServiceCard = ({
  service,
  url,
  onClick = false,
  hideButton = false,
  selectable = false,
  selectedIds = {},
  onMore = false,
}) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  return (
    <Box
      onClick={() => {
        onClick ? (
          <>
            {setSelected(!selected)}
            {selected ? onClick(service, true) : onClick(service)}
          </>
        ) : (
          <></>
          //   navigate(`${url}`)
        );
      }}
      boxShadow={"0 0 2px -1px"}
      mb={2}
      borderRadius={1}
      p={1}
      sx={{
        width: { xs: "100%", md: "49%", lg: "32%" },
        ":hover": {
          cursor: "pointer",
          boxShadow: "0 0 3px -1px",
        },
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontSize: 18,
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        {service?.name}
        {hideButton ? (
          ""
        ) : (
          <>
            {onMore ? (
              <>{onMore}</>
            ) : (
              <IconButton sx={{ padding: 0.3 }}>
                <MoreHorizIcon />
              </IconButton>
            )}
          </>
        )}

        {selectable ? (
          <>
            {selected || selectedIds[service.id] ? (
              <>
                {selectedIds[service.id] && !selected ? setSelected(true) : ""}
                <CheckCircleIcon htmlColor="#1976D2" />
              </>
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </>
        ) : (
          ""
        )}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: 500,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxHeight: "3.6em",
          fontWeight: 400,
        }}
      >
        <Typography
          variant="span"
          sx={{
            color: (theme) => theme.palette.primary,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Description:{"  "}{" "}
        </Typography>
        <Typography
          variant="span"
          sx={{
            fontSize: 16,
            color: "#adacac",
            fontWeight: 400,
          }}
        >
          {service?.description}
        </Typography>
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",

          mt: 1,
        }}
      >
        Unit Price:
        <Typography component={"span"} ml={1.5} fontWeight={"600"}>
         $ {service?.price}
        </Typography>
      </Typography>
    </Box>
  );
};
