import React, { useEffect } from "react";
import { CustomSwipableTabs, NavBar } from "../../components";
import ApiClient from "../../constant/API_URLS";
import Job from "./ArchivePage/Job";
import Team from "./ArchivePage/Team";
import Client from "./ArchivePage/Client";

function Archives() {



  const tabData = [
    {
      label: "Jobs",
      value: <Job />,
    },
    {
      label: "Team",
      value: <Team />,
    },
    {
      label: "Clients",
      value: <Client />,
    },
  ];



  return (
    <>
      <NavBar title="Archive" noFilter />
      <CustomSwipableTabs tabsData={tabData} />
    </>
  );
}

export default Archives;
