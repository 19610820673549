import React, { useEffect, useState } from "react";
import { CustomSwipableTabs, NavBar } from "../../components";
import {
  Box,
  Container,
  ListItemText,
  Typography,
  ListItem,
  Divider,
  Paper,
  Button,
  colors,
  LinearProgress,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { ThemeColors } from "../../constant/ThemeColors";
import {
  AccessTimeFilled,
  CheckCircle,
  Edit,
  LockClock,
  LockClockSharp,
  LockReset,
} from "@mui/icons-material";
import { GET_USER } from "../../utils/LocalStorageGetSet";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../constant/API_URLS";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";


export const Dashboard = () => {
  const navigate = useNavigate();

  const [details, setDetails] = useState("");

  const dashboardDetail = () => {
    ApiClient.adminDashboard
      .get()
      .then((res) => {
        if (res) {
          setDetails(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dashboardDetail();
  }, []);

  function calculatePercentages(data) {
    const totalQuotes = data.send_quote || 0;
    const acceptedQuotes = data.acepted_quote || 0;
    const totalTodo = data.todo || 0;

    const quotePercentage = Math.round(
      (acceptedQuotes / totalQuotes) * 100 || 0
    );
    const todoPercentage = Math.round(
      (data.completed_todo / totalTodo) * 100 || 0
    );

    return { quotePercentage, todoPercentage };
  }

  return (
    <>
      <NavBar noFilter noSearch title="Dashboard" />
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <Typography variant="h5">Dashboard</Typography>
        <Box sx={{ my: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              gap: 2,
            }}
          >
            <Box
              borderRadius={1}
              boxShadow={"0 0 3px -3px"}
              sx={{
                width: { lg: 2 / 3 },
                border: 0.5,
                borderBottom: 4,
                borderColor: ThemeColors.borderColor,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  background: "#EFF6FF",
                  borderRadius: 1,
                  display: "flex",
                }}
              >
                <ListItemText
                  primary="Welcome Back!"
                  secondary="Profile"
                  primaryTypographyProps={{
                    color: "#1072EF",
                    fontWeight: "700",
                  }}
                  secondaryTypographyProps={{
                    color: "#3C8CF2",
                    fontWeight: "600",
                  }}
                />

                <Edit
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/admin-profile")}
                />
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                }}
              >
                <ListItemText
                  primary="Name"
                  primaryTypographyProps={{ fontSize: 14 }}
                  secondaryTypographyProps={{
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: "500",
                  }}
                  secondary={GET_USER?.first_name + " " + GET_USER?.last_name}
                />
                <ListItemText
                  primary="Company Name"
                  primaryTypographyProps={{ fontSize: 14 }}
                  secondaryTypographyProps={{
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: "500",
                  }}
                  secondary={GET_USER?.company_name}
                />

                <ListItemText
                  primary="Phone"
                  secondary={
                    GET_USER?.phone || (
                      <Button
                        onClick={() => {
                          navigate("edit-profile");
                        }}
                        variant="text"
                        p={0}
                        m={0}
                      >
                        Add Phone
                      </Button>
                    )
                  }
                  primaryTypographyProps={{ fontSize: 14 }}
                  secondaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                />
                <ListItemText
                  primary="Email"
                  secondary={GET_USER?.email}
                  primaryTypographyProps={{ fontSize: 14 }}
                  secondaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                />
              </Box>
            </Box>
            {!details ? (
              <LoadingEffect />
            ) : (
              <Box
                sx={{
                  width: { lg: 1 / 3 },
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                  },
                  gap: 2,
                }}
              >
                <Box
                  onClick={() => { navigate('/jobs?tab=upcoming') }}
                  sx={{
                    p: 2,
                    background: "#FEFADC",
                    borderRadius: 1,
                    border: 1,
                    borderBottom: 4,
                    borderColor: ThemeColors.borderColor,
                    ':hover': {
                      cursor: 'pointer'
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      textTransform: "uppercase",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <AccessTimeFilled htmlColor="#C8AD02" /> UPCOMING JObs
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: 18,
                    }}
                  >
                    {details?.upcoming_jobs} Jobs
                  </Typography>
                </Box>

                <Box
                  onClick={() => { navigate('/jobs?tab=inprogress') }}
                  sx={{
                    p: 2,
                    background: "#FFECE5",
                    borderRadius: 1,
                    border: 1,
                    borderBottom: 4,
                    borderColor: ThemeColors.borderColor,
                    ':hover': {
                      cursor: 'pointer'
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      textTransform: "uppercase",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <LockReset htmlColor="#E9531B" /> In progress JObs
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: 18,
                    }}
                  >
                    {details?.progress_jobs} Jobs
                  </Typography>
                </Box>

                <Box
                  onClick={() => { navigate('/jobs?tab=completed') }}
                  sx={{
                    p: 2,
                    background: "#F6FFFB",
                    borderRadius: 1,
                    border: 1,
                    borderBottom: 4,
                    borderColor: ThemeColors.borderColor,
                    ':hover': { cursor: 'pointer' }
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      textTransform: "uppercase",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <CheckCircle htmlColor="#0C994B" /> Completed JObs
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: 18,
                    }}
                  >
                    {details?.completed_jobs} Jobs
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {!details ? (
            <LoadingEffect />
          ) : (
            <Box
              sx={{
                my: 3,
                display: "flex",
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                gap: 2,
              }}
            >
              <Box
                borderRadius={1}
                boxShadow={"0 0 3px -3px"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: { lg: 1 / 1 },
                  border: 0.5,
                  borderBottom: 4,
                  borderColor: ThemeColors.borderColor,
                }}
              >
                <Box
                  onClick={() => { navigate('/quote?tab=sent') }}
                  sx={{ p: 2, background: "#fff", borderRadius: 1, ':hover': { cursor: 'pointer' } }}>
                  <ListItemText
                    primary="Sent Quote"
                    secondary={details?.send_quote}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      color: colors.grey[600],
                    }}
                    secondaryTypographyProps={{
                      fontSize: 48,
                      mt: -1.6,
                      fontWeight: "600",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    m: 1,
                    p: 2,
                    background: "#eee",
                    borderRadius: 1,
                  }}
                >
                  <Box sx={{ display: "flex", gap: 12 }}>
                    <ListItemText
                      primary={
                        calculatePercentages(details)?.quotePercentage + "%"
                      }
                      primaryTypographyProps={{
                        fontWeight: "700",
                        fontSize: 24,
                        color: colors.green[800],
                      }}
                    />
                    <ListItemText
                      primary="Accepted Quotes"
                      secondary={details?.acepted_quote}
                      primaryTypographyProps={{
                        fontWeight: "600",
                        fontSize: 11,
                        color: colors.grey[600],
                      }}
                      secondaryTypographyProps={{
                        fontWeight: "700",
                        fontSize: 16,
                        mt: -0.5,
                        textAlign: "end",
                      }}
                    />
                  </Box>
                  <LinearProgress
                    color="success"
                    sx={{ height: 8, width: "100%", borderRadius: 4 }}
                    variant="determinate"
                    value={calculatePercentages(details)?.quotePercentage}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {!details ? (
            <LoadingEffect />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                gap: 2,
              }}
            >
              <Box
                borderRadius={1}
                boxShadow={"0 0 3px -3px"}
                sx={{
                  width: { lg: 2 / 3 },
                  border: 0.5,
                  borderBottom: 4,
                  borderColor: ThemeColors.borderColor,
                  display: "flex",
                  justifyContent: "space-between",
                  // px:4
                }}
              >
                <Box
                  onClick={() => { navigate('/team') }}
                  sx={{ p: 2, background: "#fff", borderRadius: 1, ':hover': { cursor: 'pointer' } }}>
                  <ListItemText
                    primary="Active Team Member"
                    secondary={details?.total_team_members}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      color: colors.grey[600],
                      textTransform: "capitalize",
                    }}
                    secondaryTypographyProps={{
                      fontSize: 48,
                      mt: -1.6,
                      fontWeight: "600",
                    }}
                  />
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box onClick={() => { navigate('/clients') }} sx={{ p: 2, background: "#fff", borderRadius: 1, ':hover': { cursor: 'pointer' } }}>
                  <ListItemText
                    primary="total Customers"
                    secondary={details?.total_customer}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      color: colors.grey[600],
                      textTransform: "capitalize",
                    }}
                    secondaryTypographyProps={{
                      fontSize: 48,
                      mt: -1.6,
                      fontWeight: "600",
                    }}
                  />
                </Box>
              </Box>

              <Box
                borderRadius={1}
                boxShadow={"0 0 3px -3px"}
                sx={{
                  width: { lg: 1 / 3 },
                  border: 0.5,
                  borderBottom: 4,
                  borderColor: ThemeColors.borderColor,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // px:4
                }}
              >
                <Box
                  sx={{ p: 2, background: "#fff", borderRadius: 1 }}
                >
                  <ListItemText
                    primary="Tasks"
                    secondary={details?.todo}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      color: colors.grey[600],
                      textTransform: "capitalize",
                    }}
                    secondaryTypographyProps={{
                      fontSize: 48,
                      mt: -1.6,
                      fontWeight: "600",
                    }}
                  />
                </Box>

                <Box sx={{ p: 2, background: "#fff", borderRadius: 1 }}>
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      sx={{
                        height: "100px !important",
                        width: "100px !important",
                        transform: "rotate(100deg) !important",
                      }}
                      variant="determinate"
                      value={calculatePercentages(details)?.todoPercentage}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        sx={{
                          fontWeight: "700",
                          fontSize: 16,
                        }}
                      >
                        {`${calculatePercentages(details)?.todoPercentage}%`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
