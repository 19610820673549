import React, { useEffect, useState } from "react";
import ApiClient from "../../../constant/API_URLS";
import { Box, IconButton, Pagination, Tooltip, Typography } from "@mui/material";
import { ArchiveJobCard } from "../../../components/Cards/DisplayCards";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { NoResultFound } from "../../../components/NoResult/NoResult";
import { NameCardList } from "../../../components/Cards";
import { useSelector } from "react-redux";
import CustomToaster from "../../../utils/CustomToaster";
import { ConfirmationDialog } from "../../../components";
import { SettingsBackupRestore } from "@mui/icons-material";

function Client() {
  const [rawclients, setClients] = useState("");
  const clients = rawclients?.data;

  const fetchArchive = (filter = `&page=1`) => {
    ApiClient.archives
      .get('?client=1' + filter)
      .then((res) => {
        if (res) {
          setClients(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchArchive();
  }, []);



  const [filteredData, setFilteredData] = useState('');
  const searchText = useSelector(state => state?.searchBarText);
  useEffect(() => {
    if (searchText && clients) {
      const searchTextLowerCase = searchText?.toLowerCase();
      const searchWords = searchTextLowerCase.split(' ');

      const filteredData = clients.filter((item) => {
        const firstNameLowerCase = item?.first_name?.toLowerCase();
        const lastNameLowerCase = item?.last_name?.toLowerCase();
        const addressStrings = item.additionaladdress.map((address) =>
          `${address.street}, ${address.city}, ${address.state}, ${address.pin_code}`.toLowerCase()
        );

        const addressMatch = addressStrings.some((address) => {
          return searchWords.some((word) => address.includes(word));
        });

        return (
          searchWords.some((word) => firstNameLowerCase?.includes(word)) ||
          searchWords.some((word) => lastNameLowerCase?.includes(word)) ||
          addressMatch
        );
      });


      setFilteredData(filteredData);
    } else {
      setFilteredData('')
    }
  }, [searchText, rawclients])

  const handleRestore = async (id, handleClose, rest) => {

    ApiClient.client.put(id, { is_deleted: 0 }).then(res => {
      if (res) {
        CustomToaster.success(res.message);
        handleClose()
        fetchArchive()
      }
    }).catch(err => {
      CustomToaster.error(err.message);
    })
  }

  return (
    <>
      {clients ? (
        <>
          {clients?.length ? <><Typography>
            Total :{" "}
            <Typography component={"strong"} style={{ fontWeight: "700" }}>
              {clients?.length}
            </Typography>
          </Typography>


            <NameCardList secondaryAction={
              <ConfirmationDialog onSubmit={handleRestore} text="Are you sure you want to proceed with the restore? This action will revert the system to its previous state">
                <Tooltip title="Restore">
                      <IconButton>
                        <SettingsBackupRestore />
                      </IconButton>
                    </Tooltip>
              </ConfirmationDialog>
            } data={filteredData || clients} noClick />
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', mt: 4 }}>
              <Pagination onChange={(e, value) => {
                fetchArchive(`&page=${value}`);
              }} size='large' page={rawclients?.current_page} count={rawclients?.last_page} color="primary" />
            </Box>
          </> : <>
            <NoResultFound>No client found in archive</NoResultFound>
          </>}
        </>
      ) : (
        <>
          <LoadingEffect />
        </>
      )}
    </>
  );
}

export default Client;
