import React, { useEffect, useState } from "react";
import ApiClient from "../../../constant/API_URLS";
import { Box, Pagination, Typography } from "@mui/material";
import { ArchiveJobCard } from "../../../components/Cards/DisplayCards";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { NoResultFound } from "../../../components/NoResult/NoResult";
import { useSelector } from "react-redux";

function Job() {
  const [rawjobs, setJobs] = useState("");
  const jobs = rawjobs?.data;

  const fetchArchive = (filter = '?page=1') => {
    ApiClient.archives
      .get(filter)
      .then((res) => {
        if (res) {
          setJobs(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchArchive();
  }, []);


  const [filteredData, setFilteredData] = useState("");
  const searchText = useSelector((state) => state?.searchBarText);
  useEffect(() => {
    if (searchText && jobs?.length) {
      const searchTextLowerCase = searchText.toLowerCase();
      const searchWords = searchTextLowerCase.split(" ");

      const filteredData = jobs.filter((item) => {
        const jobNameLowerCase = item?.name?.toLowerCase();
        const firstNameLowerCase =
          item?.client_details?.first_name?.toLowerCase();
        const lastNameLowerCase = item?.client_details?.last_name?.toLowerCase();
        const companyNameLowerCase =
          item?.client_details?.company_name?.toLowerCase();
        const address = item?.client_details;
        const addressStrings =
          `${address.street}, ${address.city}, ${address.state}, ${address.pin_code}`?.toLowerCase();

        return (
          searchWords.some((word) => firstNameLowerCase?.includes(word)) ||
          searchWords.some((word) => jobNameLowerCase?.includes(word)) ||
          searchWords.some((word) => companyNameLowerCase?.includes(word)) ||
          searchWords.some((word) => lastNameLowerCase?.includes(word)) ||
          searchWords.some((word) => addressStrings.includes(word))
        );
      });

      setFilteredData(filteredData);
    } else {
      setFilteredData("");
    }
  }, [searchText, rawjobs]);


  const handleJobClick = (job) => {
    
  }

  return (
    <>
      {jobs ? (
        <>
          {jobs?.length ? <>
            <Typography>
              Total Jobs :{" "}
              <Typography component={"strong"} style={{ fontWeight: "700" }}>
                {jobs?.length}
              </Typography>
            </Typography>

            <Box
              py={2}
              overflow={"hidden"}
              my={1}
              display={"flex"}
              flexWrap={"wrap"}
              gap={2}
            >
              {(filteredData || jobs)?.map((item, index) => (
                <ArchiveJobCard fetchArchive={fetchArchive} key={index} job={item} />
              ))}
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', mt: 4 }}>
              <Pagination onChange={(e, value) => {
                fetchArchive(`?page=${value}`);
              }} size='large' page={rawjobs?.current_page} count={rawjobs?.last_page} color="primary" />
            </Box>
          </> : <>
            <NoResultFound>No Job in archive found</NoResultFound>
          </>}
        </>
      ) : (
        <>
          <LoadingEffect />
        </>
      )}
    </>
  );
}

export default Job;
