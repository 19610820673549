import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Divider,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export function BottomDrawer({
  title = "Action",
  icon,
  data = [],
  state,
  setState,
}) {
  const [internalState, setInternalState] = React.useState(false);

  // Define the state to use (external or internal)
  const stateApp = typeof state !== "undefined" ? state : internalState;
  const setStateApp =
    typeof setState !== "undefined" ? setState : setInternalState;

  const list = () => (
    <Box role="presentation">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          //   mr:1,
          //   mt:1
        }}
      >
        <Typography>{title}</Typography>
        <IconButton
          size="small"
          onClick={() => setStateApp(false)}
          color="inherit"
        >
          <Close />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {data.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </List>
    </Box>
  );

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("md"));

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        color="inherit"
        onClick={(e) => {
          if (isLg) {
            handleClick(e);
            setStateApp(true);
          } else {
            setStateApp(true);
          }
        }}
      >
        {icon ? <>{icon}</> : <MoreHorizIcon htmlColor="" />}
      </IconButton>

      <Popover
        id={id}
        open={stateApp && anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box role="presentation">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 1,
              //   mr:1,
              //   mt:1
            }}
          >
            <List>
              {/* {list()} */}
              {data.map((item, index) => (
                <React.Fragment key={index}>{item}</React.Fragment>
              ))}
            </List>
          </Box>
        </Box>
      </Popover>

      <Drawer
        sx={{
          maxHeight: "20%",
          display: { sm: "block", lg: "none" },
        }}
        PaperProps={{
          style: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
        anchor={"bottom"}
        open={stateApp}
        onClose={() => setStateApp(false)}
      >
        {list()}
      </Drawer>
    </>
  );
}
