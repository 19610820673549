import authSlice from "./authSlice";
import navbarSliceReducers from "./navbarSlice";
import constantReducers from "./constantSlice"
import searchBarTextReducers from './searchBarTop'


export default {
    navbar: navbarSliceReducers,
    auth: authSlice,
    constant: constantReducers,
    searchBarText: searchBarTextReducers
}