import { Close } from "@mui/icons-material";
import Search from "@mui/icons-material/Search";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import { MaterialCard, ServiceCard, TextArrowCard } from "../Cards/DisplayCards";

function MaterialGlobalSearch({ setSelected, selected }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (service = true) => {
        if (service) {
            setSelected((prevState) => ({
                ...prevState,
                material: true,
            }));
        }
        setDisplayList(false)
        setOpen(false);

        setValue('')
    };
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [displayList, setDisplayList] = useState(false);

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            setDisplayList(false);
            setIsLoading(true);

            try {
                const res = await ApiClient.materialCategory.get("?name=" + value);
                setDisplayList(res?.data);
            } catch (error) {
                CustomToaster.error(error?.message);
            } finally {
                setIsLoading(false);
                // setDisplayList(true);
            }
        },
        [value]
    );

    return (
        <>
            <Tooltip title="Search">
                <IconButton sx={{ mr: 1 }} onClick={handleOpen}>
                    <SvgIcon>
                        <Search sx={{ color: "white" }} />
                    </SvgIcon>
                </IconButton>
            </Tooltip>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => handleClose()}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                    sx={{
                        px: 3,
                        py: 1,
                    }}
                >
                    <Typography variant="h6">Search</Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <SvgIcon>
                            <Close />
                        </SvgIcon>
                    </IconButton>
                </Stack>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon>
                                            <Search />
                                        </SvgIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => setValue(event.target.value)}
                            placeholder="Search..."
                            value={value}
                        />
                    </Box>
                    {isLoading && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: 3,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {displayList && (
                        <>
                            {displayList?.metarial?.length ? (
                                <>
                                    {" "}
                                    <Typography sx={{ mt: 2 }} variant="h6">
                                        Material
                                    </Typography>
                                    {displayList?.metarial?.map((material) => (
                                        <>
                                            <MaterialCard
                                                fullWidth
                                                selectable
                                                hideButton
                                                selectedIds={selected.materialIds}
                                                key={material.id}
                                                onClick={(material, isSelected) => {
                                                    if (isSelected) {
                                                        setSelected((prevState) => {
                                                            const updatedmaterialIds = {
                                                                ...prevState.materialIds,
                                                            };
                                                            delete updatedmaterialIds[material.id];
                                                            return {
                                                                ...prevState,
                                                                materialIds: updatedmaterialIds,
                                                            };
                                                        });
                                                    } else {
                                                        setSelected((prevState) => ({
                                                            ...prevState,
                                                            materialIds: {
                                                                ...prevState.materialIds,
                                                                [material.id]: material,
                                                            },
                                                        }));
                                                    }
                                                }}
                                                material={material}
                                            />
                                        </>
                                    ))}
                                </>
                            ) : (
                                ""
                            )}

                            {displayList?.metarial_category?.length ? (
                                <>
                                    <Typography sx={{ mt: 2 }} variant="h6">
                                        Material Categories
                                    </Typography>
                                    {displayList?.metarial_category?.map((item) => (
                                        <>
                                            <TextArrowCard
                                                key={item.id}
                                                title={item.name}
                                                onClick={() => {
                                                    setSelected((prevState) => {
                                                        let tempArray = prevState.subCat;
                                                        tempArray = [...tempArray, item.id];
                                                        return {
                                                            ...prevState,
                                                            subCat: tempArray,
                                                        }
                                                    });

                                                    handleClose(false);
                                                }}
                                            />
                                        </>
                                    ))}
                                </>
                            ) : (
                                ""
                            )}

                            {displayList?.industry?.length ? (
                                <>
                                    <Typography sx={{ mt: 2 }} variant="h6">
                                        Material Industry
                                    </Typography>
                                    {displayList?.industry?.map((cat) => (
                                        <>
                                            <TextArrowCard
                                                key={cat.id}
                                                title={cat.name}
                                                onClick={() => {
                                                    setSelected((prevState) => ({
                                                        ...prevState,
                                                        cat: cat.id,
                                                        subCat: []
                                                    }));
                                                    handleClose(false);
                                                }}
                                            />
                                        </>
                                    ))}
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default MaterialGlobalSearch;
