import React, { useEffect, useState } from "react";
import {
  QuoteCard,
  ScheduledQuoteCard,
} from "../../../components/Cards/DisplayCards";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { NoResultFound } from "../../../components";

export function Quote() {
  const { userId } = useParams();
  const [quoteList, setQuoteList] = useState("");

  const fetchJobs = () => {
    ApiClient.quote
      .get("?user_id=" + userId)
      .then((res) => {
        if (res) {
          setQuoteList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <Box
      py={2}
      justifyContent={"center"}
      overflow={"hidden"}
      my={1}
      display={"flex"}
      flexWrap={"wrap"}
      gap={2}
    >
      {quoteList ? (
        <>
          {!quoteList?.length ? (
            <NoResultFound />
          ) : (
            <>
              {" "}
              {quoteList.map((item, index) => (
                <ScheduledQuoteCard
                  url="/quote/detail/"
                  key={item.quote_id}
                  quote={item}
                />
              ))}
            </>
          )}
        </>
      ) : (
        <>
          <LoadingEffect />
        </>
      )}
    </Box>
  );
}
