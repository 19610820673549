import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CustomTextField } from "../../components/InputFields/TextFields";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  confirmPasswordValidationSchema,
  emailValidationSchema,
  passwordValidationSchema,
} from "../../constant/validationSchema";

import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";
import handleAsyncActionWithToaster from "../../utils/handleAsyncActionWithToaster";
import { SET_TOKEN, SET_USER } from "../../utils/LocalStorageGetSet";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUnitList } from "../../redux/constantSlice";
import { Forgot } from "./Forgot";

export function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const redirect = location?.state?.from?.pathname || "/";

  const [disable, setDisable] = React.useState(false);
  const login = (data) => {
    ApiClient.login
      .post(data)
      .then((res) => {
        if (res?.data?.token) {
          SET_TOKEN(res?.data?.token);
          dispatch(setToken(res?.data?.token));
          SET_USER(res?.data);
          window.location = redirect;
          dispatch(fetchUnitList());
          navigate(redirect);
        }
        setDisable(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "admin@gmail.com",
      password: "Demo@123",
    },
    validationSchema: Yup.object().shape({
      password: passwordValidationSchema,
      email: emailValidationSchema,
    }),
    onSubmit: (values) => login(values),
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={"/assets/static/img/logo.png"}
          margin={2}
          width={420}
          height={160}
        />
        <Typography component="h1" variant="h5" mt={4}>
          Sign in
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("email")}
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <CustomTextField
            form={formik}
            field={formik.getFieldProps("password")}
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Forgot />
          <Button
            disabled={disable}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, p: 1 }}
          >
            Sign In
          </Button>

          {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
        </Box>
      </Box>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Intel Electric
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
