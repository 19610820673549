import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BottomDrawer, ConfirmationDialog, NavBar } from "../../components";
import ApiClient from "../../constant/API_URLS";
import { Box, Grid, ListItemButton, Typography } from "@mui/material";
import {
  LabelTextAddressCard,
  LabelTextCard,
  LabelTextEmailCard,
  LabelTextPhoneCard,
  TextArrowCard,
} from "../../components/Cards/DisplayCards";
import {
  AddAddress,
  AddEmail,
  AddPhone,
} from "../../components/AddAdditionalContact";
import CustomToaster from "../../utils/CustomToaster";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import { UpdateAddress } from "../../components/UpdateDetails/UpdateAddress";
import { UpdateText } from "../../components/UpdateDetails/UpdateText";
import { UpdateEmail } from "../../components/UpdateDetails/UpdateEmail";
import { UpdatePhone } from "../../components/UpdateDetails/UpdatePhone";
import { DeleteConfirmationDialog } from "../../components/DeleteDetails/DeleteConfirmationDialog";
import { ThemeColors } from "../../constant/ThemeColors";

import { Delete, Edit } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import { userActiveInActive } from "../../constant/GlobalVariable";

export const Profile = () => {
  const { userId } = useParams();
  const [profileDetail, setProfileDetail] = useState("");
  const navigate = useNavigate();

  const fetchDetail = () => {
    setProfileDetail("");
    ApiClient.team
      .get(userId)
      .then((res) => {
        setProfileDetail(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTeam = (data, handleClose) => {
    ApiClient.team
      .put(userId, data)
      .then((res) => {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      })
      .catch((err) => {
        CustomToaster.error(err?.message);
      });
  };

  const deleteTeam = (data, handleClose) => {
    ApiClient.team
      .delete(userId)
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          handleClose();
          navigate(-1);
        }
      })
      .catch((err) => {
        CustomToaster.error(err?.message);
      });
  };

  useEffect(() => {
    if (userId) {
      fetchDetail();
    }
  }, []);

  const activeDeactiveUser = (data, handleClose) => {
    ApiClient.client.put(userId, {
        is_active: data === userActiveInActive.active ? userActiveInActive.inactive : userActiveInActive.active
    }).then(res => {
        if (res) {
            fetchDetail();
            handleClose()
        }
    }).catch(err => {
        console.log(err);
    })
}

  const actions = [
    <>
      <ConfirmationDialog  onSubmit={(_, handleClose) => {
                    activeDeactiveUser(profileDetail?.is_active, handleClose);
                }} >
        <ListItemButton
          sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
        >
          <TuneIcon />
          <Typography component={"span"}>{profileDetail?.is_active ? 'Active' : 'Deactive'}</Typography>
        </ListItemButton>
      </ConfirmationDialog>
    </>,
    <>
      <DeleteConfirmationDialog onSubmit={deleteTeam}>
        <ListItemButton
          sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Delete />
          <Typography component={"span"}>Delete</Typography>
        </ListItemButton>
      </DeleteConfirmationDialog>
    </>,
  ];

  return (
    <>
      <NavBar
        isSearch={false}
        isFilter={false}
        title={profileDetail?.first_name || "Team Profile"}
        goBack
        onRight={<BottomDrawer title="Action" data={actions} />}
      />

      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        {!profileDetail ? (
          <LoadingEffect />
        ) : (
          <>
            <LabelTextCard
              label={"First Name"}
              value={profileDetail?.first_name}
              onUpdate={
                <UpdateText
                  label={"First Name"}
                  name={"first_name"}
                  value={profileDetail?.first_name}
                  onSubmit={updateTeam}
                />
              }
            />

            <LabelTextCard
              label={"Last Name"}
              value={profileDetail?.last_name}
              onUpdate={
                <UpdateText
                  label={"Last Name"}
                  name={"last_name"}
                  value={profileDetail?.last_name}
                  onSubmit={updateTeam}
                />
              }
            />

            <LabelTextEmailCard
              onUpdate={fetchDetail}
              label={"Email"}
              value={profileDetail?.AdditionEmails}
              // addButton={<AddEmail onUpdate={fetchDetail} id={userId} />}
            />

            <LabelTextPhoneCard
              onUpdate={fetchDetail}
              label={"Phone"}
              value={profileDetail?.AdditionPhones}
              // addButton={<AddPhone onUpdate={fetchDetail} id={userId} />}
            />

            <LabelTextAddressCard
              onUpdate={fetchDetail}
              label={"Address"}
              value={profileDetail?.additionaladdress}
              // addButton={<AddAddress id={userId} onUpdate={fetchDetail} />}
            />
            <TextArrowCard
              noDivider
              sx={{
                border: 1,
                borderBottom: 3,
                borderColor: ThemeColors.borderColor,
                // boxShadow:ThemeColors.hoverBoxShadow,
                ":hover": {
                  boxShadow: ThemeColors.hoverBoxShadow,
                  cursor: "pointer",
                },
              }}
              title={"Jobs"}
              url="visits"
            />
          </>
        )}
      </Box>
    </>
  );
};
