export const jobStatus = {
    DRAFT: 1,
    UPCOMING: 2,
    INPROGRESS: 3,
    COMPLETE: 4

}

export const userActiveInActive = {
    active: 0,
    inactive: 1
}

export const todoStatus = {
    ASSIGN: 1,
    PENDING: 2,
    COMPLETED: 3
}
export const quoteStatus = {
    SENT: 2,
    SCHEDULED: 1,
    DECLINED: 2,
    ACCEPTED:1
}