import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {
  BottomDrawer,
  FloatingBottomRight,
  NavBar,
  NoResultFound,
} from "../../../components";
import { Box, Button, Grid, ListItemButton, Typography } from "@mui/material";
import { ServiceCard } from "../../../components/Cards/DisplayCards";
import ApiClient from "../../../constant/API_URLS";
import { useNavigate, useParams } from "react-router-dom";
import {
  numericValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomToaster from "../../../utils/CustomToaster";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails/DeleteConfirmationDialog";
import { Delete, Edit } from "@mui/icons-material";
import CustomUnitSelect from "../../../components/InputFields/SelectFields/CustomUnitSelect";

const CreateService = ({ id, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      unit_id: "",
      price: "",
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      description: stringValidationSchema,
      unit_id: numericValidationSchema,
      price: numericValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      createService(values, resetForm);
    },
  });

  const createService = (data, resetForm) => {
    ApiClient.service
      .post(data, id)
      .then((res) => {
        CustomToaster.success(res?.message);
        resetForm();
        onSubmit();
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        mt: 1,
        mx: 2,
        pb: 1,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container columnSpacing={{ md: 1, lg: 2 }} justifyContent="center">
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("name")}
            margin="normal"
            label="Service Name"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("description")}
            margin="normal"
            label="Description"
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomUnitSelect form={formik}
            name={'unit_id'} />
          {/* <CustomTextField
            form={formik}
            field={formik.getFieldProps("unit_id")}
            margin="normal"
            label="Unit Id"
          /> */}
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("price")}
            margin="normal"
            label="Price "
          />
        </Grid>
        <Grid
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "flex-end",
            gap: 1,
            mt: 3,
          }}
          item
          xs={12}
          width={"100%"}
        >
          <Button onClick={onClose} sx={{ color: "#A9A9A9" }}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Grid>

        <Grid sx={{ display: { md: "none" } }} item xs={12} width={"100%"}>
          <CustomButton label={"Save"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export const ServicesList = () => {
  const dispatch = useDispatch();
  const { catId, subCatId } = useParams();
  const [services, setService] = useState("");

  const fetchDetail = () => {
    ApiClient.service
      .get(subCatId)
      .then((res) => {
        setService(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const navigate = useNavigate();


  useEffect(() => {
    if (catId) {
      fetchDetail();
    }
  }, []);


  const handleDelete = (id, handleCloseSuccess) => {
    ApiClient.service.delete(id).then((res) => {
      if (res) {
        CustomToaster.success(res.message)
        handleCloseSuccess()
        fetchDetail()
      }
    }).catch(err => { console.log(err); })
  }



  const generateAction = (id) => ([
    <>
      <DeleteConfirmationDialog onSubmit={(_, handleCloseSuccess) => handleDelete(id, handleCloseSuccess)} >
        <ListItemButton
          sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Delete />
          <Typography component={"span"}>Delete</Typography>
        </ListItemButton>
      </DeleteConfirmationDialog>
    </>,

    // <>
    //   <ListItemButton
    //     onClick={() => {
    //       // navigate("/jobs/update/" + jobId + "/full");
    //     }}
    //     sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
    //   >
    //     <DoNotDisturbIcon />
    //     <Typography component={"span"}>Mark Unavailable</Typography>
    //   </ListItemButton>
    // </>,
  ]

  )


  return (
    <>
      <NavBar title="Services" goBack  noFilter noSearch />
      <Box sx={{ mt: 2, display:'flex', flexWrap:'wrap',gap:1.6 }}>
        {!services ? (
          <LoadingEffect />
        ) : (
          <>
            {services?.length ? (
              <>
                {services?.map((service) => (
                  <ServiceCard
                    key={service.id}
                    service={service}
                    onMore={<BottomDrawer title="Action" data={generateAction(service.id)} />}
                  />
                ))}
              </>
            ) : (
              <>
                <NoResultFound>No Result Found</NoResultFound>
              </>
            )}
          </>
        )}
      </Box>

      <FloatingBottomRight title={"Add Service"}>
        <CreateService onSubmit={fetchDetail} id={subCatId} />
      </FloatingBottomRight>
    </>
  );
};
