import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLeftIcon, setPageTitle } from "../../../redux/navbarSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { LabelTextCard, NavBar } from "../../../components";
import { useFormik } from "formik";
import {
  confirmPasswordValidationSchema,
  emailValidationSchema,
  numericValidationSchema,
  passwordValidationSchema,
  phoneValidationSchema,
  postalCodeValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomAutoComplete from "../../../components/InputFields/SelectFields/CustomAutoComplete";
import {
  CANADA_CITIES,
  CANADA_STATES,
} from "../../../constant/CanadaStateCities";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { MapModal, usePlacesAutocomplete } from "../../../utils/GoogleMap";
import GoogleAutoComplete from "../../../components/InputFields/SelectFields/GoogleAutoComplete";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate } from "react-router-dom";
import CustomCheckBox from "../../../components/InputFields/CustomCheckBox/CustomCheckBox";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";

export default function ClientAdd() {
  const navigate = useNavigate();
  const [taxes, setTaxes] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: taxes?.name,
      rate: taxes?.rate,
      authority: taxes?.authority,
    },

    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      rate: numericValidationSchema,
      // authority: stringValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      if (!taxes?.id) {
        createTaxes(values, resetForm);
      } else {
        updateTaxes(values, resetForm);
      }
    },
  });

  const createTaxes = (data, resetForm) => {
    ApiClient.tax
      .post(data)
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          resetForm();
          // navigate(-1);
          setIsEditable(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTaxes = (data, resetForm) => {
    ApiClient.tax
      .put(taxes?.id, data)
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          resetForm();
          // navigate(-1);
          setIsEditable(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [rows, setRows] = useState([1]);
  const hanldeAddField = () => {
    setRows((prevState) => [...prevState, 1]);
  };
  const hanldeRemoveField = (indexToRemove) => {
    setRows((prevState) => {
      let newArray = [
        ...prevState.slice(0, indexToRemove),
        ...prevState.slice(indexToRemove + 1),
      ];

      return newArray;
    });
  };

  const getTaxes = () => {
    ApiClient.tax
      .get()
      .then((res) => {
        if (res) {
          setTaxes(res?.data[0] || "false");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTaxes();
  }, [isEditable]);

  useEffect(() => {
    if (taxes) {
      formik.setValues({
        name: taxes?.name,
        rate: taxes?.rate,
        authority: taxes?.authority,
      });
    }
  }, [taxes]);

  return (
    <>
      {!taxes ? (
        <LoadingEffect />
      ) : (
        <>
          {isEditable ? (
            <Box component="form" onSubmit={formik.handleSubmit}>
              {rows?.map((item, index) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                      mt: 1,
                      height: "100%",
                      gap: 2,
                    }}
                  >
                    <CustomTextField
                      form={formik}
                      field={formik.getFieldProps("name")}
                      margin="normal"
                      label="Tax name"
                    />
                    <CustomTextField
                      form={formik}
                      field={formik.getFieldProps("rate")}
                      margin="normal"
                      label="Rate (%)"
                    />
                    <CustomTextField
                      form={formik}
                      field={formik.getFieldProps("authority")}
                      margin="normal"
                      label="Tax authority"
                    />

                    {index ? (
                      <RemoveCircleOutlineIcon
                        color="error"
                        onClick={() => hanldeRemoveField(index)}
                      />
                    ) : (
                      <Typography
                        component={"span"}
                        sx={{ px: 0 }}
                      ></Typography>
                    )}
                  </Box>
                </>
              ))}

              <Box sx={{ ":hover": { cursor: "pointer" }, pb: 4, pt: 4 }}>
                {/* <Typography sx={{ display: 'flex', alignContent: 'center', color: 'green', fontSize: 19, fontWeight: '500' }}><AddIcon /> Add new line</Typography> */}
                <Typography sx={{ pl: 1 }}>
                  Eg, Tax authority for the tax you are adding Eg: Illionois
                  department of revenue.
                </Typography>
              </Box>
              {/* <Box onClick={hanldeAddField} sx={{ ':hover': { cursor: 'pointer' }, pb: 8, pt: 4 }}>
          <Typography sx={{ display: 'flex', alignContent: 'center', color: 'green', fontSize: 19, fontWeight: '500' }}><AddIcon /> Add new line</Typography>
          <Typography sx={{ pl: 1 }}>
            Eg, Tax authority for the tax you are adding Eg: Illionois
            department of revenue.
          </Typography>
        </Box> */}

              <Button
                type="submit"
                variant="contained"
                sx={{
                  maxWidth: "400px",
                  width: "90%",
                  display: "flex",
                  py: 1,
                  margin: "auto",
                  fontSize: 18,
                  mt: 4,
                }}
              >
                Save
              </Button>
            </Box>
          ) : (

            <Box component={'div'}  sx={{ m: 2 }}>
              <Box  component={'div'} sx={{display:'flex', justifyContent:'flex-end'}}> 
              <Button onClick={() => setIsEditable(true)} variant="outlined">
                Edit
              </Button>

              </Box>
              <Box component={'div'} sx={{ display: "flex", gap: 10 }}>
                <LabelTextCard
                  label={"Tax Name"}
                  value={
                    taxes?.name +
                    `${taxes?.authority ? " (" + taxes?.authority + ")" : ""}`
                  }
                />
                <LabelTextCard label={"Tax Rate"} value={taxes?.rate + "%"} />
                <LabelTextCard
                  label={"Tax Authority"}
                  value={taxes?.authority}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}
