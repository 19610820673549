export default function ReadableAddress(jsonData) {
    const street = jsonData.street;
    const city = jsonData.city;
    const state = jsonData.state;
    const pincode = jsonData.pincode;
    const country = jsonData.country;
    let readableAddress = '';
  
    if (street) readableAddress += `${street}, `;
    if (city) readableAddress += `${city}, `;
    if (state) readableAddress += `${state}, `;
    if (pincode) readableAddress += `${pincode}, `;
    if (country) readableAddress += `${country}, `;
  
    // Remove the trailing comma and space
    readableAddress = readableAddress.replace(/,\s*$/, '');
    return readableAddress;
  }
  