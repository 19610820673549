import { createApiFunction } from "../../services/apiServices"

export const BASE_URL = 'https://appswatt.com/intelelectric/api'

// add api without base url BASE_URL
const ApiClient = {
    login: createApiFunction('/login'),
    forgetPassword: createApiFunction('/forgetPassword'),
    resetPassword: createApiFunction('/resetPassword'),
    logout: createApiFunction('/logout'),
    team: createApiFunction('/team'),
    client: createApiFunction('/client'),
    service: createApiFunction('/service'),
    serviceDetail: createApiFunction('/service-details'),
    serviceCategory: createApiFunction('/industry'),
    serviceSubCategory: createApiFunction('/servicesubcat'),
    serviceSubCategoryDetail: createApiFunction('/servicesubcat-details'),
    materialCategory: createApiFunction('/metarialcategory'),
    material: createApiFunction('/metarial'),
    materialUpdate: createApiFunction('/metarial-update'),
    materialDetail: createApiFunction('/metarial-details'),
    job: createApiFunction('/job'),
    allJobs: createApiFunction('/all-jobs'),
    address: createApiFunction('/address'),
    email: createApiFunction('/email'),
    phone: createApiFunction('/phone'),
    unit: createApiFunction('/unit'),
    jobstatus: createApiFunction('/jobstatus'),
    quote: createApiFunction('/quote'),
    jobNote: createApiFunction('/note'),
    uploadFiles: BASE_URL + '/files',
    files:createApiFunction('/files'),
    timeSheet:createApiFunction('/sheet'),
    todo:createApiFunction('/todo'),
    changePassword:createApiFunction('/changePassword'),
    adminProfile:createApiFunction('/profile/1'),
    adminDashboard:createApiFunction('/dashboard'),
    tax:createApiFunction('/tax'),
    invoice:createApiFunction('/invoice'),
    archives:createApiFunction('/archives'),


    timeSheetJobWise:createApiFunction('/sheet-job'),
    timeSheetTeamWise:createApiFunction('/sheet-team'),
    timeSheetDateWise:createApiFunction('/sheet-date'),
    liveTrackTeam:createApiFunction('/teamtrack'),
















    publicJobDetail:createApiFunction('/jobdetails'),
    publicNoteDetail:createApiFunction('/notedetails'),
    publicQuoteDetail:createApiFunction('/quotedetails'),




}



export default ApiClient;