import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLeftIcon, setPageTitle } from "../../../redux/navbarSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { NavBar } from "../../../components";
import { useFormik } from "formik";
import {
  emailValidationSchema,
  passwordValidationSchema,
  phoneValidationSchema,
  postalCodeValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import { Box, Button, Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CustomAutoComplete from "../../../components/InputFields/SelectFields/CustomAutoComplete";
import { CANADA_CITIES, CANADA_STATES } from "../../../constant/CanadaStateCities";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { MapModal, usePlacesAutocomplete } from "../../../utils/GoogleMap";
import GoogleAutoComplete from "../../../components/InputFields/SelectFields/GoogleAutoComplete";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate } from "react-router-dom";

export function TeamAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      street: "",
      state: "",
      city: "",
      email: "",
      state: '',
      pincode: '',
      lat: null,
      lang: null,
      place_id: null,
      role: "2"
    },
    validationSchema: Yup.object().shape({
      email: emailValidationSchema,
      first_name: stringValidationSchema,
      last_name: stringValidationSchema,
      phone: phoneValidationSchema,
      state: stringValidationSchema,
      city: stringValidationSchema,
      street: stringValidationSchema,
      pincode: postalCodeValidationSchema
    }),
    onSubmit: (values, { resetForm }) => { CreateTeam(values, resetForm) }
  });


  const CreateTeam = (data, resetForm) => {
    ApiClient.team.post(data).then((res) => {
      if (res) {
        CustomToaster.success(res?.message)
        resetForm();
        navigate(-1);
      }


    }).catch((err) => {
      console.log(err);
    })
  }



  const selectedState = formik.values.state;
  useEffect(() => {
    formik.values.city = ""
  }, [selectedState])





  return (
    <>
      <NavBar title="Create Team Member" goBack isFilter={false} isSearch={false} />

      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          mt: 1,
          pb: 10,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Grid container columnSpacing={{ md: 1, lg: 2 }} justifyContent="center">
          <Grid item xs={12} md={6} >
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("first_name")}
              margin="normal"
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("last_name")}
              margin="normal"
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("phone")}
              margin="normal"
              label="Phone"
            />

          </Grid>
          <Grid item xs={12} md={6} >
            <GoogleAutoComplete
              form={formik}
              field={formik.getFieldProps("street")}
              margin="normal"
              label="Street"
              googleAddress={(formattedAddress) => {
                const { formatted, lat, lang, place_id } = formattedAddress;
                // formik.setFieldValue('street', formatted.street || '');
                formik.setFieldValue('state', formatted.state || '');
                formik.setFieldValue('city', formatted.city || '');
                formik.setFieldValue('pincode', formatted.postalCode || '');
                formik.setFieldValue('lat', lat || '');
                formik.setFieldValue('lang', lang || '');
                formik.setFieldValue('place_id', place_id || '');
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomAutoComplete
              form={formik}
              field={formik.getFieldProps("state")}
              data={CANADA_STATES}
              label="State"
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomAutoComplete
              form={formik}
              field={formik.getFieldProps("city")}
              data={selectedState ? CANADA_CITIES(selectedState) : []}
              disabled={!selectedState || !CANADA_CITIES(selectedState).length}
              label="City"
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("pincode")}
              margin="normal"
              label="Postal Code"
              placeholder="M2P 1R7"
              inputStyle={{ textTransform: 'uppercase' }}
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomTextField
              form={formik}
              field={formik.getFieldProps("email")}
              margin="normal"
              label="Email Address"
            />
          </Grid>

          <Grid item xs={12} width={'100%'}>
            <CustomButton label={"Save"} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
