import React, { useEffect, useState } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ClientSelectRadioCard,
  JobDetailContainerListCard,
  NavBar,
  NoResultFound,
  RadioListFullPageWrapper,
  SelectAddressRadioCard,
} from "../../../components";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  arrayValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { useNavigate } from "react-router-dom";
import { CustomFileInput } from "../../../components/InputFields/CustomFileInput/CustomFileInput";
import { FileUploadRounded, UploadFileOutlined } from "@mui/icons-material";
import { ThemeColors } from "../../../constant/ThemeColors";
import { DateTimeDrawer } from "../../../components/InputFields/DateTime";
import { convertToFormData } from "../../../utils/ConvertToFormData";
import { useSelector } from "react-redux";
import {
  transformMaterialArray,
  transformServiceArray,
} from "../../../utils/transformMaterialService";

function QuoteAdd() {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  const [material, setMaterial] = useState([]);

  const taxList = useSelector((state) => state?.constant?.taxList);

  const addTax = () => {
    let rate = taxList[0] ? taxList[0]?.rate : 0;
    let tax = (Number(subTotal) * Number(rate)) / 100;
    return {
      total: Number(subTotal) + (Number(subTotal) * Number(rate)) / 100,
      tax,
    };
  };

  const [selectValue, setSelectValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      quote_no:"",
      address_id: "",
      client_id: "",
      notes: "",
      materials: [],
      services: [],
      term_condi_text: "",
      term_condi_file: [],
      schedule_date: new Date(),
      schedule_email: [],
      schedule_phone: [],
      quote_status: 2,
    },
    validationSchema: Yup.object().shape({
      address_id: numericValidationSchema,
      client_id: numericValidationSchema,
      name: stringValidationSchema,
      quote_no: stringValidationSchema,
      services: arrayValidationSchema,
      term_condi_text: Yup.string().test(
        'has-data',
        'Either Terms & Conditions Text or File is Required',
        function (value) {
          const { term_condi_file } = this.parent;
          return !!value || (term_condi_file && term_condi_file.length > 0);
        }
      ),
      term_condi_file: Yup.array(),
    }),

    onSubmit: (values, { resetForm }) => {
      createQuotes(values, resetForm);
    },
  });

  const createQuotes = (data, resetForm) => {
    ApiClient.quote
      .post({ ...data, quote_tax_rate: taxList[0]?.rate || 0 })
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          resetForm();
          setMaterial([]);
          setSelectValue("");
          setServices([]);
          navigate(-1);
        }
      });
  };

  const [isOpen, setIsOpen] = useState(false);

  const [subTotal, setSubTotal] = useState(0);
  const calculateSubTotal = () => {
    let subTotal = 0;
    for (const mat of formik.values?.materials) {
      const unitCount = parseInt(mat?.unit_count || 0);
      console.log(mat);
      const purchasePrice = parseInt(mat?.unit_price);
      subTotal += unitCount * purchasePrice;
    }
    for (const service of formik.values?.services) {
      const unitCount = parseInt(service?.unit_count || 1);
      const purchasePrice = parseInt(service?.unit_price);
      subTotal += unitCount * purchasePrice;
    }
    setSubTotal(subTotal);
  };

  useEffect(() => {
    formik.setFieldValue("services", transformServiceArray(services));
    formik.setFieldValue("materials", transformMaterialArray(material));
    formik.values.schedule_email = [];
    formik.values.schedule_phone = [];
    formik.setFieldValue("address_id", selectedAddress?.id);
    formik.setFieldValue("client_id", selectValue?.id);
  }, [material, services, selectValue, selectedAddress]);

  useEffect(() => {
    calculateSubTotal();
  }, [formik]);

  // radio button form data
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => setDialogOpen(false);

  const handleEmailSelection = (email) => {
    const allEmails = formik?.values?.schedule_email;
    const isExists = formik?.values?.schedule_email?.includes(email);
    if (!isExists) {
      formik.setFieldValue("schedule_email", [...allEmails, email]);
    } else {
      formik.setFieldValue(
        "schedule_email",
        allEmails?.filter((res) => res !== email)
      );
    }
  };

  return (
    <>
      <NavBar title="Add Quote" goBack noFilter noSearch />
      <Box sx={{ p: 1 }}>
        <ClientSelectRadioCard onSubmit={setSelectValue} />
        {selectValue?.id ? (
          <>
            <SelectAddressRadioCard
              data={selectValue}
              onSubmit={setSelectedAddress}
            />
            <Divider />

            <Box mt={4} mb={2}>
              <Typography variant="subtitle2">Quote No.: *</Typography>
              <CustomTextField
                form={formik}
                field={formik.getFieldProps("quote_no")}
              />
            </Box>
            <Box mt={4} mb={2}>
              <Typography variant="subtitle2">Name: *</Typography>
              <CustomTextField
                form={formik}
                field={formik.getFieldProps("name")}
              />
            </Box>

            <JobDetailContainerListCard
              editable
              listData={services}
              setListData={setServices}
              hideListButton
              cardButton="service"
              cardTitle="Services *"
            />
             <Typography
              variant="subtitle2"
              component={"span"}
              sx={{ color: "red" }}
            >
              {formik?.touched.services && formik?.errors?.services}
            </Typography>
            <JobDetailContainerListCard
              cardTitle="Material"
              editable
              listData={material}
              setListData={setMaterial}
              hideListButton
              cardButton="material"
            />

            <Box
              sx={{
                my: 3,
                border: "1px solid #B7D4FF",
                borderBottom: "3px solid #B7D4FF",
                padding: "15px",
                // backgroundColor: "#F8F9FC",
                marginTop: "30px",
                borderRadius: "7px",
              }}
            >
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Subtotal:{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${subTotal?.toFixed(2)}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Tax: {taxList[0] ? `${taxList[0]?.rate}%` : ""}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${addTax()?.tax?.toFixed(2)}
                </Typography>
              </Typography>
              <Divider p={1} />
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                component="p"
              >
                Grand Total:{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    // color: "#000",
                  }}
                >
                  ${addTax()?.total?.toFixed(2)}
                </Typography>
              </Typography>
            </Box>

            <Box mt={4} mb={2}>
              <Typography variant="subtitle2">Terms & Conditions:* </Typography>
              <Box
                mt={0.5}
                mb={2}
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <Box sx={{ width: "80%" }}>
                  <CustomTextField
                    form={formik}
                    field={formik.getFieldProps("term_condi_text")}
                  />
                </Box>
                <Box sx={{ width: "20%" }}>
                  <CustomFileInput
                    form={formik}
                    type="file"
                    customIcon={
                      <Box
                        sx={{
                          px: 2,
                          border: 1,
                          borderColor: ThemeColors.borderColor,
                          borderRadius: 1,
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <UploadFileOutlined htmlColor={ThemeColors.iconColor} />
                        <Typography noWrap sx={{}}>
                          {formik.values?.term_condi_file?.length &&
                          formik.values?.term_condi_file?.length > 1
                            ? `${formik.values?.term_condi_file?.length} files Selected`
                            : formik?.values?.term_condi_file[0]?.filename}
                        </Typography>
                      </Box>
                    }
                    field={formik.getFieldProps("term_condi_file")}
                  />
                </Box>
              </Box>
            </Box>

            <Box my={4} pb={10}>
              <Typography variant="subtitle2">Notes: </Typography>

              <CustomTextField
                multiline
                form={formik}
                field={formik.getFieldProps("notes")}
              />
            </Box>

            <Box
              sx={{
                position: "fixed",
                zIndex: 100,
                bottom: 20,
                left: 0,
                right: 0,
                maxWidth: "400px",
                width: "90%",
                display: "flex",
                py: 1,
                margin: "auto",
                fontSize: 18,
              }}
              gap={2}
            >
              <Button
                variant="outlined"
                sx={{ py: 1, fontWeight: "500" }}
                fullWidth
                onClick={() => {
                  formik
                    ?.validateForm()
                    ?.then((res) => {
                      formik?.setErrors(res);
                      formik?.setTouched(res);
                      if (!Object?.keys(res)?.length) {
                        formik.setFieldValue("schedule_date", new Date());
                        setDialogOpen(true);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Send
              </Button>
              <Button
                variant="contained"
                sx={{ py: 1, fontWeight: "500" }}
                fullWidth
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Schedule
              </Button>
            </Box>

            <DateTimeDrawer
              isOpen={isOpen}
              onClose={(value, isTrue) => {
                setIsOpen(false);
                if (isTrue) {
                  formik.setFieldValue("schedule_date", value);
                  formik.setFieldValue("quote_status", 1);
                  formik
                    ?.validateForm()
                    ?.then((res) => {
                      formik?.setErrors(res);
                      formik?.setTouched(res);
                      if (!Object.keys(res)) {
                        setDialogOpen(true);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
            />

            <RadioListFullPageWrapper
              label="Choose Email, Phone or both"
              open={dialogOpen}
              handleClose={handleClose}
            >
              {selectValue?.AdditionEmails?.length ? (
                selectValue?.AdditionEmails.map((item) => {
                  return (
                    <Typography
                      sx={{
                        mb: 1,
                        borderRadius: 1,
                        border: 0.1,
                        borderColor: ThemeColors.borderColor,
                        ":hover": {
                          boxShadow: ThemeColors.hoverBoxShadow,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        handleEmailSelection(item.email);
                      }}
                    >
                      <ListItem
                        components={"div"}
                        // onClick={onClick}
                        secondaryAction={
                          <IconButton edge="end" aria-label="detail-page">
                            {formik.values?.schedule_email?.includes(
                              item?.email
                            ) ? (
                              <>
                                <CheckCircleIcon htmlColor="#1976D2" />
                              </>
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </IconButton>
                        }
                      >
                        <ListItemText primary={item.email} />
                      </ListItem>

                      <Divider
                        sx={{
                          borderRadius: 4,
                        }}
                      />
                    </Typography>
                  );
                })
              ) : (
                <NoResultFound />
              )}
              {formik?.getFieldProps("schedule_date")?.value >= new Date() ? (
                <CustomButton
                  onClick={formik.handleSubmit}
                  label={"Schedule"}
                />
              ) : (
                <CustomButton
                  onClick={formik.handleSubmit}
                  label={"Send Quote"}
                />
              )}
            </RadioListFullPageWrapper>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}

export { QuoteAdd };
