import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    BottomDrawer,
    ConfirmationDialog,
    CustomSwipableTabs,
    NavBar,
} from "../../../components";
import ApiClient from "../../../constant/API_URLS";
import { Box, Grid, ListItemButton, Typography } from "@mui/material";
import {
    LabelTextAddressCard,
    LabelTextCard,
    LabelTextEmailCard,
    LabelTextPhoneCard,
} from "../../../components/Cards/DisplayCards";
import {
    AddAddress,
    AddEmail,
    AddPhone,
} from "../../../components/AddAdditionalContact";
import CustomToaster from "../../../utils/CustomToaster";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { UpdateText } from "../../../components/UpdateDetails/UpdateText";
import { ProfileDetail } from "./Detail";
import { Job } from "./Job";
import { Quote } from "./Quote";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails/DeleteConfirmationDialog";
import { Delete, Edit } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import { userActiveInActive } from "../../../constant/GlobalVariable";

export const ClientProfile = () => {
    const { userId } = useParams();
    const [profileDetail, setProfileDetail] = useState("");
    const navigate = useNavigate();

    const fetchDetail = () => {
        setProfileDetail("");
        ApiClient.client
            .get(userId)
            .then((res) => {
                setProfileDetail(res?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deleteUser = (data, handleClose) => {
        ApiClient.client
            .delete(userId)
            .then((res) => {
                if (res) {
                    CustomToaster.success(res?.message);
                    handleClose();
                    navigate(-1);
                }
            })
            .catch((err) => {
                CustomToaster.error(err?.message);
            });
    };

    const tabData = [
        { label: "Profile", value: <ProfileDetail /> },
        { label: "Jobs", value: <Job /> },
        { label: "Quote", value: <Quote /> },
    ];

    useEffect(() => {
        if (userId) {
            fetchDetail();
        }
    }, []);

    const activeDeactiveUser = (data, handleClose) => {
        ApiClient.client.put(userId, {
            is_active: data === userActiveInActive.active ? userActiveInActive.inactive : userActiveInActive.active
        }).then(res => {
            if (res) {
                fetchDetail();
                handleClose()
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const actions = [
        <>
            <ConfirmationDialog
                onSubmit={(_, handleClose) => {
                    activeDeactiveUser(profileDetail?.is_active, handleClose);
                }}
            >
                <ListItemButton
                    sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
                >
                    <TuneIcon />
                    <Typography component={"span"}>{profileDetail?.is_active ? 'Active' : 'Deactive'}</Typography>
                </ListItemButton>
            </ConfirmationDialog>
        </>,
        <>
            <DeleteConfirmationDialog onSubmit={deleteUser}>
                <ListItemButton
                    sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
                >
                    <Delete />
                    <Typography component={"span"}>Delete</Typography>
                </ListItemButton>
            </DeleteConfirmationDialog>
        </>,
    ];

    return (
        <>
            <NavBar
                onRight={<BottomDrawer title="Action" data={actions} />}
                title={profileDetail?.first_name || "Client Profile"}
                goBack
                isFilter={false}
                isSearch={false}
            />
            <CustomSwipableTabs tabsData={tabData} />
        </>
    );
};
