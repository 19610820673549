import { Box, Typography } from "@mui/material";
import React from "react";
import { UnScheduledJobCard } from "../../../../components/Cards/DisplayCards";
import { NoResultFound } from "../../../../components";

function UnScheduled({ jobs }) {
  if (!jobs?.length) {
    return <NoResultFound>No UnScheduled Job Found</NoResultFound>;
  }

  return (
    <>

    <Typography>
      Total Draft :{" "}
      <Typography component={"strong"} style={{ fontWeight: "700" }}>
        {jobs.length}
      </Typography>
    </Typography>

    <Box
      py={2}
      overflow={"hidden"}
      my={1}
      display={"flex"}
      flexWrap={"wrap"}
      gap={2}
    >
     
      {jobs?.map((item, index) => (
        <UnScheduledJobCard key={index} job={item} />
      ))}
    </Box>
    </>
  );
}

export default UnScheduled;
