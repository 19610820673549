import React, { useEffect, useState } from "react";
import { CustomSwipableTabs, NavBar, NoResultFound } from "../../components";
import { Sent } from "./QuoteList/Sent";
import { Scheduled } from "./QuoteList/Scheduled";
import { Fab } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ApiClient from "../../constant/API_URLS";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";
import { useSelector } from "react-redux";
import { quoteStatus } from "../../constant/GlobalVariable";

export const Quote = () => {
  const navigate = useNavigate();


  const tabData = [
    {
      label: "Scheduled",
      value: <Scheduled />,
    },
    {
      label: "Sent",
      value: <Sent />,
    },
  ];


  const [currentTab, setCurrentTab] = useState(0)
  const [searchParams] = useSearchParams();
  const tab = searchParams?.get("tab");
  useEffect(() => {
    if (tab) {
      if (tab?.toLowerCase() == 'sent') {
        setCurrentTab(1)
      }
    }
  }, [tab])



  return (
    <>
      <NavBar title="Quote" noFilter />


      <CustomSwipableTabs tabsData={tabData} currentTab={currentTab} />

      <Fab
        color="primary"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          transition: "ease",
        }}
        onClick={() => {
          navigate("add");
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
