import {
  Avatar,
  Box,
  IconButton,
  ListItem as ItemList,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  numericValidationSchema,
  stringValidationSchema,
} from "../../../../constant/validationSchema";
import { CustomTextField } from "../../../InputFields/TextFields";
import { Delete } from "@mui/icons-material";

const EditableServiceCard = ({ service, index, onUpdate }) => {
  const [editMode, setEditMode] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: service.name,
      unit_price: service.price,
      unit_count: "1",
      ...service,
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      unit_price: numericValidationSchema,
      unit_count: numericValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      // createService(values, resetForm);
    },
  });

  const [editName, setEditName] = React.useState(true);
  const [editPrice, setEditPrice] = React.useState(true);
  const [editQty, setEditQty] = React.useState(true);

  const validateField = async () => {
    onUpdate(index, formik?.values);
  }
  useEffect(() => {
    validateField()

  }, [formik.values]);

  const handleDelete = () => {
    setEditMode(true);
    onUpdate(index, null);
  };

  useEffect(() => {
    if (editMode) {
      formik?.setValues({
        name: service.name,
        unit_price: service.price,
        unit_count: "1",
        ...service,
      });
      setEditMode(false);
    }
  }, [service]);

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          mb: 0.6,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <CustomTextField
          fullWidth={false}
          autoFocus={editName}
          onBlur={() => setEditName(false)}
          form={formik}
          field={formik.getFieldProps("name")}
          variant="standard"
        />

        {/* {editName || !formik?.values?.name ? (
          <CustomTextField
            fullWidth={false}
            autoFocus={editName}
            onBlur={() => setEditName(false)}
            form={formik}
            field={formik.getFieldProps("name")}
            variant="standard"
          />
        ) : (
          <>
            {" "}
            <Typography onClick={() => setEditName(true)} variant="h6">
              {formik?.values?.name}
            </Typography>
          </>
        )} */}

        <Delete onClick={handleDelete} htmlColor="red" fontSize="14" />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="div"
          sx={{ display: "flex", gap: 2, alignItems: "center" }}
        >
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <span> Qty: </span>

            <Typography
              onClick={() => setEditQty(true)}
              component={"p"}
              sx={{ maxWidth: 56, mx: 0.5 }}
            >
              <CustomTextField
                fullWidth={false}
                autoFocus={editQty}
                onBlur={() => setEditQty(false)}
                form={formik}
                field={formik.getFieldProps("unit_count")}
                variant="standard"
                type="number"
              />

              {/* {editQty || !formik?.values?.unit_count ? (
                <CustomTextField
                  fullWidth={false}
                  autoFocus={editQty}
                  onBlur={() => setEditQty(false)}
                  form={formik}
                  field={formik.getFieldProps("unit_count")}
                  variant="standard"
                />
              ) : (
                <>
                  {" "}
                  <span>{formik?.values?.unit_count}</span>
                </>
              )} */}
            </Typography>

            <Typography
              component={"span"}
              sx={{ maxWidth: 40, marginLeft: 1 }}
            ></Typography>
          </Typography>
          <Typography
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              sx={{
                mx: 1,
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
              component="strong"
            >
              $
              <Typography component={"span"} sx={{ maxWidth: 72, mx: 0.5 }}>
                <CustomTextField
                  fullWidth={false}
                  autoFocus={editPrice}
                  onBlur={() => setEditPrice(false)}
                  form={formik}
                  field={formik.getFieldProps("unit_price")}
                  variant="standard"
                  type="number"
                />

                {/* {editPrice || !formik?.values?.unit_price ? (
                  <CustomTextField
                    fullWidth={false}
                    autoFocus={editPrice}
                    onBlur={() => setEditPrice(false)}
                    form={formik}
                    field={formik.getFieldProps("unit_price")}
                    variant="standard"
                  />
                ) : (
                  <>
                    {" "}
                    <span onClick={() => setEditPrice(true)}>
                      {formik?.values?.unit_price}
                    </span>
                  </>
                )} */}
              </Typography>
              {"  "}
            </Typography>
            each
          </Typography>
        </Typography>

        <Typography variant="h6">
          ${formik?.values?.unit_price * formik?.values?.unit_count}
        </Typography>
      </Box>
    </Box>
  );
};

const EditableMaterialCard = ({ material, index, onUpdate }) => {
  const formik = useFormik({
    initialValues: {
      name: material.name,
      description: material.description,
      unit_id: material.unit_id,
      unit_price: material.sales_price,
      unit_count: 1,
      ...material,
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      unit_price: numericValidationSchema,
      unit_count: numericValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      // createService(values, resetForm);
    },
  });

  const [editName, setEditName] = React.useState("");
  const [editPrice, setEditPrice] = React.useState("");
  const [editQty, setEditQty] = React.useState("");



  const validateField = async () => {
    // let value = await formik.validateForm();
    // console.log(value);
    onUpdate(index, formik?.values);
    // if (!(Object.keys(value)?.length)) {
    //   onUpdate(index, formik?.values);
    // }
  }
  useEffect(() => {
    validateField()
  }, [formik.values]);



  const handleDelete = () => {
    onUpdate(index, null);
    setEditMode(true);
  };

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (editMode) {
      setEditMode(false);
      formik?.setValues({
        name: material.name,
        description: material.description,
        unit_id: material.unit_id,
        unit_price: material.sales_price,
        unit_count: "1",
        ...material,
      });

    }
  }, [material]);

  return (
    <Box sx={{ p: 1 }}>
      <Box
        component={'form'}
        sx={{
          mb: 0.6,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <CustomTextField
          fullWidth={false}
          autoFocus={editName}
          onBlur={() => setEditName(false)}
          form={formik}
          field={formik.getFieldProps("name")}
          variant="standard"
        />
        {/* {editName || !formik?.values?.name ? (
          <CustomTextField
            fullWidth={false}
            autoFocus={editName}
            onBlur={() => setEditName(false)}
            form={formik}
            field={formik.getFieldProps("name")}
            variant="standard"
          />
        ) : (
          <>
            {" "}
            <Typography onClick={() => setEditName(true)} variant="h6">
              {formik?.values?.name}
            </Typography>
          </>
        )} */}

        <Delete onClick={handleDelete} htmlColor="red" fontSize="14" />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="div"
          sx={{ display: "flex", gap: 2, alignItems: "center" }}
        >
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <span> Qty: </span>

            <Typography
              onClick={() => setEditQty(true)}
              component={"p"}
              sx={{ maxWidth: 56, mx: 0.5 }}
            >
              <CustomTextField
                fullWidth={false}
                autoFocus={editQty}
                onBlur={() => setEditQty(false)}
                form={formik}
                field={formik.getFieldProps("unit_count")}
                variant="standard"
                type="number"
              />
              {/* {editQty || !formik?.values?.unit_count ? (
                <CustomTextField
                  fullWidth={false}
                  autoFocus={editQty}
                  onBlur={() => setEditQty(false)}
                  form={formik}
                  field={formik.getFieldProps("unit_count")}
                  variant="standard"
                />
              ) : (
                <>
                  {" "}
                  <span>{formik?.values?.unit_count}</span>
                </>
              )} */}
            </Typography>

            <Typography
              component={"span"}
              sx={{ maxWidth: 40, marginLeft: 1 }}
            ></Typography>
          </Typography>
          <Typography
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              sx={{
                mx: 1,
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
              component="strong"
            >
              $
              <Typography component={"span"} sx={{ maxWidth: 50, mx: 0.5 }}>
                <CustomTextField
                  fullWidth={false}
                  autoFocus={editPrice}
                  onBlur={() => setEditPrice(false)}
                  form={formik}
                  field={formik.getFieldProps("unit_price")}
                  variant="standard"
                  type="number"
                />
                {/* {editPrice || !formik?.values?.unit_price ? (
                  <CustomTextField
                    fullWidth={false}
                    autoFocus={editPrice}
                    onBlur={() => setEditPrice(false)}
                    form={formik}
                    field={formik.getFieldProps("unit_price")}
                    variant="standard"
                  />
                ) : (
                  <>
                    {" "}
                    <span onClick={() => setEditPrice(true)}>
                      {formik?.values?.unit_price}
                    </span>
                  </>
                )} */}
              </Typography>
              {"  "}
            </Typography>
            each
          </Typography>
        </Typography>

        <Typography variant="h6">
          ${formik?.values?.unit_price * formik?.values?.unit_count}
        </Typography>
      </Box>
    </Box>
  );
};

export function ListItem({
  cardButton = "service",
  hideListButton,
  data,
  editable = false,
  onUpdate,
  index,
}) {
  return (
    <>
      {editable ? (
        <>
          {cardButton == "material" ? (
            <EditableMaterialCard
              index={index}
              onUpdate={onUpdate}
              material={data}
            />
          ) : (
            <EditableServiceCard
              index={index}
              onUpdate={onUpdate}
              service={data}
            />
          )}
        </>
      ) : (
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              mb: 0.4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
              {data?.name}
            </Typography>

            {hideListButton ? (
              ""
            ) : (
              <IconButton
                size="small"
                sx={{
                  background: "rgba(0,0,0,0.09)",
                  p: 0.2,
                  ":hover": {
                    background: "rgba(0,0,0,0.2)",
                  },
                }}
              >
                <MoreHorizIcon />
              </IconButton>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography
              variant="div"
              sx={{ display: "flex", gap: 2, alignItems: "center" }}
            >
              <Typography component="span">
                Qty:{" "}
                <Typography
                  sx={{ fontSize: 17, fontWeight: "600" }}
                  component="strong"
                >
                  {data?.unit_count}
                </Typography>
              </Typography>
              <Typography component="span">
                <Typography sx={{ fontWeight: "600" }} component="strong">
                  ${data?.unit_price}.00{"  "}
                </Typography>
                each
              </Typography>
            </Typography>

            <Typography variant="h6">
              ${data?.unit_price * data?.unit_count}.00
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
