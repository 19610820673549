export default function DisplayUserAddress(userObject) {
    const { street, city, state, country, pincode } = userObject;
    let addressString = '';
  
    if (street) {
      addressString += street + ', ';
    }
  
    if (city) {
      addressString += city + ', ';
    }
  
    if (state) {
      addressString += state + ', ';
    }
  
    if (country) {
      addressString += country + ', ';
    }
  
    if (pincode) {
      addressString += pincode;
    }
  
    return addressString;
  }