import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ClientAdd, ClientProfile, Clients } from '../../pages/Clients'

export const ClientsRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Clients />} />
                <Route path='profile/:userId' element={<ClientProfile />} />
                <Route path='add' element={<ClientAdd />} />
            </Routes>
        </>
    )
}
