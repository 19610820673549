import { Box, Container, Divider, List, Typography } from "@mui/material";
import React, { useEffect, useId, useState } from "react";
import {
  AddMaterialActionButton,
  AddServiceActionButton,
} from "../../../ActionButtons";
import { ListItem } from "./List";

const cardButton = {
  BUTTON_ONE: "Button One",
  BUTTON_TWO: "Button Two",
  BUTTON_THREE: "Button Three",
};

export function JobDetailContainerListCard({
  jobId = 0,
  cardTitle = "Services",
  cardButton = "service || material",
  hideCardButton = false,
  hideListButton = false,
  listData = [1, 1],
  setListData = false,
  editable = false,
  // onSubmit = false,
  // onUpdate = false,
}) {
  const [items, setitems] = useState(false);

  const onSubmit = (newItem) => {
    const dataArray = [...items, ...Object.values(newItem)];
    const uid = Math.floor(Math.random() * 100000000);
    const itemsWithUniqueIds = dataArray.map((item, index) => ({
      ...item,
      id: uid + index,
    }));
    setitems([...itemsWithUniqueIds]);
  };

  const onUpdate = (id, item) => {
    if (!item) {
      setitems((prevItems) => prevItems.filter((item) => item.id !== id));
    } else {
      setitems((prevState) => {
        let newService = [];
        if (prevState) {
          newService = prevState;
        }
        // Find the index of the item with the given id
        const index = newService.findIndex((item) => item.id === id);
        if (index !== -1) {
          // If the item with the id exists, update it
          newService[index] = item;
        }
        return [...newService];
      });
    }
  };

  useEffect(() => {
    if (listData && !items && items !== listData) {
      const dataArray = [...listData];
      const uid = Math.floor(Math.random() * 100000000);
      const itemsWithUniqueIds = dataArray.map((item, index) => ({
        ...item,
        id: uid + index,
      }));
      setitems([...itemsWithUniqueIds]);

    }
  }, [listData]);

  useEffect(() => {
    if (setListData && items) {
      setListData(items);
    }
  }, [items]);

  return (
    <>
      <Box sx={{ overflow: "hidden", width: "99.9%", p: 0, mb: 4, mt: 4 }}>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography sx={{ fontWeight: "500" }} variant="h6">
            {cardTitle}
          </Typography>
          {hideCardButton ? (
            ""
          ) : (
            <>
              {cardButton === "service" ? (
                <AddServiceActionButton onSubmit={onSubmit} id={jobId} />
              ) : (
                <>
                  {cardButton === "material" ? (
                    <AddMaterialActionButton onSubmit={onSubmit} id={jobId} />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        {listData?.length ? (
          <Box
            sx={{
              mt: 1,
              border: 1,
              borderColor: "#B7D4FF",
              borderRadius: 1.6,
              px: 1,
            }}
          >
            {listData?.length ? (
              <>
                <List>
                  {listData?.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        cardButton={cardButton}
                        key={index}
                        index={item?.id}
                        onUpdate={onUpdate}
                        editable={editable}
                        data={item}
                        hideListButton={hideListButton}
                      />
                      {index !== listData?.length - 1 ? <Divider /> : ""}
                    </React.Fragment>
                  ))}
                </List>
              </>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
