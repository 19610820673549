import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { Menu } from "@mui/icons-material";
import { ThemeColors } from "../../../constant/ThemeColors";

export const LogisticsFleetToolbar = (props) => {
  const { onDrawerOpen } = props;

  return (
    <Box
      sx={{
        left: 0,
        p: 0,
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        width: '100%',
        zIndex:2
      }}
    >
      <Card
        sx={{
          p: 1,
          pointerEvents: "auto",
          background:ThemeColors.primary
        }}
      >
        <IconButton onClick={onDrawerOpen}>
          <SvgIcon>
            <Menu htmlColor="white" />
          </SvgIcon>
        </IconButton>
      </Card>
    </Box>
  );
};
