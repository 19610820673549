import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuOpen: false,
  drawerWidth: '300',
  currentTab: 0,
  leftIcon: null,
  rightIcon: null,
  isSearch: false,
  pageTitle: 'Home',
  themeMode: 'light',
};

const itemsSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menuOpen = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload
    },
    setLeftIcon: (state, action) => {
      state.leftIcon = action.payload
    },
    setRightIcon: (state, action) => {
      state.rightIcon = action.payload
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload
    },
  },
});

export const { setMenu, setCurrentTab, setPageTitle, setThemeMode, setLeftIcon, setRightIcon, setIsSearch } = itemsSlice.actions;
export default itemsSlice.reducer;
