export default function CalculateTotalServiceMaterial(
  material = [],
  services = []
) {
  let subTotal = 0;

  for (const mat of material) {
    const unitCount = parseInt(mat?.qty || mat?.unit_count || 0);
    const purchasePrice = parseInt(mat.unit_price || 0);
    subTotal += unitCount * purchasePrice;
  }

  for (const service of services) {
    const unitCount = parseInt(service?.qty || service?.unit_count || 0);
    const purchasePrice = parseInt(service.unit_price ||  0);
    subTotal += unitCount * purchasePrice;
  }

  return subTotal;
}
