import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Payments } from '../../pages/Payments'

export const PaymentsRoutes = () => {
    return (
        <>
            <Routes>
                <Route index element={<Payments />} />
            </Routes>
        </>
    )
}
