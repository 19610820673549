import { Card, IconButton, Paper } from "@mui/material";
import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  LocationCityRounded,
  LocationOn,
  PersonPinCircle,
} from "@mui/icons-material";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  InfoWindowF,
} from "@react-google-maps/api";
import { NavBar } from "../../components";
import { LoadingEffect } from "../../components/SimmerEffects/LoadingEffect";

import { useCallback, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";

import { LogisticsFleetDrawer } from "./logistics/logistics-fleet-drawer";
import { LogisticsFleetList } from "./logistics/logistics-fleet-list";
import { LogisticsFleetToolbar } from "./logistics/logistics-fleet-toolbar";
import ApiClient from "../../constant/API_URLS";
import CustomToaster from "../../utils/CustomToaster";

const useVehicles = () => {
  return [
    {
      id: "VOL-653CD2",
      location: "New York, NY, USA",
      latitude: 40.74759625348667,
      longitude: -74.00422032706065,
      temp: "8°C",
      startedAt: "Sep 01, 7:53 AM",
      departedAt: "Sep 01, 8:02 AM",
      arrivedAt: "Sep 01, 8:18 AM",
    },
    {
      id: "VOL-653CD3",
      location: "New York, NY, USA",
      latitude: 40.75374208987527,
      longitude: -74.02878378307403,
      temp: "6°C",
      startedAt: "Sep 01, 8:21 AM",
      departedAt: "Sep 01, 8:36 AM",
      arrivedAt: "Sep 01, 9:54 AM",
    },
    {
      id: "VOL-653CD4",
      location: "New York, NY, USA",
      latitude: 40.765281069832085,
      longitude: -73.96392724511145,
      temp: "8°C",
      startedAt: "Sep 01, 6:34 AM",
      departedAt: "Sep 01, 7:41 AM",
      arrivedAt: "Sep 01, 9:20 AM",
    },
  ];
};

const Page = () => {
  const rootRef = useRef(null);
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [vehicles, setVehicles] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentVehicleId, setCurrentVehicleId] = useState(vehicles[0]?.id);

  const handleVehicleSelect = useCallback((vehicleId) => {
    setCurrentVehicleId(vehicleId);
  }, []);

  const handleVehicleDeselect = useCallback(() => {
    setCurrentVehicleId(undefined);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const fetchLiveData = () => {
    ApiClient.liveTrackTeam
      .get()
      ?.then((res) => {
        if (res) {
          setVehicles(res?.data);
        }
      })
      .catch((err) => {
        CustomToaster.error(err?.message);
      });
  };

  React.useEffect(() => {
    fetchLiveData();
    const intervalId = setInterval(() => {
      fetchLiveData();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Divider />
      <Box
        component="div"
        ref={rootRef}
        sx={{
          // marginTop:4,
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
          position: "relative",
          height: "100vh",
          // width: {lg:'98vw',}
        }}
      >
        {mdUp && (
          <Box
            sx={{
              borderRightColor: "divider",
              borderRightStyle: "solid",
              borderRightWidth: 1,
              flex: "0 0 400px",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h5">Team Members</Typography>
            </Box>
            <LogisticsFleetList
              currentVehicleId={currentVehicleId}
              onVehicleDeselect={handleVehicleDeselect}
              onVehicleSelect={handleVehicleSelect}
              vehicles={vehicles}
            />
          </Box>
        )}
        <Box
          sx={{
            flex: "1 1 auto",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {!mdUp && <LogisticsFleetToolbar onDrawerOpen={handleDrawerOpen} />}

          <LiveTracking
            onVehicleSelect={handleVehicleSelect}
            currentVehicleId={currentVehicleId}
            vehicles={vehicles}
          />
        </Box>
      </Box>
      {!mdUp && (
        <LogisticsFleetDrawer
          container={rootRef.current}
          onClose={handleDrawerClose}
          open={openDrawer}
        >
          <LogisticsFleetList
            currentVehicleId={currentVehicleId}
            onVehicleDeselect={handleVehicleDeselect}
            onVehicleSelect={handleVehicleSelect}
            vehicles={vehicles}
          />
        </LogisticsFleetDrawer>
      )}
    </>
  );
};

const LiveTracking = (props) => {
  const { onVehicleSelect, currentVehicleId, vehicles } = props;
  const latitude = 45.42839483504057;
  const longitude = -75.70800912738123;

  function getLocationForMember(memberId, data) {
    const member = data.find((item) => item.member_info.id === memberId);
    if (member && member.timesheet.length > 0) {
      const timesheetEntry = member.timesheet[0];
      const latitude = parseFloat(timesheetEntry.sheet_lat);
      const longitude = parseFloat(timesheetEntry.sheet_long);

      // Check if the conversion was successful
      if (!isNaN(latitude) && !isNaN(longitude)) {
        return {
          lat: latitude,
          lng: longitude,
        };
      } else {
        return {}; // Invalid latitude or longitude format
      }
    } else {
      return {}; // Member not found or no timesheet entry
    }
  }

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 10,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBd0PYN77escnzaFd5L_kjgZOMweC8jJo4",
  });

  const [activeMarker, setActiveMarker] = React.useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <>
      <NavBar title="Live Tracking" noFilter noSearch />

      {isLoaded ? (
        <GoogleMap
          center={{
            ...defaultProps?.center,
            ...getLocationForMember(currentVehicleId, vehicles),
          }}
          zoom={defaultProps.zoom}
          mapContainerStyle={{
            position: "absolute",
            height: "100vh",
            width: "90vw",
            left: 0,
            right: 0,
            margin: "auto",
          }}
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
        >
          {vehicles &&
            vehicles?.map((vehicle) => {
              const timeSheet = vehicle?.timesheet;
              return timeSheet?.length ? (
                <>
                  <Marker
                    position={{
                      lat: parseFloat(timeSheet[0]?.sheet_lat),
                      lng: parseFloat(timeSheet[0]?.sheet_long),
                    }}
                    onClick={() => {
                      onVehicleSelect(vehicle.member_info?.id);
                    }}
                  >
                    <Box
                      sx={{
                        height: 50,
                        width: 50,
                        "& img": {
                          height: "100%",
                        },
                      }}
                    >
                      <img src="/assets/car-marker.png" />
                    </Box>
                  </Marker>
                </>
              ) : (
                <></>
              );
            })}
        </GoogleMap>
      ) : (
        <>
          <LoadingEffect />
        </>
      )}
    </>
  );
};

export default Page;
