import React, { useEffect, useState } from "react";
import {
  BottomDrawer,
  ConfirmationDialog,
  CustomSwipableTabs,
  NavBar,
  NoResultFound,
} from "../../../components";
import { Box, ListItemButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { JobInfo } from "./JobInfo";
import { JobNotes } from "./JobNotes";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import {
  Delete,
  DoneAll,
  DoneOutline,
  Edit,
  GroupAdd,
  PostAdd,
} from "@mui/icons-material";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails/DeleteConfirmationDialog";
import CustomToaster from "../../../utils/CustomToaster";
import { Todo } from "./Todo";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { jobStatus } from "../../../constant/GlobalVariable";

export function JobDetail() {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const [jobDetail, setJobDetail] = useState("");


  const fetchJobDetail = () => {
    ApiClient.job.get("?job_id=" + jobId).then((res) => {
      if (res) {
        setJobDetail(res?.data[0]);
      }
    });
  };

  useEffect(() => {
    fetchJobDetail();
  }, []);

  const tabData = [
    { label: "INFO", value: <JobInfo jobDetail={jobDetail} /> },
    { label: "Notes", value: <JobNotes /> },
    { label: "Tasks", value: <Todo jobDetail={jobDetail} /> },
  ];

  const handleDelete = (_, handleCloseSuccess) => {
    ApiClient.job
      .delete(jobId)
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          handleCloseSuccess();
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markComplete = (_, handleCloseSuccess) => {
    ApiClient.job
      .put(jobId, { job_status: jobStatus?.COMPLETE, end_time: new Date() })
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          handleCloseSuccess();
          fetchJobDetail();
        }
      }).catch((err) => {
        console.log(err);
      });;
  };

  const actions = [
    <>
      {jobDetail?.job_status !== jobStatus?.COMPLETE ? (<ListItemButton
        onClick={() => {
          navigate("/jobs/update/" + jobId + "/full");
        }}
        sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
      >
        <Edit />
        <Typography component={"span"}>Edit</Typography>
      </ListItemButton>) : ("")}
    </>,
    <>
      <DeleteConfirmationDialog onSubmit={handleDelete}>
        <ListItemButton
          sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Delete />
          <Typography component={"span"}>Delete</Typography>
        </ListItemButton>
      </DeleteConfirmationDialog>
    </>,
    <>
      <ListItemButton
        onClick={() => {
          navigate("/jobs/add/?repost=" + jobId);
        }}
        sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
      >
        <PostAdd />
        <Typography component={"span"}>Re-Create Job</Typography>
      </ListItemButton>
    </>,
    <>
      {jobDetail?.job_status !== jobStatus?.DRAFT ? (
        <ListItemButton
          onClick={() => {
            navigate("/invoices/detail/" + jobId + "?invoiceCreate=" + jobId);
          }}
          sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
        >
          <ReceiptIcon />
          <Typography component={"span"}>Generate Invoice</Typography>
        </ListItemButton>
      ) : (
        ""
      )}
    </>,
    <>
      {jobDetail?.job_status == jobStatus?.INPROGRESS ? (
        <ConfirmationDialog
          title="Please Confirm"
          text="Please Confirm to Mark this Job Completed"
          onSubmit={(_, handleClose) => {
            markComplete(_, handleClose);
          }}
        >
          <ListItemButton
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <DoneAll />
            <Typography component={"span"}>Mark Complete</Typography>
          </ListItemButton>
        </ConfirmationDialog>
      ) : (
        ""
      )}
    </>,
  ];

  return (
    <>
      <NavBar
        title={"#" + jobId}
        goBack
        noFilter
        noSearch
        onRight={<BottomDrawer title="Action" data={actions} />}
      />

      {!jobDetail ? (
        <LoadingEffect />
      ) : (
        <CustomSwipableTabs tabsData={tabData} />
      )}
    </>
  );
}
