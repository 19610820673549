import React, { useEffect, useState } from "react";
import {
  BottomDrawer,
  CustomDialogWrapper,
  FloatingBottomRight,
  NavBar,
  NoResultFound,
} from "../../../components";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import InventoryIcon from "@mui/icons-material/Inventory";
import {
  Box,
  Button,
  Fab,
  Grid,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import {
  arrayValidationSchema,
  numericValidationSchema,
  stringValidationSchema,
} from "../../../constant/validationSchema";
import ApiClient from "../../../constant/API_URLS";
import CustomToaster from "../../../utils/CustomToaster";
import { CustomTextField } from "../../../components/InputFields/TextFields";
import CustomButton from "../../../components/ActionButtons/CustomButton";
import { CustomFileInput } from "../../../components/InputFields/CustomFileInput/CustomFileInput";
import { convertToFormData } from "../../../utils/ConvertToFormData";
import replaceAndRemoveTrailingSlash from "../../../utils/ReplaceAndRemoveTrailingSlash";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import {
  MaterialCard,
  TextArrowCard,
} from "../../../components/Cards/DisplayCards";
import { UpdateText } from "../../../components/UpdateDetails/UpdateText";
import { DeleteConfirmationDialog } from "../../../components/DeleteDetails";
import { Delete, Edit } from "@mui/icons-material";
import CustomUnitSelect from "../../../components/InputFields/SelectFields/CustomUnitSelect";

export const CreateItem = ({ id, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      quantity: "",
      unit_id: "",
      purchase_price: "",
      sales_price: "",
      description: "",
      metarial_image: [],
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      quantity: stringValidationSchema,
      unit_id: numericValidationSchema,
      purchase_price: numericValidationSchema,
      sales_price: numericValidationSchema,
      description: stringValidationSchema,
      metarial_image: arrayValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      createItem(values, resetForm);
    },
  });

  const createItem = (data, resetForm) => {
    ApiClient.material
      .post(data, id)
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          resetForm();
          onSubmit();
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        mt: 1,
        mx: 2,
        pb: 1,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container columnSpacing={{ md: 1, lg: 2 }} justifyContent="center">
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("name")}
            margin="normal"
            label="Material Name"
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("quantity")}
            margin="normal"
            label="Quantity "
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomUnitSelect form={formik} name={"unit_id"} />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("purchase_price")}
            margin="normal"
            label="Purchase Price "
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("sales_price")}
            margin="normal"
            label="Sales Price "
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("description")}
            margin="normal"
            label="Description"
          />
        </Grid>

        <Grid item xs={12}>
          <CustomFileInput
            form={formik}
            singleImage
            field={formik.getFieldProps("metarial_image")}
            margin="normal"
            type="file"
            multiple
            label="Material Image "
          />
        </Grid>

        <Grid
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "flex-end",
            gap: 1,
            mt: 3,
          }}
          item
          xs={12}
          width={"100%"}
        >
          <Button onClick={onClose} sx={{ color: "#A9A9A9" }}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Grid>

        <Grid sx={{ display: { md: "none" } }} item xs={12} width={"100%"}>
          <CustomButton label={"Save"} />
        </Grid>
      </Grid>
    </Box>
  );
};

const UpdateItem = ({ id, onClose, onSubmit, material }) => {
  const formik = useFormik({
    initialValues: {
      name: material?.name,
      quantity: material?.quantity,
      unit_id: material?.unit_id,
      purchase_price: material?.purchase_price,
      sales_price: material?.sales_price,
      description: material?.description,
      metarial_image: material?.metarial_image || [],
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
      quantity: stringValidationSchema,
      unit_id: numericValidationSchema,
      purchase_price: numericValidationSchema,
      sales_price: numericValidationSchema,
      description: stringValidationSchema,
      // metarial_image: arrayValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      updateItem(values, resetForm);
    },
  });

  const updateItem = (data, resetForm) => {
    ApiClient.materialUpdate
      .put(id, data)
      .then((res) => {
        if (res) {
          CustomToaster.success(res?.message);
          resetForm();
          onClose();
          onSubmit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        mt: 1,
        mx: 2,
        pb: 1,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container columnSpacing={{ md: 1, lg: 2 }} justifyContent="center">
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("name")}
            margin="normal"
            label="Material Name"
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("quantity")}
            margin="normal"
            label="Quantity "
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomUnitSelect form={formik} name={"unit_id"} />
          {/* <CustomTextField
            form={formik}
            field={formik.getFieldProps("unit_id")}
            margin="normal"
            label="Unit Id"
          /> */}
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("purchase_price")}
            margin="normal"
            label="Purchase Price "
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("sales_price")}
            margin="normal"
            label="Sales Price "
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("description")}
            margin="normal"
            label="Description"
          />
        </Grid>

        <Grid item xs={12}>
          <CustomFileInput
            form={formik}
            singleImage
            field={formik.getFieldProps("metarial_image")}
            margin="normal"
            type="file"
            // multiple
            label="Material Image "
          />
          <Typography>
            {!Array.isArray(formik.values.metarial_image) ? (
              <>
                <img
                  src={formik.values.metarial_image}
                  height={50}
                  width={50}
                />
              </>
            ) : (
              <></>
            )}
          </Typography>
        </Grid>

        <Grid
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "flex-end",
            gap: 1,
            mt: 3,
          }}
          item
          xs={12}
          width={"100%"}
        >
          <Button onClick={onClose} sx={{ color: "#A9A9A9" }}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Grid>

        <Grid sx={{ display: { md: "none" } }} item xs={12} width={"100%"}>
          <CustomButton label={"Save"} />
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateSubCategory = ({ id, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      parent_id: id,
    },
    validationSchema: Yup.object().shape({
      name: stringValidationSchema,
    }),
    onSubmit: (values, { resetForm }) => {
      createSubCat(values, resetForm);
    },
  });

  const createSubCat = (data, resetForm) => {
    ApiClient.materialCategory.post(data).then((res) => {
      CustomToaster.success(res?.message);
      resetForm();
      onSubmit();
      onClose();
    });
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        mt: 1,
        mx: 2,
        pb: 1,
      }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container columnSpacing={{ md: 1, lg: 2 }} justifyContent="center">
        <Grid item xs={12}>
          <CustomTextField
            form={formik}
            field={formik.getFieldProps("name")}
            margin="normal"
            label="Catergory"
          />
        </Grid>

        <Grid
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: "flex-end",
            gap: 1,
            mt: 3,
          }}
          item
          xs={12}
          width={"100%"}
        >
          <Button onClick={onClose} sx={{ color: "#A9A9A9" }}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Grid>

        <Grid sx={{ display: { md: "none" } }} item xs={12} width={"100%"}>
          <CustomButton label={"Save"} />
        </Grid>
      </Grid>
    </Box>
  );
};

const FloatingActionButton = ({ industryId, onSubmit, hidden }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const handleToggleOptions = () => {
    setIsOptionsOpen((prev) => !prev);
  };

  const options = [
    {
      label: "Add Material",
      icon: <InventoryIcon />,
      onClick: <CreateItem onSubmit={onSubmit} id={industryId} />,
    },
    {
      label: "Add Sub Category",
      icon: <AccountTreeIcon />,
      onClick: <CreateSubCategory onSubmit={onSubmit} id={industryId} />,
    },
  ];

  return (
    <>
      {isOptionsOpen &&
        options?.map((item, index) => {
          if (hidden === index) return;
          return (
            <Tooltip title={item?.label} placement="left">
              <FloatingBottomRight
                color="secondary"
                variant="extended"
                bottomSpacing={hidden !== undefined ? 1 : index + 1}
                icon={
                  <Typography sx={{ display: "flex", columnGap: 0.5 }}>
                    {item?.icon}
                    <Typography
                      component={"p"}
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.label}
                    </Typography>
                  </Typography>
                }
                title={item?.label}
              >
                {item?.onClick}
              </FloatingBottomRight>
            </Tooltip>
          );
        })}

      <Fab
        color="primary"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          transition: "ease",
        }}
        onClick={handleToggleOptions}
      >
        {isOptionsOpen ? <CloseIcon /> : <AddIcon />}
      </Fab>
    </>
  );
};

export function MaterialList() {
  const { industryId, ...rest } = useParams();
  const currentIndustryId = replaceAndRemoveTrailingSlash(rest["*"])
    .split("/")
    .pop();

  const [materialDetail, setMaterialDetail] = useState("");
  const [hidden, setHidden] = useState("");

  const fetchDetail = () => {
    ApiClient.material.get(currentIndustryId || industryId || 0).then((res) => {
      if (res) {
        if (res?.data?.category_childlist?.length) {
          setHidden(0);
        } else if (res?.data?.metarial_list?.length) {
          setHidden(1);
        } else {
          setHidden(undefined);
        }

        setMaterialDetail(res?.data);
      }
    });
  };

  const updateCategory = (data, handleClose, rest) => {
    ApiClient.materialCategory.put(rest?.id, data).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  const deleteCategory = (id, handleClose, rest) => {
    ApiClient.materialCategory.delete(id).then((res) => {
      if (res) {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      }
    });
  };

  useEffect(() => {
    fetchDetail();
  }, [currentIndustryId]);

  const handleDelete = (id, handleCloseSuccess) => {

    ApiClient.material
      .delete(id)
      .then((res) => {
        if (res) {
          CustomToaster.success(res.message);
          handleCloseSuccess();
          fetchDetail();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateAction = (material) => [
    <>
      <DeleteConfirmationDialog
        onSubmit={(_, handleCloseSuccess) =>
          handleDelete(material?.id, handleCloseSuccess)
        }
      >
        <ListItemButton
          sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
        >
          <Delete />
          <Typography component={"span"}>Delete</Typography>
        </ListItemButton>
      </DeleteConfirmationDialog>
    </>,

    <>
      <CustomDialogWrapper
        fullWidth
        element={
          <ListItemButton
            onClick={() => {
              // navigate("/jobs/update/" + jobId + "/full");
            }}
            sx={{ w: "full", display: "flex", alignItems: "center", gap: 1 }}
          >
            <Edit />
            <Typography component={"span"}>Edit</Typography>
          </ListItemButton>
        }
      >
        <UpdateItem
          onSubmit={fetchDetail}
          id={material?.id}
          material={material}
        />
      </CustomDialogWrapper>
    </>,
  ];

  return (
    <>
      <NavBar title="MaterialList" goBack isFilter={false} noFilter noSearch />

      {materialDetail ? (
        <>
          {materialDetail?.metarial_list?.length ? (
            <>
              <Box>
                <Typography sx={{ my: 2 }}>
                  <Typography component={"span"} sx={{ opacity: 0.7 }}>
                    {" "}
                    Result showing:{" "}
                  </Typography>
                  <Typography component={"span"}>
                    {materialDetail?.metarial_list?.length}
                  </Typography>
                </Typography>
                <Box component={"div"} sx={{ display: "flex", gap: 1.2, flexWrap: 'wrap' }}>
                  {materialDetail?.metarial_list &&
                    materialDetail?.metarial_list?.map((material) => (
                      <MaterialCard
                        key={material.id}
                        material={material}
                        onMore={
                          <BottomDrawer
                            title="Action"
                            data={generateAction(material)}
                          />
                        }
                      />
                    ))}
                </Box>
              </Box>
            </>
          ) : (
            <>
              {materialDetail?.category_childlist?.length ? (
                <>
                  {materialDetail?.category_childlist?.map((cat) => (
                    <TextArrowCard
                      key={cat.id}
                      title={cat.name}
                      url={cat.id}
                      onUpdate={
                        <UpdateText
                          id={cat.id}
                          name={"name"}
                          value={cat.name}
                          iconText="Edit "
                          onSubmit={updateCategory}
                          label="Category"
                        />
                      }
                      onDelete={
                        <DeleteConfirmationDialog
                          id={cat.id}
                          name={"name"}
                          value={cat.name}
                          iconText="Delete"
                          onSubmit={deleteCategory}
                          label="Category"
                        />
                      }
                    />
                  ))}
                </>
              ) : (
                <>
                  <NoResultFound />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <LoadingEffect />
        </>
      )}

      <FloatingActionButton
        hidden={hidden}
        onSubmit={fetchDetail}
        industryId={currentIndustryId || industryId}
      />
    </>
  );
}
