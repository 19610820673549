import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomSwipableTabs, NavBar } from "../../../components";
import ApiClient from "../../../constant/API_URLS";
import { Box, Grid } from "@mui/material";
import {
  LabelTextAddressCard,
  LabelTextCard,
  LabelTextEmailCard,
  LabelTextPhoneCard,
} from "../../../components/Cards/DisplayCards";
import {
  AddAddress,
  AddEmail,
  AddPhone,
} from "../../../components/AddAdditionalContact";
import CustomToaster from "../../../utils/CustomToaster";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { UpdateText } from "../../../components/UpdateDetails/UpdateText";

export const ProfileDetail = () => {
  const { userId } = useParams();
  const [profileDetail, setProfileDetail] = useState("");
  const navigate = useNavigate();

  const fetchDetail = () => {
    setProfileDetail("");
    ApiClient.client
      .get(userId)
      .then((res) => {
        setProfileDetail(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUser = (data, handleClose) => {
    ApiClient.client
      .put(userId, data)
      .then((res) => {
        CustomToaster.success(res?.message);
        handleClose();
        fetchDetail();
      })
      .catch((err) => {
        CustomToaster.error(err?.message);
      });
  };

  useEffect(() => {
    if (userId) {
      fetchDetail();
    }
  }, []);

  return (
    <>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 1,
        }}
      >
        {!profileDetail ? (
          <LoadingEffect />
        ) : (
          <>
            <LabelTextCard
              label={"First Name"}
              value={profileDetail?.first_name}
              onUpdate={
                <UpdateText
                  label={"First Name"}
                  name={"first_name"}
                  value={profileDetail?.first_name}
                  onSubmit={updateUser}
                />
              }
            />

            {profileDetail?.last_name && (
              <LabelTextCard
                label={"Last Name"}
                value={profileDetail?.last_name}
                onUpdate={
                  <UpdateText
                    label={"Last Name"}
                    name={"last_name"}
                    value={profileDetail?.last_name}
                    onSubmit={updateUser}
                  />
                }
              />
            )}

            {profileDetail?.company_name ? (
              <LabelTextCard
                label={"Company Name"}
                value={profileDetail?.company_name}
                onUpdate={
                  <UpdateText
                    label={"Company Name"}
                    name={"company_name"}
                    value={profileDetail?.company_name}
                    onSubmit={updateUser}
                  />
                }
              />
            ) : (
              ""
            )}

            <LabelTextEmailCard
              onUpdate={fetchDetail}
              label={"Email"}
              value={profileDetail?.AdditionEmails}
              addButton={<AddEmail onUpdate={fetchDetail} id={userId} />}
            />

            <LabelTextPhoneCard
              onUpdate={fetchDetail}
              label={"Phone"}
              value={profileDetail?.AdditionPhones}
              addButton={<AddPhone onUpdate={fetchDetail} id={userId} />}
            />

            <LabelTextAddressCard
              onUpdate={fetchDetail}
              label={"Address"}
              value={profileDetail?.additionaladdress}
              addButton={<AddAddress id={userId} onUpdate={fetchDetail} />}
            />
          </>
        )}
      </Box>
    </>
  );
};
