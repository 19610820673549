import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CompleteJobCard, JobCard } from "../../../components/Cards/DisplayCards";
import { useParams } from "react-router-dom";
import ApiClient from "../../../constant/API_URLS";
import { LoadingEffect } from "../../../components/SimmerEffects/LoadingEffect";
import { NoResultFound } from "../../../components/NoResult/NoResult";
import { ClientProfileJobCard } from "../../../components/Cards/DisplayCards/JobCard/ClientProfileJobCard";

export function Job() {
    const { userId } = useParams();
    const [jobList, setJobList] = useState("");

    const fetchJobs = () => {
        ApiClient.job.get('?user_id=' + userId).then((res) => {
            if (res) {
                setJobList(res.data);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchJobs();
    }, [])

    return (
        <Box
        py={2}
        justifyContent={'center'}
        overflow={"hidden"}
        my={1}
        display={"flex"}
        flexWrap={"wrap"}
        gap={2}
      >
        {jobList ? (
          <>
            {!jobList?.length ? (
              <NoResultFound />
            ) : (
              <>
                {" "}
                {jobList.map((item, index) => (
                  <ClientProfileJobCard key={index} job={item} id={index} />
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <LoadingEffect />
          </>
        )}
      </Box>
    );
}
