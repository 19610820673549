import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";

export const NameCard = ({
  user,
  onClick,
  secondaryAction,
}) => {


  return (
    <>
      <Typography
        sx={{
          borderRadius: 1,
          ":hover": {
            boxShadow: "0 3px 12px -8px #a0a",
            cursor: "pointer",
          },
        }}
      >
        <ListItem
          components={"div"}
          onClick={onClick}
          secondaryAction={
            secondaryAction ? (
              React?.cloneElement(secondaryAction, { id: user.id })
            ) : (
              <IconButton edge="end" aria-label="detail-page">
                <KeyboardArrowRightIcon className="!fill-[#B1BAC4]" />
              </IconButton>
            )
          }
        >
          <ListItemAvatar>
            <Avatar>
              {user?.first_name.charAt(0).toUpperCase() +
                (user?.last_name
                  ? user?.last_name?.charAt(0).toUpperCase()
                  : "")}
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            primaryTypographyProps={{
              textTransform: "capitalize",
            }}
            primary={
              user?.first_name + " " + (user?.last_name ? user?.last_name : "")
            }
            secondary={
              user?.additionaladdress[0]?.street +
              " " +
              user?.additionaladdress[0]?.pin_code
            }
          />

          {user?.is_active ? (
            <Typography
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",

                borderRadius: 1,
                px: 0.5,
                py: 0.1,
                fontSize: 14,
                fontWeight: "500",
              }}
            >
              <FiberManualRecord htmlColor="red" fontSize="10px" />
              In-Active
            </Typography>
          ) : (
            ""
          )}
        </ListItem>

        <Divider
          sx={{
            borderRadius: 4,
          }}
        />
      </Typography>
    </>
  );
};
