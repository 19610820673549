import { Box, Typography } from '@mui/material'
import React from 'react'
import { InProgressJobCard } from '../../../../components/Cards/DisplayCards'
import { NoResultFound } from '../../../../components';

function InProgress({ jobs }) {
  if (!jobs?.length) {
    return <NoResultFound >No InProgress Job Found</NoResultFound>;
  }

  return (
    <>

      <Typography>
      Total InProgress :{" "}
        <Typography component={"strong"} style={{ fontWeight: "700" }}>
          {jobs.length}
        </Typography>
      </Typography>

      <Box py={2} overflow={'hidden'} my={1} display={'flex'} flexWrap={'wrap'} gap={2} >
        {jobs.map((item, index) => (
          <InProgressJobCard key={index} job={item} />
        ))}
      </Box>
    </>
  )
}

export default InProgress